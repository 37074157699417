import { DataTable } from "@/components/table/data-table.tsx";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { DataTableFacetedFilter } from "@/components/table/data-table-faceted-filter.tsx";
import { DataTableSearch } from "@/components/table/data-table-search.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import {
  getCoreRowModel,
  type RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import { productReceivedColumns } from "@/pages/invoices/product-received/product-received-columns.tsx";
import { usePaginationSearchParams } from "@/hooks/use-pagination-search-params.ts";
import { type ApiResponse, fetchApi, isApiError } from "@/lib/api.ts";
import { useFilterSearchParams } from "@/hooks/use-filter-search-params.ts";
import { parseAsArrayOf, parseAsString, parseAsIsoDate } from "nuqs";
import { useDebounce } from "@/hooks/use-debounce.tsx";
import { useOrderBySearchParams } from "@/hooks/use-order-by-search-params.ts";
import { DataTableViewOptions } from "@/components/data-table/data-table-view-options.tsx";
import { DatePickerRange } from "@/components/ui/date-picker.tsx";
import { useState } from "react";
import { DataTableExportDropdown } from "@/components/data-table/data-table-export-dropdown";
import { toast } from "sonner";

type ReceivedProductInvoiceResponse = {
  invoices: Array<{
    id: string;
    status: string;
    type: "product" | "service";
    issuer: {
      document: string;
      name?: string;
    };
    recipient: {
      document: string;
      name: string;
    };
    invoice: {
      value: string;
      issuedAt: string;
      number: string;
    };
    manifest?: string;
    approvalStatus: string;
  }>;
  pagination: {
    total: number;
    totalPages: number;
  };
};

export function ReceivedProductInvoices() {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [allInvoices, setAllInvoices] = useState(false);

  const { paginationState, handleTablePaginationChange } =
    usePaginationSearchParams();

  const { sortState, tableSortingState, handleTableSortingChange } =
    useOrderBySearchParams({
      orderBy: "issuedAt",
      orderDirection: "desc",
    });

  const { filters, tableFilterState, handleTableFilterChange, setFilters } =
    useFilterSearchParams({
      dateFrom: parseAsIsoDate,
      dateTo: parseAsIsoDate,
      status: parseAsArrayOf(parseAsString).withDefault([]),
      approvalStatus: parseAsArrayOf(parseAsString).withDefault([]),
      issuer: parseAsString,
      recipient: parseAsString,
    });

  const debouncedFilters = useDebounce(filters, 500);
  const debouncedPaginationState = useDebounce(paginationState, 500);

  const { mutate, isPending } = useMutation({
    mutationKey: ["download-received-product-invoices"],
    mutationFn: async (data: {
      type: "pdf" | "xml";
      invoices: string[];
      allInvoices: boolean;
    }) => {
      return fetchApi
        .post<
          ApiResponse<{
            url: string;
          }>
        >("/invoices/products/received/download", data)
        .then((res) => res.data.data);
    },
    onSuccess: (data) => {
      console.log(data);
      toast.success(
        "Arquivo solicitado. Você pode acompanhar e baixar o arquivo no menu Sistema > Arquivos."
      );
    },
    onError: (error) => {
      if (isApiError(error)) {
        const errorMessage = error.response?.data.error.message;

        if (errorMessage === "NO_INVOICES_FOUND") {
          return toast.error(
            "Nenhuma nota foi encontrada para o tipo selecionado."
          );
        }

        if (errorMessage === "INVOICES_NOT_FROM_SAME_TENANT") {
          return toast.error(
            "Alguma coisa deu errado! Algumas notas não pertencem a você. Por favor, entre em contato com o suporte."
          );
        }

        if (errorMessage === "FILE_NOT_FOUND_ON_S3") {
          return toast.error(
            "Algum arquivo selecionado não foi encontrado em nosso banco de dados. Por favor, entre em contato com o suporte."
          );
        }
      }
      toast.error("Ocorreu um erro ao baixar as notas.");
    },
  });

  const { data: invoices } = useQuery({
    queryKey: [
      `/invoices/products/received`,
      debouncedPaginationState,
      debouncedFilters,
      sortState,
    ],
    queryFn: () =>
      fetchApi
        .get<ApiResponse<ReceivedProductInvoiceResponse>>(
          "/invoices/products/received",
          {
            params: {
              limit: debouncedPaginationState.pageSize,
              page: debouncedPaginationState.pageIndex + 1,
              ...debouncedFilters,
              ...sortState,
            },
          }
        )
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
  });

  const table = useReactTable({
    columns: productReceivedColumns,
    data: invoices?.invoices ?? [],
    state: {
      pagination: paginationState,
      columnFilters: tableFilterState,
      sorting: tableSortingState,
      rowSelection,
    },
    onPaginationChange: handleTablePaginationChange,
    onSortingChange: handleTableSortingChange,
    onColumnFiltersChange: handleTableFilterChange,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    pageCount: invoices?.pagination?.totalPages ?? -1,
    getRowId: (row) => row.id,
    onRowSelectionChange: (newSelectionUpdater) => {
      setRowSelection((prev) => {
        const newSelection =
          typeof newSelectionUpdater === "function"
            ? newSelectionUpdater(prev)
            : newSelectionUpdater;

        const selectedRowsCount = Object.keys(newSelection).length;
        const totalInvoicesCount = invoices?.pagination.total || 0;

        if (allInvoices && selectedRowsCount < totalInvoicesCount) {
          setAllInvoices(false);
        }

        return newSelection;
      });
    },
  });

  const dateFrom = filters.dateFrom;
  const dateTo = filters.dateTo;

  const selectedRowsCount = Object.keys(rowSelection).length;
  const totalInvoices = invoices?.pagination.total || 0;

  const handleInvoicesSelection = (markAll: boolean) => {
    if (markAll) {
      setAllInvoices(true);
      table.toggleAllRowsSelected(true);
    } else {
      setAllInvoices(false);
      table.setRowSelection({});
      table.toggleAllRowsSelected(false);
    }
  };

  const renderSelectionMessage = () => {
    if (selectedRowsCount === 0) return null;

    if (allInvoices) {
      return (
        <div className="flex items-center gap-1 text-sm text-muted-foreground border p-2 rounded-md justify-center">
          <span>Todas as notas foram marcadas.</span>
          <span
            className="text-blue-600 hover:underline hover:cursor-pointer hover:text-blue-500"
            onClick={() => handleInvoicesSelection(false)}
          >
            Clique para desmarcar todas.
          </span>
        </div>
      );
    }

    if (selectedRowsCount === totalInvoices && !allInvoices) {
      return (
        <div className="flex items-center gap-1 text-sm text-muted-foreground border p-2 rounded-md justify-center">
          <span>Todas as notas foram marcadas.</span>
          <span
            className="text-blue-600 hover:underline hover:cursor-pointer hover:text-blue-500"
            onClick={() => handleInvoicesSelection(false)}
          >
            Clique para desmarcar todas.
          </span>
        </div>
      );
    }

    if (selectedRowsCount > 0) {
      return (
        <div className="flex items-center gap-1 text-sm text-muted-foreground border p-2 rounded-md justify-center">
          <span>
            Notas selecionadas {selectedRowsCount} de {totalInvoices}.
          </span>
          <span
            className="text-blue-600 hover:underline hover:cursor-pointer hover:text-blue-500"
            onClick={() => handleInvoicesSelection(true)}
          >
            Clique para marcar todas.
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Notas de Produto Recebidas</PageTitle>
        <PageDescription>
          Gerencie todas as notas recebidas até o momento pelo nosso sistema.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="space-y-4">
          {renderSelectionMessage()}
          <div className="flex items-center justify-between flex-wrap min-w-0 gap-2">
            <div className="flex items-center gap-2 min-w-0 flex-wrap">
              <DatePickerRange
                value={{
                  from: dateFrom ?? undefined,
                  to: dateTo ?? undefined,
                }}
                setDate={(newDate) => {
                  void setFilters({
                    dateFrom: newDate?.from ?? null,
                    dateTo: newDate?.to ?? null,
                  });
                }}
              />
              <DataTableFacetedFilter
                title="Status Sefaz"
                column={table.getColumn("status")}
                options={[
                  {
                    label: "Autorizada",
                    value: "authorized",
                  },
                  {
                    label: "Cancelada",
                    value: "cancelled",
                  },
                  {
                    label: "Denegada",
                    value: "denied",
                  },
                ]}
              />
              <DataTableSearch
                placeholder="Emitente"
                column={table.getColumn("issuer")!}
              />
              <DataTableSearch
                placeholder="Tomador"
                column={table.getColumn("recipient")!}
              />
              {selectedRowsCount > 0 && (
                <DataTableExportDropdown
                  isPending={isPending}
                  onRequest={(type) =>
                    mutate({
                      type,
                      invoices: Object.keys(rowSelection),
                      allInvoices,
                    })
                  }
                />
              )}
            </div>
            <DataTableViewOptions
              table={table}
              columns={{
                status: "Status",
                approvalStatus: "Matriz de Avaliação",
              }}
            />
          </div>
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
