import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { useParams } from "react-router-dom";
import { useUpdateCredentialsMutation } from "@/pages/companies/[document]/actions/useCredentialsMutation.ts";
import { getBase64 } from "@/lib/utils.ts";
import { toast } from "sonner";

const certificateSchema = z.object({
  certificate: z
    .string({
      required_error: "Por favor, adicione um certificado",
    })
    .min(1, "Por favor, adicione um certificado")
    .refine(
      (value) =>
        !value.startsWith("data:") ||
        !value.includes("base64") ||
        !value.includes("pfx"),
      "Por favor, adicione um certificado válido"
    ),
  certificatePassword: z
    .string({
      required_error: "Por favor, adicione a senha do certificado",
    })
    .min(1, "Por favor, adicione a senha do certificado"),
});

type Certificate = z.infer<typeof certificateSchema>;

export function ConfigureCertificate() {
  const { document } = useParams();

  const form = useForm<Certificate>({
    resolver: zodResolver(certificateSchema),
    defaultValues: {
      certificatePassword: "",
      certificate: "",
    },
  });

  const { mutateAsync: validateCertificate } = useMutation({
    mutationKey: ["/certificates/validate"],
    mutationFn: ({
      certificate,
      password,
    }: {
      certificate: string;
      password: string;
    }) =>
      fetchApi<{
        status: "success" | "error";
        data: {
          name?: string;
          document?: string;
          expiration: string;
          isExpired: boolean;
        };
      }>("/certificates/validate", {
        method: "POST",
        data: {
          certificate: certificate,
          certificatePassword: password,
        },
      }),
    onSuccess: () => {
      toast.success(
        "As informações do seu certificado foram carregadas, aguarde pois estamos validando o conteúdo do certificado."
      );
    },
  });

  const { mutateAsync: createCertificate } = useUpdateCredentialsMutation({
    document,
    type: "certificate",
  });

  const submit = async () => {
    const validation = await validateCertificate({
      certificate: form.getValues("certificate"),
      password: form.getValues("certificatePassword"),
    });

    if (validation.status !== 200) {
      if (validation.status === 400) {
        form.setError("certificate", {
          type: "manual",
          message: "Certificado inválido.",
        });
        return;
      }

      form.setError("certificate", {
        type: "manual",
        message: "Ocorreu um erro ao validar o certificado.",
      });
      return;
    }

    if (validation.data.data.isExpired) {
      form.setError("certificate", {
        type: "manual",
        message: "Este certificado encontra-se expirado.",
      });
      return;
    }

    const { status } = await createCertificate({
      certificate: form.getValues("certificate"),
      certificatePassword: form.getValues("certificatePassword"),
    });

    if (status > 300) {
      form.setError("certificate", {
        type: "manual",
        message: "Ocorreu um erro ao salvar o certificado.",
      });
      return;
    }
  };

  return (
    <div>
      <p className="font-semibold text-lg">Cadastrar certificado digital</p>
      <p className="font-sm text-muted-foreground mt-2">
        A sua empresa não possui um certificado digital configurado em nosso
        sistema. Para cadastrar o certificado, por favor clique no botão logo
        abaixo.
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submit)} className="space-y-8">
          <div className="mt-3">
            <FormField
              control={form.control}
              name="certificate"
              render={({ field: { onChange, value: _value, ...rest } }) => (
                <FormItem>
                  <FormLabel>Certificado digital</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      {...rest}
                      accept=".pfx"
                      onChange={async (e) => {
                        const file = e.target.files?.[0];

                        form.reset({
                          certificatePassword: "",
                        });

                        if (!file) {
                          onChange("");
                          return;
                        }

                        const base64 = await getBase64(file);
                        onChange(base64);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="certificatePassword"
              render={({ field }) => (
                <FormItem className="mt-6">
                  <FormLabel>Senha do certificado</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                      disabled={!form.watch("certificate")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button type="submit" disabled={!form.watch("certificate")}>
            Salvar certificado
          </Button>
        </form>
      </Form>
    </div>
  );
}
