import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";

interface ComponentProps {
  status: string;
  invoiceNumber: string | null;
  invoiceSeries: string | null;
  externalId: string | null;
}

export function SefazAuthorizedInfoCard({
  status,
  invoiceNumber,
  invoiceSeries,
  externalId,
}: ComponentProps) {
  const isAuthorized =
    status === "authorized" && invoiceNumber && invoiceSeries && externalId;

  if (!isAuthorized) {
    return null;
  }

  return (
    <Card className="border-green-600 bg-green-50">
      <CardHeader className="[&>*]:text-green-800">
        <CardTitle>NF-e autorizada pelo SEFAZ.</CardTitle>
        <CardDescription>
          <p>
            Número da NF-e:{" "}
            <span className="font-semibold">{invoiceNumber}</span>
          </p>
          <p>
            Série da NF-e:{" "}
            <span className="font-semibold">{invoiceSeries}</span>
          </p>
          <p>
            Chave da NF-e: <span className="font-semibold">{externalId}</span>
          </p>
        </CardDescription>
      </CardHeader>
      {/*<CardContent>*/}
      {/*  <div className="flex flex-row flex-wrap gap-4">*/}
      {/*    <Button>Download PDF</Button>*/}
      {/*    <Button>Download XML</Button>*/}
      {/*  </div>*/}
      {/*</CardContent>*/}
    </Card>
  );
}
