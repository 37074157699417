import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  AlertTriangle,
  CheckCircle,
  Loader2,
  PlusCircle,
  User,
  X,
} from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { Popover, PopoverContent } from "@/components/ui/popover.tsx";
import { cn } from "@/lib/utils.ts";
import { useMemo, useState } from "react";
import { Autocomplete } from "@/components/autocomplete.tsx";
import { PopoverAnchor } from "@radix-ui/react-popover";
import { useUser } from "@/hooks/use-user.ts";
import { Button } from "@/components/ui/button.tsx";
import { useTenantUsers } from "@/hooks/use-tenant-users.ts";
import { toast } from "sonner";
import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import { SuccessDataTable } from "@/pages/invoices/import/components/nfse/components/success-data-table.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";
import { ErrorsDataTable } from "@/pages/invoices/import/components/nfse/components/errors-data-table.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";

interface NfseData {
  status: "success" | "error";
  invoice: CreateServiceInvoice;
  errors: string[];
  line: number;
  csvData: Record<string, string>;
}

interface NfseDataTableProps {
  fileName: string;
  success: Omit<NfseData, "csvData" | "errors" | "line">[];
  errors: Omit<NfseData, "invoice">[];
}

const batch = z.object({
  name: z.string().min(1, "O nome do lote é obrigatório"),
  owners: z.array(z.string()).min(1, "É obrigatório pelo menos um responsável"),
});

type Batch = z.infer<typeof batch>;

export function ProcessedNfseFile({
  fileName,
  success,
  errors,
}: NfseDataTableProps) {
  const { data: userData } = useUser();
  const { data: tenantData } = useTenantUsers();
  const navigate = useNavigate();

  const { mutate: createBatch, isPending } = useMutation({
    mutationFn: async ({
      batch,
      invoices,
    }: {
      batch: Batch;
      invoices: CreateServiceInvoice[];
    }) =>
      fetchApi.post("/invoices/sent/services/batch", {
        name: batch.name,
        owners: batch.owners,
        invoices,
      }),
    onSuccess: () => {
      toast.success(
        "Lote criado com sucesso. As informações serão processadas, você pode acompanhar o status na página de lotes.",
        {
          duration: 10000,
        }
      );
      navigate("/invoices/batches");
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 400) {
          toast.error(
            `Ocorreu um erro ao criar o lote. Verifique se as empresas emissoras estão cadastradas no sistema.`
          );
        } else if (status === 500) {
          toast.error(
            "Ocorreu um erro interno ao criar o lote. Por favor, tente novamente."
          );
        }

        return;
      }

      toast.error(
        "Ocorreu um erro ao criar o lote. Por favor, tente novamente."
      );
    },
  });

  const [popoverOwners, setPopoverOwners] = useState(false);

  const form = useForm<Batch>({
    resolver: zodResolver(batch),
    defaultValues: {
      owners: [userData?.id ?? ""],
      name: fileName,
    },
  });

  const formOwners = form.watch("owners");

  const owners = useMemo(() => {
    return formOwners.map((id) => {
      return {
        name: tenantData?.users.find((r) => r.id === id)?.name ?? "",
        id,
      };
    });
  }, [formOwners, tenantData]);

  const submit = async (batch: Batch) => {
    if (errors.length > 0) {
      toast.warning(
        "Por favor, corrija os erros apontados para poder continuar com a sua solicitação.",
        {
          duration: 5000,
          position: "bottom-center",
        }
      );
      return;
    }

    const invoices = success.map((row) => {
      return {
        ...row.invoice,
      };
    });

    createBatch({ batch, invoices });
  };

  const filterAvailableOwners = () => {
    if (!tenantData) return [];

    return tenantData.users.filter((user) => {
      return !owners.find((owner) => owner.id === user.id);
    });
  };

  const deleteOwner = (index: number) => {
    if (index === 0) return;
    form.setValue(
      "owners",
      formOwners.filter((_, i) => i !== index)
    );
  };

  const addOwner = (value: string) => {
    const owner = tenantData?.users.find((r) => r.id === value);
    setPopoverOwners(false);

    if (!owner) {
      toast.error(
        "Não foi possível adicionar o responsável. Verifique se o usuário existe."
      );
      return;
    }

    if (owners.find((r) => r.id === owner.id)) {
      toast.warning("O responsável já está adicionado no lote.");
      return;
    }

    form.setValue(
      "owners",
      [...owners, owner].map((o) => o.id)
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Leitura do arquivo finalizada ({fileName})</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"flex flex-col gap-y-2"}>
          <h3 className={"font-medium text-lg"}>Configurações do lote</h3>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)} className="space-y-3">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className={"max-w-lg"}>
                    <FormLabel required>Nome do lote</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder={fileName} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="owners"
                render={() => (
                  <FormItem className={"w-full"}>
                    <div>
                      <FormLabel required>Responsáveis pelo lote</FormLabel>
                    </div>
                    <FormControl>
                      <div className={"flex flex-wrap gap-2 items-center"}>
                        {owners.map(({ name, id }, index) => (
                          <Badge
                            key={id}
                            variant={index === 0 ? "secondary" : "outline"}
                            className={cn(
                              "select-none",
                              index !== 0 && "hover:cursor-pointer"
                            )}
                            onClick={() => deleteOwner(index)}
                          >
                            <span>{name}</span>
                            {index !== 0 && (
                              <X className={"ml-2 size-3 text-destructive"} />
                            )}
                          </Badge>
                        ))}
                        <Popover
                          open={popoverOwners}
                          onOpenChange={setPopoverOwners}
                        >
                          <PopoverAnchor asChild>
                            <PlusCircle
                              onClick={() => setPopoverOwners(true)}
                              className={
                                "size-4 hover:cursor-pointer select-none"
                              }
                            />
                          </PopoverAnchor>
                          <PopoverContent
                            onOpenAutoFocus={(e) => e.preventDefault()}
                            align={"center"}
                            side={"top"}
                            sideOffset={10}
                            className={"flex flex-col gap-y-2"}
                          >
                            <div
                              className={
                                "text-muted-foreground flex items-center gap-x-1"
                              }
                            >
                              <User className={"size-3"} />
                              <span className={"text-sm"}>
                                Adicionar responsável
                              </span>
                            </div>
                            <Autocomplete
                              options={filterAvailableOwners().map((r) => {
                                return {
                                  label: r.name ?? r.id,
                                  value: r.id,
                                };
                              })}
                              emptyMessage={"Nenhum resultado"}
                              onValueChange={({ value }) => {
                                addOwner(value);
                              }}
                              placeholder={"Clique aqui"}
                            />
                          </PopoverContent>
                        </Popover>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className={"flex flex-col gap-y-3"}>
                {errors?.length > 0 ? (
                  <Alert variant={"destructive"} className="my-2">
                    <AlertTriangle className={"size-4"} />
                    <AlertTitle>
                      Identificamos alguns erros na importação do arquivo
                    </AlertTitle>
                    <AlertDescription>
                      O lote apresentado possui {errors.length} erro(s). Por
                      favor, verifique os erros na tabela abaixo e corrija-os.
                      Após corrigir os erros, envie novamente o arquivo para
                      continuar com a importação.
                    </AlertDescription>
                  </Alert>
                ) : (
                  <Alert variant={"success"} className="my-2">
                    <CheckCircle className={"size-4"} />
                    <AlertTitle>Importação realizada com sucesso</AlertTitle>
                    <AlertDescription>
                      O lote foi importado com sucesso. Por favor, verifique os
                      dados importados na aba "Dados recebidos".
                    </AlertDescription>
                  </Alert>
                )}
                {errors?.length > 0 && <ErrorsDataTable data={errors} />}
                <SuccessDataTable data={success} />
              </div>
              <div className={"flex items-center justify-end"}>
                <Button
                  variant={"default"}
                  disabled={isPending || errors.length > 0}
                  className="gap-2"
                >
                  {isPending && <Loader2 className={"animate-spin"} />}
                  {errors.length > 0 && <AlertTriangle className={"mr-2"} />}
                  <span>Processar lote</span>
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </CardContent>
    </Card>
  );
}
