import type { Table } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import { DataTableViewOptions } from "./data-table-view-options";

import { Eraser } from "lucide-react";
import type { ReactNode } from "react";
import { isFalsy } from "@/lib/utils.ts";

interface DataTableToolbarProps<TData> {
  children?: ReactNode;
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  children,
  table,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table
    .getState()
    .columnFilters.some((f) => !isFalsy(f.value));

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2 flex-wrap">
        {children}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Resetar filtros
            <Eraser className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
