import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { DocumentCardInfo } from "@/pages/evaluation/components/document-card-info.tsx";
import {
  Clock,
  CheckCircle,
  FileMagnifyingGlass,
  XCircle,
} from "@phosphor-icons/react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import { formatCurrency } from "@/lib/currency.ts";
import { format } from "date-fns";
import { Button } from "@/components/ui/button.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { Scrollbar } from "@radix-ui/react-scroll-area";
import { Link } from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchApi, getApiData } from "@/lib/api.ts";

interface EvaluationInvoiceItem {
  id: string;
  externalId: string;
  vendor: {
    id: string;
    name: string;
    document: string;
  };
  total: string;
  issuedAt: string;
}

interface EvaluationData {
  pending: string;
  approved: string;
  rejected: string;

  beacon: {
    green: string;
    yellow: string;
    orange: string;
    red: string;
  };
}

export function EvaluationPage() {
  const { data: pendingInvoices } = useSuspenseQuery({
    queryKey: ["retrieve-evaluation-pending-invoices"],
    queryFn: () =>
      fetchApi
        .get("/evaluation/pending")
        .then(getApiData<{ invoices: EvaluationInvoiceItem[] }>)
        .then((r) => r.invoices),
  });

  const { data: evaluationData } = useSuspenseQuery({
    queryKey: ["retrieve-evaluation-data"],
    queryFn: () =>
      fetchApi.get("/evaluation/data").then(getApiData<EvaluationData>),
    initialData: {
      pending: "0",
      approved: "0",
      rejected: "0",
      beacon: {
        green: "0",
        yellow: "0",
        orange: "0",
        red: "0",
      },
    },
  });

  const hasPendingInvoices = pendingInvoices?.length > 0;

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Matriz de avaliação</PageTitle>
        <PageDescription>
          Gerencie os documentos integrados no sistema
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col lg:flex-row gap-4">
            <Card className="flex-1">
              <CardHeader>
                <CardTitle className="text-xl">
                  Visão geral dos documentos
                </CardTitle>
              </CardHeader>
              <CardContent className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                <DocumentCardInfo
                  linkTo="/evaluation/list?status=pending"
                  label="Pendentes"
                  value={evaluationData.pending}
                  icon={<Clock className="size-8 text-blue-500" />}
                />
                <DocumentCardInfo
                  linkTo="/evaluation/list?status=rejected"
                  label="Recusados"
                  value={evaluationData.rejected}
                  icon={<XCircle className="size-8 text-red-500" />}
                />
                <DocumentCardInfo
                  linkTo="/evaluation/list?status=approved"
                  label="Aprovados"
                  value={evaluationData.approved}
                  icon={<CheckCircle className="size-8 text-green-500" />}
                />
              </CardContent>
            </Card>
            <Card className="flex-1">
              <CardHeader>
                <CardTitle className="text-xl">
                  Farol de data de emissão
                </CardTitle>
              </CardHeader>
              <CardContent className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <DocumentCardInfo
                  linkTo="/evaluation/list?beacon=green&status=pending"
                  value={evaluationData.beacon.green}
                  label="0 a 5 dias da emissão"
                  icon={<div className="size-3 bg-green-500 rounded-full" />}
                />
                <DocumentCardInfo
                  linkTo="/evaluation/list?beacon=yellow&status=pending"
                  value={evaluationData.beacon.yellow}
                  label="6 a 10 dias da emissão"
                  icon={<div className="size-3 bg-yellow-500 rounded-full" />}
                />
                <DocumentCardInfo
                  linkTo="/evaluation/list?beacon=orange&status=pending"
                  value={evaluationData.beacon.orange}
                  label="11 a 20 dias da emissão"
                  icon={<div className="size-3 bg-orange-500 rounded-full" />}
                />
                <DocumentCardInfo
                  linkTo="/evaluation/list?beacon=red&status=pending"
                  value={evaluationData.beacon.red}
                  label="Acima de 21 dias da emissão"
                  icon={<div className="size-3 bg-red-500 rounded-full" />}
                />
              </CardContent>
            </Card>
          </div>
          <div className="flex-1 flex flex-col gap-4">
            <Card>
              <CardHeader>
                <CardTitle className="text-xl">
                  Documentos aguardando a sua avaliação
                </CardTitle>
              </CardHeader>
              <CardContent className="w-full">
                {!hasPendingInvoices && (
                  <div className="flex items-center justify-center gap-x-0.5">
                    <CheckCircle className="size-5 text-green-600" />
                    <span className="text-sm text-muted-foreground">
                      Não há documentos aguardando a sua avaliação
                    </span>
                  </div>
                )}
                {hasPendingInvoices && (
                  <ScrollArea className="border">
                    <div className="max-h-[400px] w-full">
                      <Table>
                        <TableHeader className="bg-card">
                          <TableRow>
                            <TableHead className="w-[160px]">
                              Data de Emissão
                            </TableHead>
                            <TableHead className="w-[250px]">
                              Fornecedor
                            </TableHead>
                            <TableHead>Tarefa</TableHead>
                            <TableHead>Valor</TableHead>
                            <TableHead />
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {pendingInvoices.map((invoice) => (
                            <TableRow key={invoice.id}>
                              <TableCell>
                                {invoice.issuedAt
                                  ? format(invoice.issuedAt, "dd/MM/yyyy")
                                  : "-"}
                              </TableCell>
                              <TableCell>{invoice.vendor.document}</TableCell>
                              <TableCell>Aguardando avaliação</TableCell>
                              <TableCell>
                                {formatCurrency(invoice.total, "BRL")}
                              </TableCell>
                              <TableCell>
                                <Link to={`/evaluation/document/${invoice.id}`}>
                                  <Button variant="outline" size="sm">
                                    <FileMagnifyingGlass className="size-5" />
                                  </Button>
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <Scrollbar orientation="vertical" />
                    <Scrollbar orientation="horizontal" />
                  </ScrollArea>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}
