import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { Card } from "@/components/ui/card.tsx";
import { cn } from "@/lib/utils.ts";
import {
  BuildingOffice,
  Database,
  MapPin,
  Bank,
  ReadCvLogo,
  FileLock,
  WarningCircle,
} from "@phosphor-icons/react";
import { Separator } from "@/components/ui/separator.tsx";
import { CreateCompanyStep1 } from "@/pages/companies/create-new/steps/step-1.tsx";
import { CreateCompanyStep2 } from "@/pages/companies/create-new/steps/step-2.tsx";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { companySchema, RegimeTributary } from "@shared/schemas/company.ts";
import { addressSchema } from "@shared/schemas/common.ts";
import { z } from "zod";
import {
  createSchemaFromSteps,
  MultiStepFormContext,
  useMultiStepForm,
} from "@/hooks/use-multistep.ts";
import { CreateCompanyStep3 } from "@/pages/companies/create-new/steps/step-3.tsx";
import { CreateCompanyStep4 } from "@/pages/companies/create-new/steps/step-4.tsx";
import { CreateCompanyStep5 } from "@/pages/companies/create-new/steps/step-5.tsx";
import { CreateCompanyStep6 } from "@/pages/companies/create-new/steps/step-6.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi, isApiError } from "@/lib/api.ts";
import { Form } from "@/components/ui/form.tsx";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const steps = [
  {
    name: "company",
    title: "Informações básicas",
    description: "Dados cadastrais da empresa",
    component: CreateCompanyStep1,
    icon: BuildingOffice,
    schema: companySchema.omit({
      municipalRegistration: true,
      stateRegistration: true,
      tributaryRegime: true,
    }),
  },
  {
    title: "Endereço",
    name: "address",
    description: "Endereço da empresa",
    component: CreateCompanyStep2,
    icon: MapPin,
    schema: addressSchema,
  },
  {
    name: "certificate",
    title: "Certificado",
    description: "Certificado para acesso dos serviços de NF-e ou NFS-e.",
    component: CreateCompanyStep3,
    icon: FileLock,
    schema: z.object({
      certificate: z
        .string({
          required_error: "Por favor, selecione um certificado válido.",
        })
        .min(1, "Por favor, selecione um certificado válido.")
        .base64("Certificado inválido."),
      certificatePassword: z
        .string({
          required_error: "Preencha a senha para desbloqueio do certificado.",
        })
        .min(4, "Preencha a senha para desbloqueio do certificado."),
      certificateDocument: z.string().optional(),
      certificateExpiration: z.coerce.date().optional(),
    }),
  },
  {
    name: "services",
    title: "Serviços",
    description: "Quais serviços serão prestados pela empresa?",
    component: CreateCompanyStep4,
    icon: Database,
    schema: z.object({
      sendServiceInvoice: z.boolean().default(false),
      receiveProductInvoice: z.boolean().default(false),
      sendProductInvoice: z.boolean().default(false),

      sendProductInvoiceSeries: z.coerce.number().optional(),
      sendProductInvoiceNextNumber: z.coerce.number().optional(),
      sendServiceInvoiceRPSNumber: z.coerce.number().optional(),
      sendServiceInvoiceRPSSeries: z.coerce.number().optional(),
      sendServiceUsername: z.string().optional(),
      sendServicePassword: z.string().optional(),
    }),
  },
  {
    name: "tributes",
    title: "Tributos",
    description: "Tributos e regime da empresa",
    component: CreateCompanyStep5,
    icon: Bank,
    schema: z.object({
      tributaryRegime: z.nativeEnum(RegimeTributary, {
        required_error: "Regime tributário é obrigatório",
      }),
      municipalRegistration: z.string(),
      stateRegistration: z.string(),
    }),
  },
  {
    name: "resume",
    title: "Resumo",
    description: "Confira os dados da sua empresa",
    component: CreateCompanyStep6,
    icon: ReadCvLogo,
  },
] as const;

const createCompanySchema = createSchemaFromSteps(steps).superRefine(
  (data, ctx) => {
    if (data.services.sendServiceInvoice) {
      if (!data.tributes.municipalRegistration) {
        ctx.addIssue({
          code: "custom",
          message: "Inscrição municipal é obrigatória quando utilizando NFSes",
          path: ["tributes", "municipalRegistration"],
        });
      }
    }

    if (
      (data.services.receiveProductInvoice ||
        data.services.sendProductInvoice) &&
      !data.tributes.stateRegistration
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Inscrição estadual é obrigatória quando utilizando NFes",
        path: ["tributes", "stateRegistration"],
      });
    }
  }
);

export function CreateCompany() {
  const companyCreateForm = useForm<z.infer<typeof createCompanySchema>>({
    resolver: zodResolver(createCompanySchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      company: {
        document: "",
        name: "",
        fantasyName: "",
        phone: "",
        email: "",
      },
      address: {
        street: "",
        number: "",
        district: "",
        zipCode: "",
        complement: "",
        cityCode: "",
        stateCode: "",
      },
      certificate: {
        certificate: "",
        certificateDocument: "",
        certificateExpiration: new Date(),
        certificatePassword: "",
      },
      services: {
        sendServiceInvoice: false,
        sendProductInvoice: false,
        receiveProductInvoice: false,
        sendProductInvoiceSeries: 1,
        sendProductInvoiceNextNumber: 1,
        sendServiceInvoiceRPSNumber: 1,
        sendServiceInvoiceRPSSeries: 1,
        sendServiceUsername: "",
        sendServicePassword: "",
      },
      tributes: {
        tributaryRegime: RegimeTributary.REGIME_NORMAL,
        municipalRegistration: "",
        stateRegistration: "",
      },
    },
  });

  const multiStepForm = useMultiStepForm(
    companyCreateForm,
    createCompanySchema,
    steps,
    true
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: createCompany } = useMutation({
    mutationKey: ["create-company"],
    mutationFn: async (data: z.infer<typeof createCompanySchema>) => {
      return fetchApi.post("/companies", data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["/companies"],
      });

      navigate("/companies");
    },
    onError: (err) => {
      if (isApiError(err)) {
        if (err.status === 409) {
          companyCreateForm.setError("company.document", {
            type: "manual",
            message: "Esta empresa já está cadastrada.",
          });

          multiStepForm.goToStep(0);

          toast.error("Esta empresa já está cadastrada.");
        } else {
          toast.error(
            "Ocorreu um erro ao cadastrar a empresa, contate o suporte."
          );
        }
      }
    },
  });

  const currentStepIndex = multiStepForm.currentStepIndex;
  const currentStep = steps[currentStepIndex];

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Cadastro de empresa</PageTitle>
        <PageDescription>
          Preencha os dados da sua empresa para começar a usufruir das
          funcionalidades oferecidas pelo Twokei.
        </PageDescription>
      </PageHeader>
      <PageContent className="flex flex-col items-center justify-center gap-4">
        <MultiStepFormContext.Provider value={multiStepForm}>
          <Card className="flex flex-col sm:flex-row w-full max-w-6xl min-h-[45rem] overflow-hidden rounded shadow-sm">
            <div className="flex flex-row md:flex-col max-w-96 lg:max-w-72 w-full">
              {steps.map((step, i) => (
                <button
                  key={i}
                  className={cn(
                    "relative flex items-center gap-2 p-6 text-foreground w-full",
                    currentStepIndex === i &&
                      "bg-primary/15 shadow font-medium",
                    "transition-all duration-200"
                  )}
                  data-active={currentStepIndex === i}
                  onClick={() => multiStepForm.goToStep(i)}
                >
                  <div
                    className={cn(
                      "absolute left-0 h-full w-1 bg-primary transition-all duration-200",
                      currentStepIndex === i ? "opacity-100" : "opacity-0",
                      "rounded-tl rounded-bl"
                    )}
                  />
                  {"icon" in step && (
                    <step.icon className="h-6 w-6 data-[active]:text-primary" />
                  )}
                  <span className="text-left flex-1">{step.title}</span>
                  {multiStepForm.invalidSteps.includes(step.name) && (
                    <span className="text-red-600 absolute top-1 right-1 text-xs">
                      <WarningCircle className="size-4" />
                    </span>
                  )}
                </button>
              ))}
            </div>
            <div className="w-full h-0.5 sm:w-1 sm:h-full bg-primary/15" />
            <div className="flex flex-col gap-2 p-4 w-full">
              <span className="text-muted-foreground">
                {"description" in currentStep && currentStep.description}
              </span>
              <Separator className="w-full mb-2" />
              <Form {...companyCreateForm}>
                <form
                  className="h-full"
                  onSubmit={companyCreateForm.handleSubmit((data) =>
                    createCompany(data)
                  )}
                >
                  <currentStep.component />
                </form>
              </Form>
            </div>
          </Card>
        </MultiStepFormContext.Provider>
      </PageContent>
    </PageContainer>
  );
}
