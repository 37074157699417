import { SelectPopover } from "@/components/select-popover.tsx";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import type { NewProductInvoice } from "@shared/schemas/invoices/nfe/new_product.ts";
import { useVendors } from "@/hooks/use-vendors.ts";
import { isCPF } from "validation-br";
import { Label } from "@/components/ui/label.tsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";
import type { Vendor } from "@shared/schemas/vendor.ts";
import { Input } from "@/components/ui/input.tsx";
import { FormFieldSimple } from "@/components/ui/form.tsx";
import { DocumentInput } from "@/components/validators/document-input.tsx";
import { NFeIndicadorInscricaoEstadual } from "@shared/schemas/invoices/nfe/focus/focus_product.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

type Cities = {
  cities: Array<{ name: string; code: string }>;
};

export function NewInvoiceProductRecipient() {
  const form = useFormContext<NewProductInvoice>();

  const { data: dataStates, isLoading: isLoadingStates } = useQuery<{
    states: Array<{ name: string; code: string }>;
  }>({
    queryKey: ["/locations/states"],
  });

  const state = form.watch("recipient.address.state");

  const { data: stateCities, isLoading: isLoadingCities } = useQuery({
    queryKey: ["/external/state/cities", state],
    queryFn: () =>
      fetchApi
        .get(`/locations/states/${state}/cities`)
        .then((res) => res.data.data as Cities),
    enabled: !!state,
    staleTime: Infinity,
  });

  const { data: dataVendors } = useVendors();

  const { mutateAsync } = useMutation({
    mutationKey: ["/vendors"],
    mutationFn: async (vendor: string) => {
      const res = await fetchApi.get<{ data: Vendor }>(`/vendors/${vendor}`);
      return res.data.data;
    },
    onSuccess: () => {
      toast.success(
        "As informações do contato foram selecionadas com sucesso!",
        {
          position: "top-center",
        }
      );
    },
  });

  const handleVendorChange = async (vendor: string) => {
    const vendorData = await mutateAsync(vendor);

    form.setValue("recipient.document", vendorData.document);
    form.setValue("recipient.name", vendorData.name);

    if (vendorData.address) {
      form.setValue("recipient.address.street", vendorData.address.street);
      form.setValue("recipient.address.number", vendorData.address.number);
      form.setValue(
        "recipient.address.complement",
        vendorData.address.complement ?? ""
      );
      form.setValue("recipient.address.district", vendorData.address.district);
      form.setValue("recipient.address.city", vendorData.address.city);
      form.setValue("recipient.address.state", vendorData.address.stateCode);
      form.setValue("recipient.address.zipCode", vendorData.address.zipCode);
      form.setValue("recipient.address.cityCode", vendorData.address.cityCode);
    }

    if (vendorData.stateRegistration) {
      form.setValue(
        "recipient.stateRegistration",
        vendorData.stateRegistration
      );
      form.setValue(
        "recipient.stateIndicator",
        vendorData.stateRegistration
          ? NFeIndicadorInscricaoEstadual.CONTRIBUINTE_ICMS
          : NFeIndicadorInscricaoEstadual.NAO_CONTRIBUINTE
      );
    }

    form.clearErrors("recipient");
  };

  const [recipientDocument, recipientStateIndicator] = useWatch({
    control: form.control,
    name: ["recipient.document", "recipient.stateIndicator"],
  });

  const isDocumentCPF = isCPF(recipientDocument);
  const documentType = isDocumentCPF ? "CPF" : "CNPJ";
  const name = isDocumentCPF ? "Nome Completo" : "Razão Social";
  const isIcmsContributor =
    recipientStateIndicator === NFeIndicadorInscricaoEstadual.CONTRIBUINTE_ICMS;

  const getCityNameByCode = (code: string) => {
    return stateCities?.cities.find((city) => city.code === code)?.name ?? "";
  };

  return (
    <div className="grid grid-cols-6 gap-3">
      <div className="space-y-2 col-span-6">
        <Label>
          Selecione um contato salvo ou preencha os dados de um novo
          destinatário
        </Label>
        <SelectPopover
          items={
            dataVendors?.vendors.map((vendor) => ({
              value: vendor.document,
              label: `${isCPF(vendor.document) ? "CPF" : "CNPJ"}: ${vendor.document} - ${vendor.name}`,
              keywords: [vendor.name],
            })) ?? []
          }
          value={recipientDocument}
          onValueChange={handleVendorChange}
        />
      </div>
      <FormFieldSimple
        control={form.control}
        name="recipient.document"
        label="Documento (CNPJ ou CPF)"
        required
        render={({ field }) => (
          <DocumentInput
            {...field}
            format={documentType}
            onChange={(value) => {
              field.onChange(value);
            }}
          />
        )}
      />
      <FormFieldSimple
        className="col-span-3"
        control={form.control}
        name="recipient.name"
        label={name}
        required
        render={({ field }) => (
          <Input
            {...field}
            placeholder={
              isDocumentCPF
                ? "Ex.: Fulano da Silva"
                : "Ex.: Empresa de Serviços Ltda."
            }
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.stateRegistration"
        label="Inscrição Estadual"
        required={!isDocumentCPF && isIcmsContributor}
        render={({ field }) => (
          <Input {...field} placeholder="Ex.: 123456789" />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.stateIndicator"
        label="Indicador de Inscrição Estadual"
        required={!isDocumentCPF}
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                value={NFeIndicadorInscricaoEstadual.CONTRIBUINTE_ICMS}
              >
                1 - Contribuinte ICMS
              </SelectItem>
              <SelectItem
                value={NFeIndicadorInscricaoEstadual.CONTRIBUINTE_ISENTO}
              >
                2 - Contribuinte Isento
              </SelectItem>
              <SelectItem
                value={NFeIndicadorInscricaoEstadual.NAO_CONTRIBUINTE}
              >
                3 - Não Contribuinte
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <FormFieldSimple
        className="col-span-3"
        control={form.control}
        name="recipient.address.street"
        label="Logradouro"
        required
        render={({ field }) => (
          <Input {...field} placeholder="Ex.: Rua Exemplo" />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.address.complement"
        label="Complemento"
        render={({ field }) => <Input {...field} placeholder="Ex.: Apto 123" />}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.address.number"
        label="Número"
        required
        render={({ field }) => <Input {...field} placeholder="Ex.: 123" />}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.address.district"
        label="Bairro"
        required
        render={({ field }) => (
          <Input {...field} placeholder="Ex.: Vila do Sapo" />
        )}
      />

      <FormFieldSimple
        control={form.control}
        name="recipient.address.state"
        label="UF"
        required
        render={({ field }) => (
          <SelectPopover
            items={
              dataStates?.states.map((state) => ({
                value: state.code,
                label: state.name,
                keywords: [state.name],
              })) ?? []
            }
            value={field.value}
            onValueChange={(value) => {
              form.resetField("recipient.address.city");
              form.resetField("recipient.address.cityCode");
              field.onChange(value);
            }}
            loading={isLoadingStates}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.address.cityCode"
        label="Município"
        required
        render={({ field }) => (
          <SelectPopover
            items={
              stateCities?.cities.map((city) => ({
                value: city.code,
                label: city.name,
                keywords: [city.name],
              })) ?? []
            }
            value={field.value}
            onValueChange={(value) => {
              field.onChange(value);
              form.setValue("recipient.address.city", getCityNameByCode(value));
            }}
            loading={isLoadingCities}
          />
        )}
      />

      <FormFieldSimple
        control={form.control}
        name="recipient.address.zipCode"
        label="CEP"
        required
        render={({ field }) => (
          <Input {...field} placeholder="Ex.: 123456789" />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.phone"
        label="Telefone"
        render={({ field }) => (
          <Input {...field} placeholder="Ex.: 21669784781" />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="recipient.email"
        label="Email"
        render={({ field }) => (
          <Input {...field} placeholder="Ex.: email@exemplo.com.br" />
        )}
      />
    </div>
  );
}
