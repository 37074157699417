import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { isAxiosError } from "axios";
import { toast } from "sonner";

type UpdateCredentialsMutation =
  | {
      username: string;
      password: string;
    }
  | {
      certificate: string;
      certificatePassword: string;
    };

type InitType = {
  document?: string;
  type: "certificate" | "credentials";
};

export function useDeleteCredentialsMutation({ document, type }: InitType) {
  const queryClient = useQueryClient();
  const mutationKey = `/companies/${document}/credentials/${type}`;

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: () =>
      fetchApi<{ status: "success" | "error" }>(mutationKey, {
        method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/credentials`],
      });
    },
  });
}

export function useUpdateCredentialsMutation({ document, type }: InitType) {
  const queryClient = useQueryClient();
  const mutationKey = `/companies/${document}/credentials/${type}`;

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: (fields: UpdateCredentialsMutation) =>
      fetchApi<{ status: "success" | "error" }>(mutationKey, {
        method: "POST",
        data: {
          ...fields,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/credentials`],
      });
      toast.success("Credenciais atualizadas com sucesso.");
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        const errorMessage = error.response?.data.error.message;

        if (errorMessage === "INVALID_CERTIFICATE") {
          toast.error("Certificado inválido.");
          return;
        }

        if (errorMessage === "CERTIFICATE_EXPIRED") {
          toast.error("Este certificado está expirado.");
          return;
        }

        if (errorMessage === "CERTIFICATE_INVALID_OWNER") {
          toast.error("Este certificado não pertence a esta empresa.");
          return;
        }
      }

      toast.error(
        "Conseguimos ler as informações do certificado, porém não foi possível validar o conteúdo. Entre em contato com o suporte para mais informações."
      );
    },
  });
}
