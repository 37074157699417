import { z } from "zod";
import {
  NFeConsumidorFinal,
  NFeFinalidadeEmissao,
  NFeIndicadorInscricaoEstadual,
  NFeItemIcmsOrigem,
  NFeItemIcmsSituacaoTributaria,
  NFeItemPisCofinsSituacaoTributaria,
  NFeLocalDestino,
  NFeModalidadeFrete,
  NFePresencaComprador,
  NFeTipoDocumento,
} from "./focus/focus_product";
import { CFOP } from "../../../constants/cfop";
import { RegimeTributary } from "../../company";
import { isCPF } from "validation-br";

enum CalculationType {
  NONE = "none",
  PERCENTAGE = "percentage",
  VALUE = "value",
}

const transformToUndefined = (value: string | null | undefined) => {
  return !!value ? value : undefined;
};
const issuerSchema = z
  .object({
    document: z.string().min(1, "Documento do emitente é obrigatório"), //cnpj_emitente ou cpf_emitente
    name: z.string().min(1, "Nome do emitente é obrigatório"), //nome_emitente
    address: z.object({
      street: z.string().min(1, "Logradouro do emitente é obrigatório"), //endereco_emitente
      number: z.string().min(1, "Número do logradouro é obrigatório"), //numero_emitente
      complement: z.string().nullish().transform(transformToUndefined), //complemento_emitente
      district: z.string().min(1, "Bairro é obrigatório"), //bairro_emitente
      zipCode: z.string().min(1, "CEP é obrigatório"), //cep_emitente
      cityCode: z.string().min(1, "Código da cidade é obrigatório"), //codigo_municipio_emitente
      state: z.string().min(1, "Estado é obrigatório"), //uf_emitente
      city: z.string().min(1, "Cidade é obrigatória"), //municipio_emitente
    }),
    phone: z.string().nullish().transform(transformToUndefined), //telefone_emitente
    stateRegistration: z.string().min(1, "Inscrição Estadual é obrigatória"), //inscricao_estadual_emitente
    stStateRegistration: z.string().nullish().transform(transformToUndefined), //inscricao_estadual_st_emitente
    municipalRegistration: z.string().nullish().transform(transformToUndefined), //inscricao_municipal_emitente
    cnaeFiscal: z.string().nullish().transform(transformToUndefined), //cnae_fiscal_emitente
    tributaryRegime: z.nativeEnum(RegimeTributary), //regime_tributario_emitente
  })
  .superRefine((value, ctx) => {
    if (value.municipalRegistration) {
      if (!value.cnaeFiscal) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "CNAE Fiscal é obrigatório",
          path: ["issuer", "cnaeFiscal"],
        });
      }
    }

    if (value.cnaeFiscal) {
      if (!value.municipalRegistration) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Inscrição Municipal é obrigatório",
          path: ["issuer", "municipalRegistration"],
        });
      }
    }
  });

const recipientSchema = z
  .object({
    document: z.string().min(1, "Documento do destinatário é obrigatório"),
    name: z.string().min(1, "Nome do destinatário é obrigatório"),
    phone: z.string().nullish().transform(transformToUndefined),
    email: z.string().nullish().transform(transformToUndefined),
    address: z.object({
      street: z.string().min(1, "Logradouro do destinatário é obrigatório"),
      complement: z.string().nullish().transform(transformToUndefined),
      number: z.string().min(1, "Número do logradouro é obrigatório"),
      district: z.string().min(1, "Bairro é obrigatório"),
      zipCode: z.string().min(1, "CEP é obrigatório"),
      cityCode: z.string().min(1, "Cidade é obrigatória"),
      state: z.string().min(1, "Estado é obrigatório"),
      city: z.string().min(1, "Cidade é obrigatória"),
    }),
    stateRegistration: z.string().nullish().transform(transformToUndefined),
    municipalRegistration: z.string().nullish().transform(transformToUndefined),
    stateIndicator: z.nativeEnum(NFeIndicadorInscricaoEstadual),
  })
  .superRefine((value, ctx) => {
    if (
      value.stateIndicator === NFeIndicadorInscricaoEstadual.CONTRIBUINTE_ICMS
    ) {
      if (isCPF(value.document)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "O indicador de contribuinte é isento para CPF (Pessoa Física)",
          path: ["recipient", "stateRegistration"],
        });
      }

      if (!value.stateRegistration) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Inscrição Estadual é obrigatória para o indicador de contribuinte",
          path: ["recipient", "stateRegistration"],
        });
      }
    }
  });

const itemPresumedCreditInfoSchema = z.object({
  taxBenefitCode: z.string().nullish().transform(transformToUndefined), //codigo_beneficio_fiscal
  percentage: z.string().nullish().transform(transformToUndefined), //percentual
  value: z.string().nullish().transform(transformToUndefined), //valor
});

const ipiTaxSchema = z.object({
  taxSituation: z
    .string()
    // .nativeEnum(NFeItemIpiSituacaoTributaria, {
    //   required_error: "Operação não informada.",
    // })
    .nullish()
    .transform(transformToUndefined), //ipi_situacao_tributaria
  producerBusinessDocument: z
    .string()
    .nullish()
    .transform(transformToUndefined), //ipi_cnpj_produtor
  sealCode: z
    .string()
    // .nativeEnum(NFeItemIpiCodigoSeloControle)
    .nullish()
    .transform(transformToUndefined), //ipi_codigo_selo_controle
  sealQuantity: z.string().nullish().transform(transformToUndefined), //ipi_quantidade_selo_controle
  frameworkCode: z.string().nullish().transform(transformToUndefined), //ipi_codigo_enquadramento_legal

  calculationPercentage: z.boolean().default(false), //INTERNO (nao utilizado na focus)

  // Por porcentagem
  calculationBase: z.string().nullish().transform(transformToUndefined), //ipi_base_calculo
  percentageAliquot: z.string().nullish().transform(transformToUndefined), //ipi_aliquota

  // Por valor
  aliquotValue: z.string().nullish().transform(transformToUndefined), //ipi_valor_por_unidade_tributavel

  // Total
  value: z.string().nullish().transform(transformToUndefined), //ipi_valor
});

const icmsTaxSchema = z.object({
  origin: z.nativeEnum(NFeItemIcmsOrigem), //icms_origem
  taxSituation: z.nativeEnum(NFeItemIcmsSituacaoTributaria), //icms_situacao_tributaria
  calculationMode: z
    .string()
    // .nativeEnum(NFeItemIcmsModalidadeBaseCalculo)
    .nullish()
    .transform(transformToUndefined), //icms_modalidade_base_calculo
  calculationBase: z.string().nullish().transform(transformToUndefined), //icms_base_calculo

  taxBenefitCodeBaseReduction: z
    .string()
    .nullish()
    .transform(transformToUndefined), //codigo_beneficio_fiscal_reducao_base_calculo

  value: z.string().nullish().transform(transformToUndefined), //icms_valor
  substituteValue: z.string().nullish().transform(transformToUndefined), //icms_valor_substituto
  operationValue: z.string().nullish().transform(transformToUndefined), //icms_valor_operacao
  ownOperationCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_base_calculo_operacao_propria

  aliquot: z.string().nullish().transform(transformToUndefined), //icms_aliquota
  aliquotRetentionValue: z.string().nullish().transform(transformToUndefined), //icms_aliquota_retencao
  finalAliquot: z.string().nullish().transform(transformToUndefined), //icms_aliquota_final
  withheldAliquot: z.string().nullish().transform(transformToUndefined), //icms_aliquota_retido

  deferralPercentage: z.string().nullish().transform(transformToUndefined), //icms_percentual_diferimento
  deferredValue: z.string().nullish().transform(transformToUndefined), //icms_valor_diferido

  exemptedValue: z.string().nullish().transform(transformToUndefined), //icms_valor_desonerado
  exemptionReason: z
    .string()
    // .nativeEnum(NFeItemIcmsMotivoDesoneracao)
    .nullish()
    .transform(transformToUndefined), //icms_motivo_desoneracao
  exemptionDeduction: z
    .string()
    // .nativeEnum(NFeItemIcmsDeducaoDesoneracao)
    .nullish()
    .transform(transformToUndefined), //icms_deducao_desoneracao

  calculationBaseReduction: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_reducao_base_calculo
  reductionPercentage: z.string().nullish().transform(transformToUndefined), //icms_percentual_reducao
  reductionReason: z
    .string()
    // .nativeEnum(NFeItemIcmsMotivoReducao)
    .nullish()
    .transform(transformToUndefined), //icms_motivo_reducao

  issuerWithheldCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_base_calculo_retido_remetente
  issuerWithheldValue: z.string().nullish().transform(transformToUndefined), //icms_valor_retido_remetente

  recipientCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_base_calculo_destino
  recipientValue: z.string().nullish().transform(transformToUndefined), //icms_valor_destino

  simpleCreditAliquot: z.string().nullish().transform(transformToUndefined), //icms_aliquota_credito_simples
  simpleCreditValue: z.string().nullish().transform(transformToUndefined), //icms_valor_credito_simples

  effectiveCalculationBaseReduction: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_reducao_base_calculo_efetiva
  effectiveCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_base_calculo_efetiva
  effectiveAliquot: z.string().nullish().transform(transformToUndefined), //icms_aliquota_efetiva
  effectiveValue: z.string().nullish().transform(transformToUndefined), //icms_valor_efetivo

  monoCalculationBase: z.string().nullish().transform(transformToUndefined), //icms_base_calculo_mono
  monoWithheldValue: z.string().nullish().transform(transformToUndefined), //icms_valor_mono_retido
  monoWithheldCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_base_calculo_mono_retido
  monoRetentionCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_base_calculo_mono_retencao
  monoValue: z.string().nullish().transform(transformToUndefined), //icms_valor_mono
  monoRetentionValue: z.string().nullish().transform(transformToUndefined), //icms_valor_mono_retencao
  monoOperationValue: z.string().nullish().transform(transformToUndefined), //icms_valor_mono_operacao
  monoDeferredValue: z.string().nullish().transform(transformToUndefined), //icms_valor_mono_diferido

  stCalculationBaseReduction: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_reducao_base_calculo_st
  stCalculationBase: z.string().nullish().transform(transformToUndefined), //icms_base_calculo_st
  stWithheldCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //icms_base_calculo_retido_st
  stWithheldValue: z.string().nullish().transform(transformToUndefined), //icms_valor_retido_st
  stExemptedValue: z.string().nullish().transform(transformToUndefined), //icms_valor_icms_st_desonerado
  stExemptionReason: z
    .string()
    // .nativeEnum(NFeItemIcmsMotivoDesoneracaoSt)
    .nullish()
    .transform(transformToUndefined), //icms_motivo_desoneracao_icms_st
  stCalculationMode: z
    .string()
    // .nativeEnum(NFeItemIcmsModalidadeBaseCalculoSt)
    .nullish()
    .transform(transformToUndefined), //icms_modalidade_base_calculo_st
  stAddedValueMargin: z.string().nullish().transform(transformToUndefined), //icms_margem_valor_adicionado_st
  stAliquot: z.string().nullish().transform(transformToUndefined), //icms_aliquota_st
  stValue: z.string().nullish().transform(transformToUndefined), //icms_valor_st
  stState: z.string().nullish().transform(transformToUndefined), //icms_uf_st

  fcpCalculationBase: z.string().nullish().transform(transformToUndefined), //fcp_base_calculo
  fcpPercentage: z.string().nullish().transform(transformToUndefined), //fcp_percentual
  fcpValue: z.string().nullish().transform(transformToUndefined), //fcp_valor
  fcpDeferralPercentage: z.string().nullish().transform(transformToUndefined), //icms_fcp_percentual_diferimento
  fcpDeferredValue: z.string().nullish().transform(transformToUndefined), //icms_fcp_valor_diferido
  fcpEffectiveValue: z.string().nullish().transform(transformToUndefined), //icms_fcp_valor_efetivo

  fcpStCalculationBase: z.string().nullish().transform(transformToUndefined), //fcp_base_calculo_st
  fcpStValue: z.string().nullish().transform(transformToUndefined), //fcp_valor_st
  fcpStWithheldCalculationBase: z
    .string()
    .nullish()
    .transform(transformToUndefined), //fcp_base_calculo_retido_st
  fcpStWithheldPercentage: z.string().nullish().transform(transformToUndefined), //fcp_percentual_retido_st
  fcpStWithheldValue: z.string().nullish().transform(transformToUndefined), //fcp_valor_retido_st
});

const pisTaxSchema = z.object({
  taxSituation: z.nativeEnum(NFeItemPisCofinsSituacaoTributaria, {
    required_error: "É necessário informar a situação tributária do PIS",
  }), //pis_situacao_tributaria

  value: z.string().nullish().transform(transformToUndefined), //pis_valor

  calculationPercentage: z.boolean().default(false), //INTERNO (nao utilizado na focus)

  // Por porcentagem
  calculationBase: z.string().nullish().transform(transformToUndefined), //pis_base_calculo
  percentageAliquot: z.string().nullish().transform(transformToUndefined), //pis_aliquota_porcentual

  // Por valor
  aliquotValue: z.string().nullish().transform(transformToUndefined), //pis_aliquota_valor

  stCalculationType: z.nativeEnum(CalculationType), //INTERNO (nao utilizado na focus)

  // Por porcentagem
  stCalculationBase: z.string().nullish().transform(transformToUndefined), //pis_base_calculo_st
  stPercentageAliquot: z.string().nullish().transform(transformToUndefined), //pis_aliquota_porcentual_st

  // Por valor
  stAliquotValue: z.string().nullish().transform(transformToUndefined), //pis_aliquota_valor_st

  // Total ST
  stValue: z.string().nullish().transform(transformToUndefined), //pis_valor_st
});

const cofinsTaxSchema = z.object({
  taxSituation: z.nativeEnum(NFeItemPisCofinsSituacaoTributaria, {
    required_error: "É necessário informar a situação tributária do COFINS",
  }), //cofins_situacao_tributaria

  value: z.string().nullish().transform(transformToUndefined), //cofins_valor

  calculationPercentage: z.boolean().default(false), //INTERNO (nao utilizado na focus)

  // Por porcentagem
  calculationBase: z.string().nullish().transform(transformToUndefined), //cofins_base_calculo
  percentageAliquot: z.string().nullish().transform(transformToUndefined), //cofins_aliquota_porcentual

  // Por valor
  aliquotValue: z.string().nullish().transform(transformToUndefined), //cofins_aliquota_valor

  stCalculationType: z.nativeEnum(CalculationType), //INTERNO (nao utilizado na focus)

  // Por porcentagem
  stCalculationBase: z.string().nullish().transform(transformToUndefined), //cofins_base_calculo_st
  stPercentageAliquot: z.string().nullish().transform(transformToUndefined), //cofins_aliquota_porcentual_st

  // Por valor
  stAliquotValue: z.string().nullish().transform(transformToUndefined), //cofins_aliquota_valor_st

  // Total ST
  stValue: z.string().nullish().transform(transformToUndefined), //cofins_valor_st
});

const productInvoiceItemSchema = z
  .object({
    dbId: z.string().optional(),

    description: z.string().min(1, "O nome do produto é obrigatório"), //descricao
    code: z.string().nullish().transform(transformToUndefined), //codigo_produto

    cfop: z.string().refine(
      (value) => CFOP.valid(value),
      (value) => {
        if (!value) return { message: `O CFOP é obrigatório` };
        return { message: `O CFOP (${value}) é inválido` };
      }
    ), //cfop

    commercialUnit: z.string().min(1, "A unidade comercial é obrigatória"), //unidade_comercial
    commercialQuantity: z
      .string()
      .min(1, "A quantidade comercial é obrigatória"), //quantidade_comercial
    commercialUnitValue: z
      .string()
      .min(1, "O valor unitário comercial é obrigatório"), //valor_unitario_comercial
    commercialBarCode: z.string().nullish().transform(transformToUndefined), //codigo_barras_comercial

    differentTaxation: z.boolean().default(false), // INTERNO (nao utilizado na focus)

    taxableUnit: z.string().nullish().transform(transformToUndefined), //unidade_tributavel
    taxableQuantity: z.string().nullish().transform(transformToUndefined), //quantidade_tributavel
    taxableUnitValue: z.string().nullish().transform(transformToUndefined), //valor_unitario_tributavel
    taxableBarCode: z.string().nullish().transform(transformToUndefined), //codigo_barras_tributavel

    freightValue: z.string().nullish().transform(transformToUndefined), //valor_frete
    insuranceValue: z.string().nullish().transform(transformToUndefined), //valor_seguro
    discountValue: z.string().nullish().transform(transformToUndefined), //valor_desconto
    othersValue: z.string().nullish().transform(transformToUndefined), //valor_outras_despesas
    taxApproximateValue: z.string().nullish().transform(transformToUndefined), //valor_total_tributos (calculado automaticamente se o consumidor_final for igual a 1)

    ncmCode: z.string().min(1, "O código NCM é obrigatório"), //codigo_ncm
    tipiExceptionCode: z.string().nullish().transform(transformToUndefined), //codigo_ex_tipi
    includeInTotal: z.boolean().default(false), //inclui_no_total
    stateTaxBeneficiaryCode: z
      .string()
      .nullish()
      .transform(transformToUndefined), //codigo_beneficio_fiscal
    presumedCreditInfos: z
      .array(itemPresumedCreditInfoSchema)
      .max(4, "O máximo de informações de crédito presumido é 4"), //infos_credito_presumido

    declareIpiTax: z.boolean().default(false), // INTERNO (nao utilizado na focus)
    ipiTax: ipiTaxSchema,

    pisTax: pisTaxSchema,
    cofinsTax: cofinsTaxSchema,
    icmsTax: icmsTaxSchema,

    observations: z
      .string()
      .max(500, "O máximo de observações é 500")
      .nullish()
      .transform(transformToUndefined), //informacoes_adicionais_item
  })
  .superRefine((value, ctx) => {
    if (value.differentTaxation) {
      if (!value.taxableUnit) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A unidade tributável é obrigatória",
          path: ["items", "taxUnit"],
        });
      }

      if (!value.taxableQuantity) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A quantidade tributável é obrigatória",
          path: ["items", "taxQuantity"],
        });
      }

      if (!value.taxableUnitValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O valor unitário tributável é obrigatório",
          path: ["items", "taxUnitValue"],
        });
      }
    }
  });

const freightSealSchema = z.object({
  number: z.string().max(60).nullish().transform(transformToUndefined), //numero
});

const freightVolumeSchema = z.object({
  quantity: z.string().nullish().transform(transformToUndefined), //quantidade
  species: z.string().max(60).nullish().transform(transformToUndefined), //especie
  brand: z.string().max(60).nullish().transform(transformToUndefined), //marca
  number: z.string().max(60).nullish().transform(transformToUndefined), //numero
  netWeight: z.string().nullish().transform(transformToUndefined), //peso_liquido
  grossWeight: z.string().nullish().transform(transformToUndefined), //peso_bruto
  seals: z.array(freightSealSchema).max(5000, "O máximo de lacres é 5000"), //lacres
});

const freightTributesSchema = z.object({
  serviceValue: z.string().nullish().transform(transformToUndefined), //transporte_icms_servico
  icmsBaseRetention: z.string().nullish().transform(transformToUndefined), //transporte_icms_base_calculo
  retentionAliquot: z.string().nullish().transform(transformToUndefined), //transporte_icms_aliquota
  icmsRetentionValue: z.string().nullish().transform(transformToUndefined), //transporte_icms_valor
  transportationCfop: z
    .string()
    .refine(
      (value) => value && CFOP.validFreight(value),
      (value) => ({
        message: `CFOP (${value}) inválido para a modalidade frete`,
      })
    )
    .nullish()
    .transform(transformToUndefined), //transporte_icms_cfop
  icmsState: z.string().nullish().transform(transformToUndefined), // INTERNO (nao utilizado na focus)
  icmsCityCode: z.string().nullish().transform(transformToUndefined), //transporte_icms_codigo_municipio
});

const freightSchema = z
  .object({
    modality: z.nativeEnum(NFeModalidadeFrete, {
      required_error: "Modalidade do frete é obrigatória",
    }), //modalidade_frete
    carrierName: z.string().nullish().transform(transformToUndefined),
    carrierDocument: z.string().nullish().transform(transformToUndefined), //cnpj_transportador or cpf_transportador
    carrierStateRegistration: z
      .string()
      .nullish()
      .transform(transformToUndefined), //inscricao_estadual_transportador
    carrierAddress: z
      .object({
        complete: z.string().nullish().transform(transformToUndefined), //endereco_transportador
        state: z.string().nullish().transform(transformToUndefined), //municipio_transportador
        city: z.string().nullish().transform(transformToUndefined), //uf_transportador
      })
      .nullish(),
    carrierVehicle: z
      .object({
        plate: z.string().nullish().transform(transformToUndefined), //veiculo_placa
        state: z.string().nullish().transform(transformToUndefined), //veiculo_uf
        rntcRegistration: z.string().nullish().transform(transformToUndefined), //veiculo_rntc
        wagonIdentification: z
          .string()
          .nullish()
          .transform(transformToUndefined), //veiculo_identificacao_vagao
        ferryIdentification: z
          .string()
          .nullish()
          .transform(transformToUndefined), //veiculo_identificacao_balsa
      })
      .nullish(),

    volumes: z
      .array(freightVolumeSchema)
      .max(5000, "O máximo de volumes é 5000")
      .nullish(), //volumes

    manuallyInformIcmsTribute: z.boolean().default(false), // INTERNO (nao utilizado na focus)
    tributes: freightTributesSchema.nullish(),
  })
  .superRefine((value, ctx) => {
    if (
      [
        NFeModalidadeFrete.POR_CONTA_DO_REMETENTE,
        NFeModalidadeFrete.POR_CONTA_DO_DESTINATARIO,
        NFeModalidadeFrete.POR_CONTA_DE_TERCEIROS,
      ].includes(value.modality)
    ) {
      if (!value.carrierName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O nome do transportador é obrigatório",
          path: ["freight", "carrierName"],
        });
      }

      if (!value.carrierDocument) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O documento do transportador é obrigatório",
          path: ["freight", "carrierDocument"],
        });
      }

      if (!value.carrierAddress?.complete) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O endereço completo do transportador é obrigatório",
          path: ["freight", "carrierAddress", "complete"],
        });
      }

      if (!value.carrierAddress?.state) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O estado (UF) do transportador é obrigatório",
          path: ["freight", "carrierAddress", "state"],
        });
      }

      if (!value.carrierAddress?.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A cidade do transportador é obrigatória",
          path: ["freight", "carrierAddress", "city"],
        });
      }

      if (!value.carrierVehicle?.plate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A placa do veículo do transportador é obrigatória",
          path: ["freight", "carrierVehicle", "plate"],
        });
      }

      if (!value.carrierVehicle?.state) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "O estado (UF) do veículo do transportador é obrigatório",
          path: ["freight", "carrierVehicle", "state"],
        });
      }
    }
  });

const othersSchema = z.object({
  complementaryInformation: z
    .string()
    .max(5000, "O máximo de informações complementares é 5000")
    .nullish()
    .transform(transformToUndefined), //informacoes_adicionais_contribuinte
  taxInformation: z
    .string()
    .max(2000, "O máximo de informações fiscais é 2000")
    .nullish()
    .transform(transformToUndefined), //informacoes_adicionais_fisco
});

const detailsSchema = z.object({
  operationNature: z
    .string()
    .min(1, "Operação da Natureza é obrigatória")
    .max(60, "A Operação da Natureza não pode exceder 60 caracteres"), //natureza_operacao
  invoiceNumber: z.string().max(3).nullish().transform(transformToUndefined), //serie
  invoiceSeries: z.string().max(9).nullish().transform(transformToUndefined), //numero
  enterExitAt: z.string().nullish().transform(transformToUndefined), //data_entrada_saida
  documentType: z.nativeEnum(NFeTipoDocumento, {
    required_error: "O tipo do documento é obrigatório",
  }), //tipo_documento
  destination: z.nativeEnum(NFeLocalDestino, {
    required_error: "O local de destino é obrigatório",
  }), //local_destino
  cityCode: z.string().nullish().transform(transformToUndefined), //municipio
  purposeOfEmission: z.nativeEnum(NFeFinalidadeEmissao, {
    required_error: "A finalidade de emissão é obrigatória",
  }), //finalidade_emissao
  endConsumer: z.nativeEnum(NFeConsumidorFinal, {
    required_error: "É necessário informar se é consumidor final",
  }), //consumidor_final
  buyerPresence: z.nativeEnum(NFePresencaComprador, {
    required_error: "É necessário informar sobre a presença do comprador",
  }), //presenca_comprador
});

const productSchema = z.object({
  invoice: z.object({
    issuedAt: z.string(), //data_emissao
    status: z.string(),
    externalId: z.string().nullable(),
  }),
  issuer: issuerSchema,
  details: detailsSchema,
  recipient: recipientSchema,
  items: z
    .array(productInvoiceItemSchema)
    .min(1, "A nota precisa conter pelo menos um item")
    .max(990, "O máximo de itens de uma nota é 990"),
  freight: freightSchema,
  others: othersSchema,
});

export type NewProductInvoice = z.infer<typeof productSchema>;

export {
  itemPresumedCreditInfoSchema,
  ipiTaxSchema,
  icmsTaxSchema,
  pisTaxSchema,
  cofinsTaxSchema,
  issuerSchema,
  detailsSchema,
  recipientSchema,
  productInvoiceItemSchema,
  freightSealSchema,
  freightVolumeSchema,
  freightTributesSchema,
  freightSchema,
  othersSchema,
  productSchema,
  CalculationType,
};
