import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import type { z } from "zod";
import { useFormContext } from "react-hook-form";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { CalendarIcon } from "lucide-react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

import { SelectPopover } from "@/components/select-popover.tsx";
import { formatCNPJ } from "@shared/format.ts";

export function ServiceBaseForm({
  companies,
}: {
  companies?: CompanyWithAddress[];
}) {
  const form = useFormContext<z.infer<typeof serviceInvoiceSchema>>();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Dados da Nota Fiscal de Serviços Eletrônica</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"flex flex-col gap-3 xl:grid xl:grid-cols-4"}>
          <FormField
            name="issuedAt"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Data de emissão</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                        disabled
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Escolha uma data</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={new Date(field.value)}
                      onSelect={(v) => {
                        field.onChange(v?.toISOString() ?? new Date());
                      }}
                      disabled={(date) =>
                        date > new Date() ||
                        date < new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.specialRegimeTributary"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Regime tributário especial</FormLabel>
                <Select onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="1">
                      1 - Microempresa Individual
                    </SelectItem>
                    <SelectItem value="2">2 - Estimativa</SelectItem>
                    <SelectItem value="3">
                      3 - Sociedade de Profissionais
                    </SelectItem>
                    <SelectItem value="4">4 - Cooperativa</SelectItem>
                    <SelectItem value="5">5 - MEI Simples Nacional</SelectItem>
                    <SelectItem value="6">
                      6 - ME EPP Simples Nacional
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.culturalSupporter"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Incentivador cultural</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value === "yes");
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="yes">Sim</SelectItem>
                    <SelectItem value="no">Não</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.operationNature"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Natureza da operação</FormLabel>
                <Select onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="1">
                      1 - Tributação no município
                    </SelectItem>
                    <SelectItem value="2">
                      2 - Tributação fora do município
                    </SelectItem>
                    <SelectItem value="3">3 - Isenção</SelectItem>
                    <SelectItem value="4">4 - Imune</SelectItem>
                    <SelectItem value="5">
                      5 - Exigibilidade suspensa por decisão judicial
                    </SelectItem>
                    <SelectItem value="6">
                      6 - Exigibilidade suspensa por procedimento administrativo
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="issuer.document"
            render={({ field }) => (
              <FormItem className="xl:col-span-2">
                <FormLabel required>CNPJ do Prestador</FormLabel>
                <SelectPopover
                  items={
                    companies?.map((company) => ({
                      value: company.document,
                      label: `${formatCNPJ(company.document)} - ${company.name}`,
                      keywords: [company.name],
                    })) ?? []
                  }
                  placeholder="Selecione a empresa"
                  value={field.value}
                  onValueChange={field.onChange}
                  disabled={!companies}
                />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
