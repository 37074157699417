import type { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { DownloadIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { format } from "date-fns";

interface GetFilesTableColumns {
  onRequestDownload: (key: string) => void;
}

const friendlyTagName: Record<
  "invoices" | "sent" | "received" | "services" | "products",
  string
> = {
  invoices: "Notas Fiscais",
  sent: "Emitidas",
  received: "Recebidas",
  services: "Serviços",
  products: "Produtos",
};

export function getFilesTableColumns({
  onRequestDownload,
}: GetFilesTableColumns): ColumnDef<{
  id: string;
  key: string;
  requestedBy: string;
  requestedByName: string;
  tags: string[];
  createdAt: string;
  updatedAt: string | null;
}>[] {
  return [
    {
      accessorKey: "createdAt",
      enableSorting: true,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader title="Data de Solicitação" column={column} />
      ),
      cell: ({ row }) => (
        <div>{format(row.original.createdAt, "dd/MM/yyyy HH:mm")}</div>
      ),
    },
    {
      accessorKey: "requestedBy",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader title="Requisitado por" column={column} />
      ),
      cell: ({ row }) => <div>{row.original.requestedByName}</div>,
    },
    {
      accessorKey: "tags",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader title="Tags" column={column} />
      ),
      cell: ({ row }) => {
        const tags = row.original.tags;

        return (
          <div className="flex flex-wrap gap-2">
            {tags.map((tag) => (
              <Badge key={tag} variant="secondary">
                {friendlyTagName[tag as keyof typeof friendlyTagName]}
              </Badge>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "updatedAt",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader title="Último Download" column={column} />
      ),
      cell: ({ row }) => (
        <div>
          {row.original.updatedAt
            ? format(row.original.updatedAt, "dd/MM/yyyy HH:mm")
            : "Nenhum download realizado"}
        </div>
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <button
                onClick={() => onRequestDownload(row.original.id)}
                className="bg-secondary px-2 py-1 border rounded-md hover:bg-secondary/50 hover:text-black/50 transition-colors duration-200"
              >
                <DownloadIcon className="size-5" />
              </button>
            </TooltipTrigger>
            <TooltipContent side="top">
              <p>Baixar arquivo</p>
            </TooltipContent>
          </Tooltip>
        );
      },
    },
  ];
}
