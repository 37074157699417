import { z } from "zod";
import { CFOP } from "../../../../constants/cfop";

enum NFeTipoDocumento {
  NOTA_FISCAL_ENTRADA = "0",
  NOTA_FISCAL_SAIDA = "1",
}

enum NFeLocalDestino {
  OPERACAO_INTERNA = "1",
  OPERACAO_INTERESTADUAL = "2",
  OPERACAO_COM_EXTERIOR = "3",
}

enum NFeFinalidadeEmissao {
  NOTA_NORMAL = "1",
  NOTA_COMPLEMENTAR = "2",
  NOTA_DE_AJUSTE = "3",
  DEVOLUCAO_DE_MERCADORIA = "4",
}

enum NFeConsumidorFinal {
  CONSUMIDOR_NORMAL = "0",
  CONSUMIDOR_FINAL = "1",
}

enum NFePresencaComprador {
  NAO_SE_APLICA = "0",
  OPERACAO_PRESENCIAL = "1",
  OPERACAO_NAO_PRESENCIAL_INTERNET = "2",
  OPERACAO_NAO_PRESENCIAL_TELEATENDIMENTO = "3",
  NFC_E_COM_ENTREGA_EM_DOMICILIO = "4",
  OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO = "5",
  OPERACAO_NAO_PRESENCIAL_OUTROS = "9",
}

enum NFeNotaReferenciadaModeloEcf {
  CUPOM_FISCAL_EMITIDO_MAQUINA_REGISTRADORA = "2B",
  CUPOM_FISCAL_PDV = "2C",
  CUPOM_FISCAL_EMITIDO_ECF = "2D",
}

enum NFeRegimeTributario {
  SIMPLES_NACIONAL = "1",
  SIMPLES_NACIONAL_EXCESSO = "2",
  REGIME_NORMAL = "3",
  SIMPLES_NACIONAL_MEI = "4",
}

enum NFeIndicadorInscricaoEstadual {
  CONTRIBUINTE_ICMS = "1",
  CONTRIBUINTE_ISENTO = "2",
  NAO_CONTRIBUINTE = "9",
}

enum NFeItemVeiculoTipoOperacao {
  VENDA_CONCESSIONARIA = "1",
  FATURAMENTO_DIRETO = "2",
  VENDA_DIRETA = "3",
  OUTROS = "0",
}

enum NFeItemVeiculoTipoCombustivel {
  ALCOOL = "1",
  GASOLINA = "2",
  DIESEL = "3",
  GASOGENIO = "4",
  GAS_METANO = "5",
  ELETRICO_FONTE_INTERNA = "6",
  ELETRICO_FONTE_EXTERNA = "7",
  GASOLINA_GAS_NATURAL_COMBUSTIVEL = "8",
  ALCOOL_GAS_NATURAL_COMBUSTIVEL = "9",
  DIESEL_GAS_NATURAL_COMBUSTIVEL = "10",
  VIDE_CAMPO_OBSERVACAO = "11",
  ALCOOL_GAS_NATURAL_VEICULAR = "12",
  DIESEL_GAS_NATURAL_VEICULAR = "13",
  VIDE_CAMPO_OBSERVACAO_VEICULAR = "14",
  GAS_NATURAL_VEICULAR = "15",
  ALCOOL_GASOLINA = "16",
  ALCOOL_GASOLINA_GAS_NATURAL_VEICULAR = "17",
  GASOLINA_ELETRICO = "18",
}

enum NFeItemVeiculoTipoPintura {
  SOLIDA = "S",
  METALICA = "M",
  PEROLIZADA = "P",
}

enum NFeItemVeiculoTipo {
  CICLOMOTOR = "02",
  MOTONETA = "03",
  MOTOCICLETA = "04",
  TRICICLO = "05",
  AUTOMOVEL = "06",
  MICRO_ONIBUS = "07",
  ONIBUS = "08",
  REBOQUE = "10",
  SEMIRREBOQUE = "11",
  CAMIONETA = "13",
  CAMINHAO = "14",
  CARROCA = "15",
  CAMINHAO_TRATOR = "17",
  TRATOR_DE_RODAS = "18",
  TRATOR_DE_ESTEIRAS = "19",
  TRATOR_MISTO = "20",
  QUADRICICLO = "21",
  CHASSI_PLATAFORMA = "22",
  CAMINHONETE = "23",
  UTILITARIO = "25",
  MOTOR_CASA = "26",
}

enum NFeItemVeiculoEspecie {
  PASSAGEIRO = "1",
  CARGA = "2",
  MISTO = "3",
  CORRIDA = "4",
  TRACAO = "5",
  ESPECIAL = "6",
}

enum NFeItemVeiculoCodigoVin {
  REMARCADO = "R",
  NORMAL = "N",
}

enum NFeItemVeiculoCondicao {
  ACABADO = "1",
  INACABADO = "2",
  SEMI_ACABADO = "3",
}

enum NFeItemVeiculoCodigoCor {
  AMARELA = "1",
  AZUL = "2",
  BEGE = "3",
  BRANCO = "4",
  CINZA = "5",
  DOURADA = "6",
  GRENA = "7",
  LARANJA = "8",
  MARROM = "9",
  PRATA = "10",
  PRETA = "11",
  ROSA = "12",
  ROXA = "13",
  VERDE = "14",
  VERMELHA = "15",
  FANTASIA = "16",
}

enum NFeItemVeiculoRestricao {
  NAO_HA = "0",
  ALIENACAO_FIDUCIARIA = "1",
  ARRENDAMENTO_MERCANTIL = "2",
  RESERVA_DOMINIO = "3",
  PENHOR_DE_VEICULOS = "4",
  OUTRAS = "9",
}

enum NFeItemDocumentoImportacaoTransporte {
  MARITIMA = "1",
  FLUVIAL = "2",
  LACUSTRE = "3",
  AEREA = "4",
  POSTAL = "5",
  FERROVIARIA = "6",
  RODOVIARIA = "7",
  CONDUTO_REDE_TRANSMISSAO = "8",
  MEIOS_PROPRIOS = "9",
  ENTRADA_SAIDA_FICTA = "10",
  COURIER = "11",
  EM_MAOS = "12",
  POR_REBOQUE = "13",
}

enum NFeItemDocumentoImportacaoFormaIntermedio {
  CONTA_PROPRIA = "1",
  CONTA_E_ORDEM = "2",
  ENCOMENDA = "3",
}

enum NFeItemCombustivelOrigem {
  NACIONAL = "0",
  IMPORTADO = "1",
}

enum NFeItemIcmsOrigem {
  NACIONAL = "0",
  ESTRANGEIRA_IMPORTACAO_DIRETA = "1",
  ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO = "2",
  NACIONAL_MAIS_40_CONTEUDO_ESTRANGEIRO = "3",
  NACIONAL_PRODUZIDA_ATRAV_PROC_PROD_BASICO = "4",
  NACIONAL_MENOS_40_CONTEUDO_ESTRANGEIRO = "5",
  ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_PRODUTO_NACIONAL_SIMILAR = "6",
  ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO_SEM_PRODUTO_NACIONAL_SIMILAR = "7",
  NACIONAL_MERCADO_BEM_CONTEUDO_SUPERIOR_70 = "8",
}

export const NFeItemIcmsOrigemList = [
  { value: "0", label: "Nacional" },
  { value: "1", label: "Estrangeira (importação direta)" },
  { value: "2", label: "Estrangeira (adquirida no mercado interno)" },
  { value: "3", label: "Nacional com mais de 40% de conteúdo estrangeiro" },
  {
    value: "4",
    label: "Nacional produzida através de processos produtivos básicos",
  },
  { value: "5", label: "Nacional com menos de 40% de conteúdo estrangeiro" },
  {
    value: "6",
    label: "Estrangeira (importação direta) sem produto nacional similar",
  },
  {
    value: "7",
    label:
      "Estrangeira (adquirida no mercado interno) sem produto nacional similar",
  },
  {
    value: "8",
    label:
      "Nacional, mercadoria ou bem com conteúdo de importação superior a 70%",
  },
];

enum NFeItemIcmsSituacaoTributaria {
  TRIBUTADA_INTEGRALMENTE = "00",
  TRIBUTACAO_MONOFASICA_PROPRIA_SOBRE_COMBUSTIVEIS = "02",
  TRIBUTADA_COM_COBRANCA_DO_ICMS_POR_SUBSTITUICAO_TRIBUTARIA = "10",
  TRIBUTADA_COM_COBRANCA_DO_ICMS_POR_SUBSTITUICAO_TRIBUTARIA_PARTILHA = "10_partilha",
  TRIBUTACAO_MONOFASICA_PROPRIA_COM_RETENCAO_SOBRE_COMBUSTIVEIS = "15",
  TRIBUTADA_COM_REDUCAO_DE_BASE_DE_CALCULO = "20",
  ISENTA_OU_NAO_TRIBUTADA_COM_COBRANCA_DO_ICMS_POR_SUBSTITUICAO_TRIBUTARIA = "30",
  ISENTA = "40",
  NAO_TRIBUTADA = "41",
  NAO_TRIBUTADA_COM_ICMS_ST_PARA_UF_DESTINO = "41_st",
  SUSPENSAO = "50",
  DIFERIMENTO = "51",
  TRIBUTACAO_MONOFASICA_SOBRE_COMBUSTIVEIS_COM_RECOLHIMENTO_DIFERIDO = "53",
  COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA = "60",
  COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA_UF_DESTINO = "60_st",
  TRIBUTACAO_MONOFASICA_SOBRE_COMBUSTIVEIS_COBRADA_ANTERIORMENTE = "61",
  TRIBUTADA_COM_REDUCAO_DE_BASE_E_COM_COBRANCA_DO_ICMS_POR_SUBSTITUICAO_TRIBUTARIA = "70",
  OUTRAS_REGIME_NORMAL = "90",
  OUTRAS_REGIME_NORMAL_PARTILHA = "90_partilha",
  TRIBUTADA_PELO_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO = "101",
  TRIBUTADA_PELO_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO = "102",
  ISENCAO_DO_ICMS_NO_SIMPLES_NACIONAL_FAIXA_RECEITA_BRUTA = "103",
  TRIBUTADA_PELO_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO_COM_SUBSTITUICAO_TRIBUTARIA = "201",
  TRIBUTADA_PELO_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO_COM_SUBSTITUICAO_TRIBUTARIA = "202",
  ISENCAO_DO_ICMS_NO_SIMPLES_NACIONAL_FAIXA_RECEITA_BRUTA_COM_SUBSTITUICAO_TRIBUTARIA = "203",
  IMUNE = "300",
  NAO_TRIBUTADA_PELO_SIMPLES_NACIONAL = "400",
  ICMS_COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA = "500",
  OUTRAS_REGIME_SIMPLES_NACIONAL = "900",
}

export const NFeItemIcmsSituacaoTributariaList = [
  { value: "00", label: "Tributada integralmente" },
  { value: "02", label: "Tributação monofásica própria sobre combustíveis" },
  {
    value: "10",
    label: "Tributada com cobrança do ICMS por substituição tributária",
  },
  {
    value: "10_partilha",
    label:
      "Tributada com cobrança do ICMS por substituição tributária (partilha)",
  },
  {
    value: "15",
    label: "Tributação monofásica própria com retenção sobre combustíveis",
  },
  { value: "20", label: "Tributada com redução de base de cálculo" },
  {
    value: "30",
    label:
      "Isenta ou não tributada e com cobrança do ICMS por substituição tributária",
  },
  { value: "40", label: "Isenta" },
  { value: "41", label: "Não tributada" },
  { value: "41_st", label: "Não tributada com ICMS ST para a UF de destino" },
  { value: "50", label: "Suspensão" },
  {
    value: "51",
    label:
      "Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)",
  },
  {
    value: "53",
    label: "Tributação monofásica sobre combustíveis com recolhimento diferido",
  },
  { value: "60", label: "Cobrado anteriormente por substituição tributária" },
  {
    value: "60_st",
    label: "Cobrado anteriormente por substituição tributária na UF de destino",
  },
  {
    value: "61",
    label: "Tributação monofásica sobre combustíveis cobrada anteriormente",
  },
  {
    value: "70",
    label:
      "Tributada com redução de base de cálculo cobrança do ICMS por substituição tributária",
  },
  { value: "90", label: "Outras (regime Normal)" },
  { value: "90_partilha", label: "Outras (regime Normal) (partilha)" },
  {
    value: "101",
    label: "Tributada pelo Simples Nacional com permissão de crédito",
  },
  {
    value: "102",
    label: "Tributada pelo Simples Nacional sem permissão de crédito",
  },
  {
    value: "103",
    label: "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  },
  {
    value: "201",
    label:
      "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "202",
    label:
      "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "203",
    label:
      "Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
  },
  { value: "300", label: "Imune" },
  { value: "400", label: "Não tributada pelo Simples Nacional" },
  {
    value: "500",
    label:
      "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  },
  { value: "900", label: "Outras (regime Simples Nacional)" },
];

enum NFeItemIcmsModalidadeBaseCalculo {
  MARGEM_VALOR_AGREGADO = "0",
  PAUTA = "1",
  PRECO_TABELADO_MAXIMO = "2",
  VALOR_OPERACAO = "3",
}

enum NFeItemIcmsMotivoDesoneracao {
  TAXI = "1",
  PRODUTOR_AGROPECUARIO = "3",
  FROTISTA_LOCADORA = "4",
  DIPLOMATICO_CONSULAR = "5",
  UTILITARIOS_MOTOCICLETAS_AMAZONIA_OCIDENTAL = "6",
  SUFRAMA = "7",
  OUTROS = "9",
  DEFICIENTE_CONDUTOR = "10",
  DEFICIENTE_NAO_CONDUTOR = "11",
  ORGAO_FOMENTO_DESENVOLVIMENTO_AGROPECUARIO = "12",
  OLIMPIADAS_RIO_2016 = "16",
}

enum NFeItemIcmsDeducaoDesoneracao {
  NAO_DEDUZ_DO_VALOR_DO_ITEM_TOTAL_NFE = "0",
  DEDUZ_DO_VALOR_DO_ITEM_TOTAL_NFE = "1",
}

enum NFeItemIcmsMotivoReducao {
  TRANSPORTE_COLETIVO_DE_PASSAGEIROS = "1",
  OUTROS = "9",
}

enum NFeItemIcmsMotivoDesoneracaoSt {
  USO_AGROPECUARIO = "3",
  OUTROS = "9",
  ORGAO_FOMENTO_DESENVOLVIMENTO_AGROPECUARIO = "12",
}

enum NFeItemIcmsModalidadeBaseCalculoSt {
  PRECO_TABELADO_OU_MAXIMO_SUGERIDO = "0",
  LISTA_NEGATIVA_VALOR = "1",
  LISTA_POSITIVA_VALOR = "2",
  LISTA_NEUTRA_VALOR = "3",
  MARGEM_DE_VALOR_AGREGADO_PERCENTUAL = "4",
  PAUTA_VALOR = "5",
  VALOR_DA_OPERACAO_ICMS_70_90 = "6",
}

enum NFeItemIpiSituacaoTributaria {
  ENTRADA_COM_RECUPERACAO_DE_CREDITO = "00",
  ENTRADA_TRIBUTADA_COM_ALIQUOTA_ZERO = "01",
  ENTRADA_ISENTA = "02",
  ENTRADA_NAO_TRIBUTADA = "03",
  ENTRADA_IMUNE = "04",
  ENTRADA_COM_SUSPENSAO = "05",
  OUTRAS_ENTRADAS = "49",
  SAIDA_TRIBUTADA = "50",
  SAIDA_TRIBUTADA_COM_ALIQUOTA_ZERO = "51",
  SAIDA_ISENTA = "52",
  SAIDA_NAO_TRIBUTADA = "53",
  SAIDA_IMUNE = "54",
  SAIDA_COM_SUSPENSAO = "55",
  OUTRAS_SAIDAS = "99",
}

export const NFeItemIpiSituacaoTributariaList = [
  { value: "00", label: "Entrada com Recuperação de Crédito" },
  { value: "01", label: "Entrada Tributada com Alíquota Zero" },
  { value: "02", label: "Entrada Isenta" },
  { value: "03", label: "Entrada Não Tributada" },
  { value: "04", label: "Entrada Imune" },
  { value: "05", label: "Entrada Suspensa" },
  { value: "49", label: "Outras Entradas" },
  { value: "50", label: "Saída Tributada" },
  { value: "51", label: "Saída Tributada com Alíquota Zero" },
  { value: "52", label: "Saída Isenta" },
  { value: "53", label: "Saída Não Tributada" },
  { value: "54", label: "Saída Imune" },
  { value: "55", label: "Saída Suspensa" },
  { value: "99", label: "Outras Saídas" },
];

enum NFeItemIpiCodigoSeloControle {
  PRODUTO_NACIONAL = "9710-01",
  PRODUTO_NACIONAL_PARA_EXPORTACAO_TIPO_1 = "9710-10",
  PRODUTO_NACIONAL_PARA_EXPORTACAO_TIPO_2 = "9710-11",
  PRODUTO_NACIONAL_PARA_EXPORTACAO_TIPO_3 = "9710-12",
  PRODUTO_ESTRANGEIRO = "8610-09",
}

enum NFeItemIssqnIndicadorExigibilidade {
  EXIGIVEL = "1",
  NAO_INCIDENCIA = "2",
  ISENCAO = "3",
  EXPORTACAO = "4",
  EXIGIBILIDADE_SUSPENSA_DECISAO_JUDICIAL = "6",
  EXIGIBILIDADE_SUSPENSA_PROCESSO_ADMINISTRATIVO = "7",
  IMUNIDADE = "5",
}

enum NFeItemPisCofinsSituacaoTributaria {
  OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_NORMAL = "01",
  OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_DIFERENCIADA = "02",
  OPERACAO_TRIBUTAVEL_BASE_CALCULO_QUANTIDADE_VENDIDA_X_ALIQUOTA_UNIDADE = "03",
  OPERACAO_TRIBUTAVEL_TRIBUTACAO_MONOFASICA_ALIQUOTA_ZERO = "04",
  OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA = "05",
  OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO = "06",
  OPERACAO_ISENTA_CONTRIBUICAO = "07",
  OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO = "08",
  OPERACAO_COM_SUSPENSAO_CONTRIBUICAO = "09",
  OUTRAS_OPERACOES_SAIDA = "49",
  OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_RECEITA_TRIBUTADA_INTERNO = "50",
  OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_RECEITA_NAO_TRIBUTADA_INTERNO = "51",
  OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_RECEITA_EXPORTACAO = "52",
  OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_INTERNO = "53",
  OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_INTERNO_EXPORTACAO = "54",
  OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_INTERNO_EXPORTACAO = "55",
  OPERACAO_COM_DIREITO_A_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_INTERNO_EXPORTACAO = "56",
  CREDITO_PRESUMIDO_OPERACAO_ADQUISICAO_VINCULADA_RECEITA_TRIBUTADA_INTERNO = "60",
  CREDITO_PRESUMIDO_OPERACAO_ADQUISICAO_VINCULADA_RECEITA_NAO_TRIBUTADA_INTERNO = "61",
  CREDITO_PRESUMIDO_OPERACAO_ADQUISICAO_VINCULADA_RECEITA_EXPORTACAO = "62",
  CREDITO_PRESUMIDO_OPERACAO_ADQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_INTERNO = "63",
  CREDITO_PRESUMIDO_OPERACAO_ADQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_INTERNO_EXPORTACAO = "64",
  CREDITO_PRESUMIDO_OPERACAO_ADQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_INTERNO_EXPORTACAO = "65",
  CREDITO_PRESUMIDO_OPERACAO_ADQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_NAO_TRIBUTADAS_INTERNO_EXPORTACAO = "66",
  CREDITO_PRESUMIDO_OUTRAS_OPERACOES = "67",
  OPERACAO_ADQUISICAO_SEM_DIREITO_A_CREDITO = "70",
  OPERACAO_ADQUISICAO_COM_ISENCAO = "71",
  OPERACAO_ADQUISICAO_COM_SUSPENSAO = "72",
  OPERACAO_ADQUISICAO_ALIQUOTA_ZERO = "73",
  OPERACAO_ADQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO = "74",
  OPERACAO_ADQUISICAO_POR_SUBSTITUICAO_TRIBUTARIA = "75",
  OUTRAS_OPERACOES_ENTRADA = "98",
  OUTRAS_OPERACOES = "99",
}

export const NFeItemPisCofinsSituacaoTributariaList = [
  {
    value: "01",
    label:
      "Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo)",
  },
  {
    value: "02",
    label:
      "Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)",
  },
  {
    value: "03",
    label:
      "Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto",
  },
  {
    value: "04",
    label: "Operação tributável: tributação monofásica (alíquota zero)",
  },
  { value: "05", label: "Operação tributável: substituição tributária" },
  { value: "06", label: "Operação tributável: alíquota zero" },
  { value: "07", label: "Operação isenta da contribuição" },
  { value: "08", label: "Operação sem incidência da contribuição" },
  { value: "09", label: "Operação com suspensão da contribuição" },
  { value: "49", label: "Outras operações de saída" },
  {
    value: "50",
    label:
      "Operação direito crédito vinculada exclusivamente a receita tributada no mercado interno",
  },
  {
    value: "51",
    label:
      "Operação direito crédito vinculada exclusivamente a receita não tributada no mercado interno",
  },
  {
    value: "52",
    label:
      "Operação direito crédito vinculada exclusivamente a receita exportada",
  },
  {
    value: "53",
    label:
      "Operação direito crédito vinculada a receitas tributadas não-tributadas no mercado interno",
  },
  {
    value: "54",
    label:
      "Operação direito crédito vinculada a receitas tributadas no mercado interno e exportadas",
  },
  {
    value: "55",
    label:
      "Operação direito crédito vinculada a receitas não-tributadas no mercado interno e exportadas",
  },
  {
    value: "56",
    label:
      "Operação direito crédito vinculada a receitas tributadas não-tributadas no mercado interno e exportadas",
  },
  {
    value: "60",
    label:
      "Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno",
  },
  {
    value: "61",
    label:
      "Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno",
  },
  {
    value: "62",
    label:
      "Crédito presumido: operação de aquisição vinculada exclusivamente a receita exportada",
  },
  {
    value: "63",
    label:
      "Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno",
  },
  {
    value: "64",
    label:
      "Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e exportadas",
  },
  {
    value: "65",
    label:
      "Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e exportadas",
  },
  {
    value: "66",
    label:
      "Crédito presumido: operação de aquisição vinculada a receitas tributadas não-tributadas no mercado interno e exportadas",
  },
  { value: "67", label: "Crédito presumido: outras operações" },
  { value: "70", label: "Operação de aquisição sem direito crédito" },
  { value: "71", label: "Operação de aquisição com isenção" },
  { value: "72", label: "Operação de aquisição com suspensão" },
  { value: "73", label: "Operação de aquisição com alíquota zero" },
  {
    value: "74",
    label: "Operação de aquisição sem incidência da contribuição",
  },
  { value: "75", label: "Operação de aquisição com substituição tributária" },
  { value: "98", label: "Outras operações de entrada" },
  { value: "99", label: "Outras operações" },
];

enum NFeIndicadorPagamento {
  PAGAMENTO_A_VISTA = "0",
  PAGAMENTO_A_PRAZO = "1",
}

enum NFeFormaPagamento {
  DINHEIRO = "01",
  CHEQUE = "02",
  CARTAO_CREDITO = "03",
  CARTAO_DEBITO = "04",
  CARTAO_LOJA_PRIVATE_LABEL = "05",
  VALE_ALIMENTACAO = "10",
  VALE_REFEICAO = "11",
  VALE_PRESENTE = "12",
  VALE_COMBUSTIVEL = "13",
  DUPLICATA_MERCANTIL = "14",
  BOLETO_BANCARIO = "15",
  DEPOSITO_BANCARIO = "16",
  PAGAMENTO_PIX_DINAMICO = "17",
  TRANSFERENCIA_BANCARIA_CARTEIRA_DIGITAL = "18",
  PROGRAMA_FIDELIDADE_CASHBACK_CREDITO_VIRTUAL = "19",
  PAGAMENTO_PIX_ESTATICO = "20",
  CREDITO_EM_LOJA = "21",
  PAGAMENTO_NAO_INFORMADO_FALHA_HARDWARE = "22",
  SEM_PAGAMENTO = "90",
  OUTROS = "99",
}

enum NFeTipoIntegracao {
  INTEGRADO = "1", // Pagamento integrado com o sistema de automação da empresa
  NAO_INTEGRADO = "2", // Pagamento não integrado com o sistema de automação da empresa
}

enum NFeBandeiraOperadora {
  VISA = "01",
  MASTERCARD = "02",
  AMERICAN_EXPRESS = "03",
  SOROCRED = "04",
  DINERS_CLUB = "05",
  ELO = "06",
  HIPERCARD = "07",
  AURA = "08",
  CABAL = "09",
  ALELO = "10",
  BANES_CARD = "11",
  CALCARD = "12",
  CREDZ = "13",
  DISCOVER = "14",
  GOODCARD = "15",
  GREENCARD = "16",
  HIPER = "17",
  JCB = "18",
  MAIS = "19",
  MAXVAN = "20",
  POLICARD = "21",
  REDE_COMPRAS = "22",
  SODEXO = "23",
  VALECARD = "24",
  VEROCHEQUE = "25",
  VR = "26",
  TICKET = "27",
  OUTROS = "99",
}

enum NFeIndicadorOrigemProcesso {
  SEFAZ = "0",
  JUSTICA_FEDERAL = "1",
  JUSTICA_ESTADUAL = "2",
  SECEX_RFB = "3",
  OUTROS = "9",
}

enum NFeTipoAtoCooncessorio {
  TERMO_DE_ACORDO = "08",
  REGIME_ESPECIAL = "10",
  AUTORIZACAO_ESPECIFICA = "12",
}

enum NFeRegimeEspecialTributacao {
  MICROEMPRESA_MUNICIPAL = "1",
  ESTIMATIVA = "2",
  SOCIEDADE_DE_PROFISSIONAIS = "3",
  COOPERATIVA = "4",
  MICROEMPRESARIO_INDIVIDUAL_MEI = "5",
  MICROEMPRESARIO_E_EMPRESA_PEQUENO_PORTE_ME_EPP = "6",
}

enum NFeModalidadeFrete {
  POR_CONTA_DO_REMETENTE = "0",
  POR_CONTA_DO_DESTINATARIO = "1",
  POR_CONTA_DE_TERCEIROS = "2",
  TRANSPORTE_PROPRIO_POR_CONTA_DO_REMETENTE = "3",
  TRANSPORTE_PROPRIO_POR_CONTA_DO_DESTINATARIO = "4",
  SEM_FRETE = "9",
}

const focusNfeNotasReferenciadas = z.object({
  chave_nfe: z.string().max(44).optional(),
  uf: z.string().max(2).optional(),
  mes: z.string().max(4).optional(),
  cnpj: z.string().max(14).optional(),
  modelo: z.string().max(2).optional(),
  serie: z.string().max(3).optional(),
  numero: z.string().max(9).optional(),
  uf_nf_produtor: z.string().max(2).optional(),
  mes_nf_produtor: z.string().max(4).optional(),
  cnpj_nf_produtor: z.string().max(14).optional(),
  cpf_nf_produtor: z.string().max(11).optional(),
  inscricao_estadual_nf_produtor: z.string().max(14).optional(),
  modelo_nf_produtor: z.string().max(2).optional(),
  serie_nf_produtor: z.string().max(3).optional(),
  numero_nf_produtor: z.string().max(9).optional(),
  chave_cte: z.string().max(44).optional(),
  modelo_ecf: z.nativeEnum(NFeNotaReferenciadaModeloEcf).optional(),
  numero_ecf: z.string().max(3).optional(),
  coo: z.string().max(6).optional(),
});

const focusNfePessoasAutorizadas = z.object({
  cpf: z.string().max(11).optional(),
  nome: z.string().max(60).optional(),
});

const focusNfeItemNve = z.object({
  nve: z
    .string()
    .max(6)
    .refine(
      (value) => value.match(new RegExp("^[A-Z]{2}\\d{4}$")),
      "NVE_INVALIDO"
    ),
});

const focusNfeItemInfoCreditoPresumido = z.object({
  codigo_beneficio_fiscal: z.string().min(8).max(10).optional(),
  percentual: z.string().max(7).optional(),
  valor: z.string().max(15).optional(),
});

const focusNfeItemDocumentoImportacaoAdicao = z.object({
  numero: z.string().max(3).optional(),
  numero_sequencial_item: z.string().max(5).optional(),
  codigo_fabricante_item: z.string().max(5).optional(),
  codigo_fabricante_estrangeiro: z.string().max(60).optional(),
  valor_desconto: z.string().max(15).optional(),
  numero_drawback: z.string().max(20).optional(),
});

const focusNfeItemDocumentoImportacao = z.object({
  numero: z.string().max(15).optional(),
  data_registro: z.string().optional(),
  local_desembaraco_aduaneiro: z.string().max(60).optional(),
  uf_desembaraco_aduaneiro: z.string().max(2).optional(),
  data_desembaraco_aduaneiro: z.string().optional(),
  via_transporte: z.nativeEnum(NFeItemDocumentoImportacaoTransporte),
  valor_afrmm: z.string().max(15).optional(),
  forma_intermedio: z.nativeEnum(NFeItemDocumentoImportacaoFormaIntermedio),
  cnpj: z.string().max(14).optional(),
  uf_terceiro: z.string().max(2).optional(),
  codigo_exportador: z.string().max(60).optional(),
  adicoes: z.array(focusNfeItemDocumentoImportacaoAdicao).max(999).min(1),
});

const focusNfeItemDetalhesExportacao = z.object({
  numero_drawback: z.string().max(20).optional(),
  numero_referencia: z.string().max(12).optional(),
  chave_nfe: z.string().max(44).optional(),
  quantidade_exportacao: z.string().max(15).optional(),
});

const focusNfeItemRastro = z.object({
  numero_lote: z.string().max(20).optional(),
  quantidade_lote: z.string().max(11).optional(),
  data_fabricacao: z.string().optional(),
  data_validade: z.string().optional(),
  codigo_agregacao: z.string().max(20).optional(),
});

const focusNfeItemCombustivelOrigem = z.object({
  indicador_importacao: z.nativeEnum(NFeItemCombustivelOrigem).optional(),
  uf_origem: z.string().max(2).optional(),
  percentual_originario_uf: z.string().max(8).optional(),
});

const focusNfeItemObservacaoContribuinte = z.object({
  campo: z.string().max(20).optional(),
  texto: z.string().max(60).optional(),
});

const focusNfeReboque = z.object({
  veiculo_placa: z.string().max(7),
  veiculo_uf: z.string().max(2),
  veiculo_rntc: z.string().max(20),
});

const focusNfeLacre = z.object({
  numero: z.string().max(60).optional(),
});

const focusNfeVolume = z.object({
  quantidade: z.string().max(15).optional(),
  especie: z.string().max(60).optional(),
  marca: z.string().max(60).optional(),
  numero: z.string().max(60).optional(),
  peso_liquido: z.string().max(15).optional(),
  peso_bruto: z.string().max(15).optional(),
  lacres: z.array(focusNfeLacre).max(10).optional(),
});

const focusNfeFormaPagamento = z.object({
  indicador_pagamento: z.nativeEnum(NFeIndicadorPagamento).optional(),
  forma_pagamento: z.nativeEnum(NFeFormaPagamento).optional(),
  descricao_pagamento: z.string().max(60).optional(),
  valor_pagamento: z.string().max(15).optional(),
  cnpj_transacional: z.string().max(14).optional(),
  uf_transacional: z.string().max(2).optional(),
  tipo_integracao: z.nativeEnum(NFeTipoIntegracao).optional(),
  cnpj_credenciadora: z.string().max(14).optional(),
  bandeira_operadora: z.nativeEnum(NFeBandeiraOperadora).optional(),
  numero_autorizacao: z.string().max(128).optional(),
  cnpj_beneficiario: z.string().max(14).optional(),
  id_terminal_pagamento: z.string().max(40).optional(),
});

const focusNfeDuplicata = z.object({
  numero: z.string().max(60).optional(),
  data_vencimento: z.string().optional(),
  valor: z.string().max(15).optional(),
});

const focusNfeObservacaoContribuinte = z.object({
  campo: z.string().max(20).optional(),
  texto: z.string().max(60).optional(),
});

const focusNfeObservacoesFisco = z.object({
  campo: z.string().max(20).optional(),
  texto: z.string().max(60).optional(),
});

const focusNfeProcessosReferenciados = z.object({
  identificador_processo: z.string().max(60).optional(),
  indicador_origem_processo: z
    .nativeEnum(NFeIndicadorOrigemProcesso)
    .optional(),
  tipo_ato_concessorio: z.nativeEnum(NFeTipoAtoCooncessorio).optional(),
});

const focusNfeFreight = z.object({
  modalidade_frete: z.nativeEnum(NFeModalidadeFrete),
  cnpj_transportador: z.string().max(14).optional(),
  cpf_transportador: z.string().max(11).optional(),
  nome_transportador: z.string().max(60).optional(),
  inscricao_estadual_transportador: z.string().max(14).optional(),
  endereco_transportador: z.string().max(60).optional(),
  municipio_transportador: z.string().max(60).optional(),
  uf_transportador: z.string().max(2).optional(),
  transporte_icms_servico: z.string().max(15).optional(),
  transporte_icms_base_calculo: z.string().max(16).optional(),
  transporte_icms_cfop: z.string().max(4).optional(), //Valores aceitos: 5351, 5352, 5353, 5354, 5355, 5356, 5357, 5359, 5360, 5931, 5932, 6351, 6352, 6353, 6354, 6355, 6356, 6357, 6359, 6360, 6931, 6932, 7358
  transporte_icms_codigo_municipio: z.string().max(7).optional(),
  veiculo_placa: z.string().max(2).optional(),
  veiculo_uf: z.string().max(2).optional(),
  veiculo_rntc: z.string().max(6).optional(),
  reboques: z.array(focusNfeReboque).max(5).optional(),
  veiculo_identificacao_vagao: z.string().max(20).optional(),
  veiculo_identificacao_balsa: z.string().max(20).optional(),
  volumes: z.array(focusNfeVolume).max(5000).optional(),
});

export type FocusNFe = {
  freight: z.infer<typeof focusNfeFreight>;
  item: z.infer<typeof focusNfeItem>;
};

const focusNfeItem = z.object({
  numero_item: z.string().max(3),
  codigo_produto: z.string().max(60).nullish(),
  codigo_barras_comercial: z.string().max(14).optional(),
  codigo_barras_proprio_comercial: z.string().max(30).optional(),
  descricao: z.string().max(120),
  codigo_ncm: z.string().min(2).max(8),
  nves: z.array(focusNfeItemNve).max(8).optional(),
  cest: z.string().max(7).optional(),
  escala_relevante: z.boolean().optional(),
  cnpj_fabricante: z.string().max(14).optional(), // Obrigatório para produto com escala relevante
  codigo_beneficio_fiscal: z.string().optional(),
  infos_credito_presumido: z.array(focusNfeItemInfoCreditoPresumido).max(4),
  codigo_ex_tipi: z.string().max(3).optional(),
  cfop: z
    .string()
    .max(4)
    .refine((value) => CFOP.valid(value), "CFOP_INVALIDO"),
  unidade_comercial: z.string().max(6),
  quantidade_comercial: z.string().max(15),
  valor_unitario_comercial: z.string().max(21),
  valor_bruto: z.string().max(15).optional(),
  codigo_barras_tributavel: z.string().max(14).optional(),
  codigo_barras_proprio_tributavel: z.string().max(30).optional(),
  unidade_tributavel: z.string().max(6).optional(),
  quantidade_tributavel: z.string().max(15).optional(),
  valor_unitario_tributavel: z.string().max(21).optional(),
  valor_frete: z.string().max(15).optional(),
  valor_seguro: z.string().max(15).optional(),
  valor_desconto: z.string().max(15).optional(),
  valor_outras_despesas: z.string().max(15).optional(),
  inclui_no_total: z.coerce.number().min(0).max(1).optional().default(1),
  documentos_importacao: z
    .array(focusNfeItemDocumentoImportacao)
    .max(100)
    .nullish(),
  detalhes_exportacao: z
    .array(focusNfeItemDetalhesExportacao)
    .max(500)
    .nullish(),
  pedido_compra: z.string().max(15).optional(),
  numero_item_pedido_compra: z.string().max(6).optional(),
  numero_fci: z.string().max(36).optional(),
  rastros: z.array(focusNfeItemRastro).max(500).nullish(),
  veiculo_tipo_operacao: z.nativeEnum(NFeItemVeiculoTipoOperacao).optional(),
  veiculo_chassi: z.string().max(17).optional(),
  veiculo_codigo_cor: z.string().max(4).optional(),
  veiculo_descricao_cor: z.string().max(40).optional(),
  veiculo_potencia_motor: z.string().max(4).optional(),
  veiculo_cm3: z.string().max(4).optional(),
  veiculo_peso_liquido: z.string().max(9).optional(),
  veiculo_peso_bruto: z.string().max(9).optional(),
  veiculo_serie: z.string().max(9).optional(),
  veiculo_tipo_combustivel: z
    .nativeEnum(NFeItemVeiculoTipoCombustivel)
    .optional(),
  veiculo_numero_motor: z.string().max(21).optional(),
  veiculo_cmt: z.string().max(9).optional(),
  veiculo_distancia_eixos: z.string().max(4).optional(),
  veiculo_ano_modelo: z.string().max(4).optional(),
  veiculo_ano_fabricacao: z.string().max(4).optional(),
  veiculo_tipo_pintura: z.nativeEnum(NFeItemVeiculoTipoPintura).optional(),
  veiculo_tipo: z.nativeEnum(NFeItemVeiculoTipo).optional(),
  veiculo_especie: z.nativeEnum(NFeItemVeiculoEspecie).optional(),
  veiculo_codigo_vin: z.nativeEnum(NFeItemVeiculoCodigoVin).optional(),
  veiculo_condicao: z.nativeEnum(NFeItemVeiculoCondicao).optional(),
  veiculo_codigo_marca_modelo: z.string().max(6).optional(),
  veiculo_codigo_cor_denatran: z.nativeEnum(NFeItemVeiculoCodigoCor).optional(),
  veiculo_lotacao: z.string().max(3).optional(),
  veiculo_restricao: z.nativeEnum(NFeItemVeiculoRestricao).optional(),
  medicamento_codigo_anvisa: z.string().max(13).optional(),
  medicamento_motivo_isencao: z.string().max(255).optional(),
  medicamento_preco_maximo_consumidor: z.string().max(15).optional(),
  combustivel_codigo_anp: z.string().max(9).optional(),
  combustivel_descricao_anp: z.string().max(95).optional(),
  combustivel_percentual_anp: z.string().max(6).optional(),
  combustivel_percentual_glp: z.string().max(6).optional(),
  combustivel_percentual_gas_natural_nacional: z.string().max(6).optional(),
  combustivel_percentual_gas_natural_importado: z.string().max(6).optional(),
  combustivel_valor_partida: z.string().max(15).optional(),
  combustivel_registro_codif: z.string().max(21).optional(),
  combustivel_quantidade_temperatura_ambiente: z.string().max(15).optional(),
  combustivel_sigla_uf: z.string().max(2).optional(),
  combustivel_cide_base_calculo: z.string().max(16).optional(),
  combustivel_cide_aliquota: z.string().max(16).optional(),
  combustivel_cide_valor: z.string().max(16).optional(),
  combustivel_numero_bico: z.string().max(3).optional(),
  combustivel_numero_bomba: z.string().max(3).optional(),
  combustivel_numero_tanque: z.string().max(3).optional(),
  combustivel_valor_encerrante_inicio: z.string().max(15).optional(),
  combustivel_valor_encerrante_final: z.string().max(15).optional(),
  combustivel_percentual_biodiesel: z.string().max(8).optional(),
  origens_combustivel: z.array(focusNfeItemCombustivelOrigem).max(30).nullish(),
  valor_total_tributos: z.string().max(16).optional(),

  icms_origem: z.nativeEnum(NFeItemIcmsOrigem),
  icms_situacao_tributaria: z.nativeEnum(NFeItemIcmsSituacaoTributaria),
  icms_modalidade_base_calculo: z.string().optional(),

  icms_base_calculo: z.string().max(16).optional(),
  icms_base_calculo_mono: z.string().max(16).optional(),
  icms_base_calculo_retido_st: z.string().max(16).optional(),
  icms_situacao_tributaria_mono_retencao: z.string().max(16).optional(),
  icms_reducao_base_calculo: z.string().max(8).optional(),
  icms_percentual_reducao: z.string().max(8).optional(),
  codigo_beneficio_fiscal_reducao_base_calculo: z.string().max(10).optional(),
  icms_aliquota: z.string().max(8).optional(),
  icms_aliquota_final: z.string().max(8).optional(),
  icms_aliquota_retido: z.string().max(8).optional(),
  icms_valor_substituto: z.string().max(16).optional(),
  icms_valor: z.string().max(16).optional(),
  icms_valor_mono: z.string().max(16).optional(),
  icms_valor_mono_retencao: z.string().max(16).optional(),
  icms_valor_retido_st: z.string().max(16).optional(),
  icms_valor_desonerado: z.string().max(16).optional(),
  icms_valor_operacao: z.string().max(16).optional(),
  icms_valor_mono_operacao: z.string().max(16).optional(),
  icms_percentual_diferimento: z.string().max(8).optional(),
  icms_motivo_desoneracao: z.string().optional(),
  icms_deducao_desoneracao: z.string().optional(),
  icms_motivo_reducao: z.string().optional(),
  icms_valor_icms_st_desonerado: z.string().max(16).optional(),
  icms_motivo_desoneracao_icms_st: z.string().optional(),
  icms_modalidade_base_calculo_st: z.string().optional(),
  icms_margem_valor_adicionado_st: z.string().max(16).optional(),
  icms_reducao_base_calculo_st: z.string().max(8).optional(),
  icms_base_calculo_st: z.string().max(16).optional(),
  icms_aliquota_st: z.string().max(8).optional(),
  icms_valor_mono_retido: z.string().max(16).optional(),
  icms_valor_st: z.string().max(16).optional(),
  icms_base_calculo_operacao_propria: z.string().max(16).optional(),
  icms_fcp_percentual_diferimento: z.string().max(8).optional(),
  icms_uf_st: z.string().max(2).optional(),
  icms_bas_calculo_mono_retido: z.string().max(16).optional(),
  icms_base_calculo_retido_remetente: z.string().max(16).optional(),
  icms_valor_retido_remetente: z.string().max(16).optional(),
  icms_base_calculo_destino: z.string().max(16).optional(),
  icms_aliquota_credito_simples: z.string().max(8).optional(),
  icms_valor_credito_simples: z.string().max(16).optional(),
  icms_reducao_base_calculo_efetiva: z.string().max(8).optional(),
  icms_base_calculo_efetiva: z.string().max(16).optional(),
  icms_base_calculo_mono_retido: z.string().max(16).optional(),
  icms_base_calculo_mono_retencao: z.string().max(16).optional(),
  icms_valor_mono_diferido: z.string().max(16).optional(),
  icms_valor_efetivo: z.string().max(16).optional(),
  icms_valor_diferido: z.string().max(16).optional(),
  fcp_percentual: z.string().max(8).optional(),
  fcp_base_calculo: z.string().max(16).optional(),
  fcp_valor: z.string().max(16).optional(),
  fcp_percentual_st: z.string().max(8).optional(),
  fcp_base_calculo_st: z.string().max(16).optional(),
  fcp_valor_st: z.string().max(16).optional(),
  fcp_percentual_retido_st: z.string().max(8).optional(),
  fcp_base_calculo_retido_st: z.string().max(16).optional(),
  fcp_valor_retido_st: z.string().max(16).optional(),
  icms_fcp_percentual_deferimento: z.string().max(8).optional(),
  icms_fcp_valor_diferido: z.string().max(16).optional(),
  icms_fcp_valor_efetivo: z.string().max(16).optional(),
  icms_aliquota_efetiva: z.string().max(8).optional(),

  // IPI
  ipi_situacao_tributaria: z.string().optional(),
  ipi_base_calculo: z.string().max(16).optional(),
  ipi_aliquota: z.string().max(8).optional(),
  ipi_quantidade_total: z.string().max(16).optional(),
  ipi_valor_por_unidade_tributavel: z.string().max(16).optional(),
  ipi_valor: z.string().max(16).optional(),
  ipi_cnpj_produtor: z.string().max(14).optional(),
  ipi_codigo_selo_controle: z.string().optional(),
  ipi_quantidade_selo_controle: z.string().max(13).optional(),
  ipi_codigo_enquadramento_legal: z.string().max(3).optional(),

  ii_base_calculo: z.string().max(16).optional(),
  ii_despesas_aduaneiras: z.string().max(16).optional(),
  ii_valor: z.string().max(16).optional(),
  ii_valor_iof: z.string().max(16).optional(),
  issqn_base_calculo: z.string().max(16).optional(),
  issqn_aliquota: z.string().max(8).optional(),
  issqn_valor: z.string().max(16).optional(),
  issqn_codigo_municipio: z.string().max(7).optional(),
  issqn_item_list_servico: z.string().max(3).optional(),
  issqn_valor_deducao: z.string().max(16).optional(),
  issqn_valor_outras_retencoes: z.string().max(16).optional(),
  issqn_valor_desconto_incondicionado: z.string().max(16).optional(),
  issqn_valor_desconto_condicionado: z.string().max(16).optional(),
  issqn_valor_retencao: z.string().max(16).optional(),
  issqn_indicador_exigibilidade: z
    .nativeEnum(NFeItemIssqnIndicadorExigibilidade)
    .nullish(),
  issqn_codigo_servico: z.string().max(6).optional(),
  issqn_codigo_municipio_incidencia: z.string().max(7).optional(),
  issqn_codigo_pais: z.string().max(3).optional(),
  issqn_numero_processo: z.string().max(30).optional(),
  issqn_indicador_incentivo: z.string().max(1).optional(),
  pis_situacao_tributaria: z.nativeEnum(NFeItemPisCofinsSituacaoTributaria),
  pis_base_calculo: z.string().max(16).optional(),
  pis_aliquota_porcentual: z.string().max(8).optional(),
  pis_aliquota_vendida: z.string().max(8).optional(),
  pis_quantidade_vendida: z.string().max(16).optional(),
  pis_aliquota_valor: z.string().max(16).optional(),
  pis_valor: z.string().max(16).optional(),
  pis_base_calculo_st: z.string().max(16).optional(),
  pis_aliquota_porcentual_st: z.string().max(8).optional(),
  pis_quantidade_vendida_st: z.string().max(16).optional(),
  pis_aliquota_valor_st: z.string().max(16).optional(),
  pis_valor_st: z.string().max(16).optional(),
  inclui_pis_st_no_total: z.boolean().optional(),
  cofins_situacao_tributaria: z.nativeEnum(NFeItemPisCofinsSituacaoTributaria),
  cofins_base_calculo: z.string().max(16).optional(),
  cofins_aliquota_porcentual: z.string().max(8).optional(),
  cofins_quantidade_vendida: z.string().max(16).optional(),
  cofins_aliquota_valor: z.string().max(16).optional(),
  cofins_valor: z.string().max(16).optional(),
  cofins_base_calculo_st: z.string().max(16).optional(),
  cofins_aliquota_porcentual_st: z.string().max(8).optional(),
  cofins_quantidade_vendida_st: z.string().max(16).optional(),
  cofins_aliquota_valor_st: z.string().max(16).optional(),
  cofins_valor_st: z.string().max(16).optional(),
  inclui_cofins_st_no_total: z.boolean().optional(),
  icms_base_calculo_uf_destino: z.string().optional(),
  fcp_base_calculo_uf_destino: z.string().optional(),
  fcp_percentual_uf_destino: z.string().optional(),
  icms_aliquota_interna_uf_destino: z.string().optional(),
  icms_aliquota_interestadual: z.string().optional(),
  icms_percentual_partilha: z.string().optional(),
  icms_valor_destino: z.string().optional(),
  fcp_valor_uf_destino: z.string().optional(),
  icms_valor_uf_destino: z.string().optional(),
  icms_valor_uf_remetente: z.string().optional(),
  percentual_devolvido: z.string().max(6).optional(),
  valor_ipi_devolvido: z.string().max(16).optional(),
  informacoes_adicionais_item: z.string().max(500).optional(),
  observacoes_contribuinte: z
    .array(focusNfeItemObservacaoContribuinte)
    .max(10)
    .optional(),
});

export const focusProductInvoice = z.object({
  natureza_operacao: z.string().min(1).max(60),
  serie: z.string().max(3).optional(),
  numero: z.string().max(9).optional(),
  data_emissao: z.string().min(1),
  data_entrada_saida: z.string().optional(),
  tipo_documento: z.nativeEnum(NFeTipoDocumento),
  local_destino: z.nativeEnum(NFeLocalDestino),
  municipio: z.string().max(7).optional(), // Código do municipio de ocorrencia (utilizado da empresa, caso omitido)
  finalidade_emissao: z.nativeEnum(NFeFinalidadeEmissao),
  consumidor_final: z.nativeEnum(NFeConsumidorFinal),
  presenca_comprador: z.nativeEnum(NFePresencaComprador),
  indicador_intermediario: z.boolean().optional(),
  notas_referenciadas: z.array(focusNfeNotasReferenciadas).max(500).optional(),

  // Campo precisa ser validado de acordo com o tipo de documento
  cnpj_emitente: z.string().max(14).optional(),
  cpf_emitente: z.string().max(11).optional(),
  nome_emitente: z.string().max(60).optional(),
  nome_fantasia_emitente: z.string().max(60).optional(),
  logradouro_emitente: z.string().max(60).optional(),
  numero_emitente: z.string().max(60).optional(),
  complemento_emitente: z.string().max(60).optional(),
  bairro_emitente: z.string().max(60).optional(),
  codigo_municipio_emitente: z.string().max(7).optional(),
  municipio_emitente: z.string().max(60).optional(),
  uf_emitente: z.string().max(2).optional(),
  cep_emitente: z.string().max(8).optional(),
  telefone_emitente: z.string().max(14).optional(),
  inscricao_estadual_emitente: z.string().max(14),
  inscricao_estadual_st_emitente: z.string().max(14).optional(),
  inscricao_municipal_emitente: z.string().max(15).optional(),
  cnae_fiscal_emitente: z.string().max(7).optional(),
  regime_tributario_emitente: z.nativeEnum(NFeRegimeTributario).optional(),

  // Campo precisa ser validado de acordo com o tipo de documento
  cnpj_destinatario: z.string().max(14).optional(),
  cpf_destinatario: z.string().max(11).optional(),
  id_estrangeiro_destinatario: z.string().max(50).optional(),
  nome_destinatario: z.string().max(60),
  logradouro_destinatario: z.string().max(60),
  numero_destinatario: z.string().max(60),
  complemento_destinatario: z.string().max(60).optional(),
  bairro_destinatario: z.string().max(60),
  codigo_municipio_destinatario: z.string().max(7).optional(),
  municipio_destinatario: z.string().max(60),
  uf_destinatario: z.string().max(2), // Deve ser omitido em operações com o destinatário fora do país
  cep_destinatario: z.string().max(8).optional(),
  codigo_pais_destinatario: z.string().max(4).optional(),
  pais_destinatario: z.string().max(60).optional(),
  telefone_destinatario: z.string().max(14).optional(),
  indicador_inscricao_estadual_destinatario: z
    .nativeEnum(NFeIndicadorInscricaoEstadual)
    .optional(),
  inscricao_estadual_destinatario: z.string().max(14).optional(), // Campo obrigatório se indicador_inscricao_estadual_destinatario for 1
  inscricao_suframa_destinatario: z.string().max(14).optional(),
  inscricao_municipal_destinatario: z.string().max(15).optional(), // Apenas se for uma NFe conjugada
  email_destinatario: z.string().max(60).optional(),

  // Campo precisa ser validado de acordo com o tipo de documento
  cnpj_retirada: z.string().max(14).optional(),
  cpf_retirada: z.string().max(11).optional(),
  nome_retirada: z.string().max(60).optional(),
  logradouro_retirada: z.string().max(60).optional(),
  numero_retirada: z.string().max(60).optional(),
  complemento_retirada: z.string().max(60).optional(),
  bairro_retirada: z.string().max(60).optional(),
  codigo_municipio_retirada: z.string().max(7).optional(),
  municipio_retirada: z.string().max(60).optional(),
  uf_retirada: z.string().max(2).optional(),
  cep_retirada: z.string().max(8).optional(),
  codigo_pais_retirada: z.string().max(4).optional(),
  pais_retirada: z.string().max(60).optional(),
  telefone_retirada: z.string().max(14).optional(),
  email_retirada: z.string().max(60).optional(),
  inscricao_estadual_retirada: z.string().max(14).optional(),

  // Campo precisa ser validado de acordo com o tipo de documento
  cnpj_entrega: z.string().max(14).optional(),
  cpf_entrega: z.string().max(11).optional(),
  nome_entrega: z.string().max(60).optional(),
  logradouro_entrega: z.string().max(60).optional(),
  numero_entrega: z.string().max(60).optional(),
  complemento_entrega: z.string().max(60).optional(),
  bairro_entrega: z.string().max(60).optional(),
  codigo_municipio_entrega: z.string().max(7).optional(),
  municipio_entrega: z.string().max(60).optional(),
  uf_entrega: z.string().max(2).optional(),
  cep_entrega: z.string().max(8).optional(),
  codigo_pais_entrega: z.string().max(4).optional(),
  pais_entrega: z.string().max(60).optional(),
  telefone_entrega: z.string().max(14).optional(),
  email_entrega: z.string().max(60).optional(),
  inscricao_estadual_entrega: z.string().max(14).optional(),

  pessoas_autorizadas: z.array(focusNfePessoasAutorizadas).max(10).optional(),

  itens: z.array(focusNfeItem).min(1).max(990),

  // Campos com preenchimento automatico pela API se omitido no envio
  icms_base_calculo: z.string().max(16).optional(),
  icms_valor_total: z.string().max(16).optional(),
  icms_valor_total_desonerado: z.string().max(16).optional(),
  fcp_valor_total_uf_destino: z.string().max(16).optional(),
  icms_valor_total_uf_destino: z.string().max(16).optional(),
  fcp_valor_total: z.string().max(16).optional(),
  icms_base_calculo_st: z.string().max(16).optional(),
  icms_valor_total_st: z.string().max(16).optional(),
  fcp_valor_total_st: z.string().max(16).optional(),
  icms_base_calculo_mono: z.string().max(16).optional(),
  icms_mono_valor: z.string().max(16).optional(),
  icms_base_calculo_mono_retencao: z.string().max(16).optional(),
  icms_valor_mono_retencao: z.string().max(16).optional(),
  icms_base_calculo_mono_retido: z.string().max(16).optional(),
  icms_valor_mono_retido: z.string().max(16).optional(),
  valor_produtos: z.string().max(16).optional(),
  valor_frete: z.string().max(16).optional(),
  valor_seguro: z.string().max(16).optional(),
  valor_desconto: z.string().max(16).optional(),
  valor_total_ii: z.string().max(16).optional(),
  valor_ipi: z.string().max(16).optional(),
  valor_ipi_devolvido: z.string().max(16).optional(),
  valor_pis: z.string().max(16).optional(),
  valor_cofins: z.string().max(16).optional(),
  valor_outras_despesas: z.string().max(16).optional(),
  valor_total: z.string().max(16).optional(),
  valor_total_tributos: z.string().max(16).optional(),
  valor_total_servicos: z.string().max(16).optional(),
  issqn_base_calculo: z.string().max(16).optional(),
  issqn_valor_total: z.string().max(16).optional(),
  valor_pis_servicos: z.string().max(16).optional(),
  valor_cofins_servicos: z.string().max(16).optional(),

  data_prestacao_servico: z.string().optional(),
  issqn_valor_total_deducao: z.string().max(16).optional(),
  issqn_valor_total_outras_retencoes: z.string().max(16).optional(),
  issqn_valor_total_desconto_incondicionado: z.string().max(16).optional(),
  issqn_valor_total_desconto_condicionado: z.string().max(16).optional(),
  issqn_valor_total_retencao: z.string().max(16).optional(),
  codigo_regime_especial_tributacao: z
    .nativeEnum(NFeRegimeEspecialTributacao)
    .optional(),
  pis_valor_retido: z.string().max(16).optional(),
  cofins_valor_retido: z.string().max(16).optional(),
  csll_valor_retido: z.string().max(16).optional(),
  irrf_base_calculo: z.string().max(16).optional(),
  irrf_valor_retido: z.string().max(16).optional(),
  prev_social_base_calculo: z.string().max(16).optional(),
  prev_social_valor_retido: z.string().max(16).optional(),

  modalidade_frete: z.nativeEnum(NFeModalidadeFrete),

  // Campo precisa ser validado de acordo com o tipo de documento

  // Frete
  cnpj_transportador: z.string().max(14).optional(),
  cpf_transportador: z.string().max(11).optional(),
  nome_transportador: z.string().max(60).optional(),
  inscricao_estadual_transportador: z.string().max(14).optional(),
  endereco_transportador: z.string().max(60).optional(),
  municipio_transportador: z.string().max(60).optional(),
  uf_transportador: z.string().max(2).optional(),
  transporte_icms_servico: z.string().max(15).optional(),
  transporte_icms_base_calculo: z.string().max(16).optional(),
  transporte_icms_cfop: z.string().max(4).optional(), //Valores aceitos: 5351, 5352, 5353, 5354, 5355, 5356, 5357, 5359, 5360, 5931, 5932, 6351, 6352, 6353, 6354, 6355, 6356, 6357, 6359, 6360, 6931, 6932, 7358
  transporte_icms_codigo_municipio: z.string().max(7).optional(),
  veiculo_placa: z.string().max(2).optional(),
  veiculo_uf: z.string().max(2).optional(),
  veiculo_rntc: z.string().max(6).optional(),
  reboques: z.array(focusNfeReboque).max(5).optional(),
  veiculo_identificacao_vagao: z.string().max(20).optional(),
  veiculo_identificacao_balsa: z.string().max(20).optional(),
  volumes: z.array(focusNfeVolume).max(5000).optional(),
  //

  numero_fatura: z.string().max(60).optional(),
  valor_original_fatura: z.string().max(16).optional(),
  valor_desconto_fatura: z.string().max(16).optional(),
  valor_liquido_fatura: z.string().max(16).optional(),
  duplicatas: z.array(focusNfeDuplicata).max(120).optional(),
  formas_pagamento: z.array(focusNfeFormaPagamento).max(100).optional(),
  valor_troco: z.string().max(16).optional(),
  cnpj_intermediario: z.string().max(14).optional(),
  id_intermediario: z.string().max(60).optional(),
  informacoes_adicionais_fisco: z.string().max(2000).optional(),
  informacoes_adicionais_contribuinte: z.string().max(5000).optional(),
  observacoes_contribuinte: z
    .array(focusNfeObservacaoContribuinte)
    .max(10)
    .optional(),
  observacoes_fisco: z.array(focusNfeObservacoesFisco).max(10).optional(),
  processos_referenciados: z
    .array(focusNfeProcessosReferenciados)
    .max(10)
    .optional(),
  nota_empenho_compra: z.string().max(22).optional(),
  pedido_compra: z.string().max(60).optional(),
  contrato_compra: z.string().max(60).optional(),
  uf_local_embarque: z.string().max(2).optional(),
  local_embarque: z.string().max(60).optional(),
  local_despacho: z.string().max(60).optional(),
  cnpj_responsavel_tecnico: z.string().max(14).optional(),
  contato_responsavel_tecnico: z.string().max(60).optional(),
  email_responsavel_tecnico: z.string().max(60).optional(),
  telefone_responsavel_tecnico: z.string().max(14).optional(),
  identificador_csrt: z.string().max(2).optional(),
  hash_csrt: z.string().max(20).optional(),
});

export type FocusProductInvoice = z.infer<typeof focusProductInvoice>;

export {
  NFeIndicadorInscricaoEstadual,
  NFeModalidadeFrete,
  NFeItemIpiSituacaoTributaria,
  NFeItemIpiCodigoSeloControle,
  NFeItemPisCofinsSituacaoTributaria,
  NFeItemIcmsOrigem,
  NFeItemIcmsSituacaoTributaria,
  NFeItemIcmsModalidadeBaseCalculo,
  NFeItemIcmsMotivoDesoneracao,
  NFeItemIcmsDeducaoDesoneracao,
  NFeItemIcmsMotivoReducao,
  NFeItemIcmsModalidadeBaseCalculoSt,
  NFeItemIcmsMotivoDesoneracaoSt,
  NFeTipoDocumento,
  NFeLocalDestino,
  NFeFinalidadeEmissao,
  NFeConsumidorFinal,
  NFePresencaComprador,
};
