import {
  type ControllerProps,
  type FieldPath,
  type FieldValues,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  CalculationType,
  type NewProductInvoice,
  productInvoiceItemSchema,
} from "@shared/schemas/invoices/nfe/new_product.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  NFeItemIcmsOrigem,
  NFeItemIcmsOrigemList,
  NFeItemIcmsSituacaoTributaria,
  NFeItemIcmsSituacaoTributariaList,
  NFeItemIpiSituacaoTributaria,
  NFeItemIpiSituacaoTributariaList,
  NFeItemPisCofinsSituacaoTributaria,
  NFeItemPisCofinsSituacaoTributariaList,
} from "@shared/schemas/invoices/nfe/focus/focus_product.ts";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { Form, FormFieldSimple } from "@/components/ui/form.tsx";
import { Label } from "@/components/ui/label.tsx";
import { SelectPopover } from "@/components/select-popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { popModal } from "@/modals";
import { PendingButton } from "@/components/pending-button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { CFOP } from "@shared/constants/cfop.ts";
import { Switch } from "@/components/ui/switch.tsx";
import {
  CurrencyInput,
  PercentageInput,
} from "@/components/validators/currency-input.tsx";
import { cn, type FlattenKeys } from "@/lib/utils.ts";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SelectContent } from "@/components/ui/select.tsx";
import { type ComponentType, useMemo } from "react";
import type { z } from "zod";

type ProductItem = NewProductInvoice["items"][0];

function resetFields<T extends Record<string, any>, K extends keyof T>(
  fields: readonly K[],
  form: { setValue: (key: K, value: any) => void }
) {
  fields.forEach((fieldName) => {
    form.setValue(fieldName, "");
  });
}

const calculateTaxValue = (
  isPercentage: boolean,
  percentage: string | undefined = "0",
  base: string | undefined = "0",
  aliquotValue: string | undefined = "0"
) => {
  if (isPercentage) {
    const percentageNumber = Number(percentage);
    const baseNumber = Number(base);

    return (baseNumber * (percentageNumber / 100)).toFixed(2);
  }

  return aliquotValue ?? "";
};

const multiplyValues = (one: string | undefined, two: string | undefined) => {
  const oneNumber = Number(one ?? "0");
  const twoNumber = Number(two ?? "0");
  return (oneNumber * twoNumber).toFixed(2);
};

function isPisCofinsSpecialCalculation(
  value: NFeItemPisCofinsSituacaoTributaria[],
  situation: NFeItemPisCofinsSituacaoTributaria,
  isPercentage: boolean
) {
  return (
    value.includes(situation) ||
    (!pisCofinsSpecialCalculation.includes(situation) && isPercentage)
  );
}

const ipiCalculationBaseFields = [
  "ipiTax.calculationBase",
  "ipiTax.percentageAliquot",
  "ipiTax.aliquotValue",
  "ipiTax.value",
] as const;

// const ipiFields = [
//   "ipiTax.taxSituation",
//   "ipiTax.producerBusinessDocument",
//   "ipiTax.sealCode",
//   "ipiTax.sealQuantity",
//   "ipiTax.frameworkCode",
//   ...ipiCalculationBaseFields,
// ] as const;

const ipiCalculateTaxSituation = [
  NFeItemIpiSituacaoTributaria.ENTRADA_COM_RECUPERACAO_DE_CREDITO,
  NFeItemIpiSituacaoTributaria.OUTRAS_ENTRADAS,
  NFeItemIpiSituacaoTributaria.SAIDA_TRIBUTADA,
  NFeItemIpiSituacaoTributaria.OUTRAS_SAIDAS,
];

const pisCofinsNoCalculateTaxSituation = [
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_TRIBUTAVEL_TRIBUTACAO_MONOFASICA_ALIQUOTA_ZERO,
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO,
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_ISENTA_CONTRIBUICAO,
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO,
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_COM_SUSPENSAO_CONTRIBUICAO,
];
const pisCofinsOnlyCalculateTaxSituationByValue = [
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_TRIBUTAVEL_BASE_CALCULO_QUANTIDADE_VENDIDA_X_ALIQUOTA_UNIDADE,
];
const pisCofinsOnlyCalculateTaxSituationByPercentage = [
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_DIFERENCIADA,
  NFeItemPisCofinsSituacaoTributaria.OPERACAO_TRIBUTAVEL_BASE_CALCULO_VALOR_OPERACAO_ALIQUOTA_NORMAL,
];
const pisCofinsSpecialCalculation = [
  ...pisCofinsOnlyCalculateTaxSituationByValue,
  ...pisCofinsOnlyCalculateTaxSituationByPercentage,
];

const pisFields = [
  "pisTax.calculationBase",
  "pisTax.percentageAliquot",
  "pisTax.aliquotValue",
  "pisTax.value",
] as const;
const pisStFields = [
  "pisTax.stCalculationBase",
  "pisTax.stPercentageAliquot",
  "pisTax.stAliquotValue",
  "pisTax.stValue",
] as const;

const cofinsFields = [
  "cofinsTax.calculationBase",
  "cofinsTax.percentageAliquot",
  "cofinsTax.aliquotValue",
  "cofinsTax.value",
] as const;
const cofinsStFields = [
  "cofinsTax.stCalculationBase",
  "cofinsTax.stPercentageAliquot",
  "cofinsTax.stAliquotValue",
  "cofinsTax.stValue",
] as const;

type IcmsField<T> = {
  name: FlattenKeys<T>;
  field: ComponentType<any>;
  label?: string;
  disabled?: boolean;
  inline?: boolean;
};

type IcmsFieldsComponents<T> = {
  [key: string]: Array<IcmsField<T>[]>;
};

const icmsBC: IcmsField<ProductItem>[] = [
  {
    name: "icmsTax.calculationMode",
    field: IcmsBCMode,
    label: "Modalidade BC",
  },
  {
    name: "icmsTax.calculationBase",
    field: IcmsCurrencyField,
    label: "Base de cálculo",
  },
  {
    name: "icmsTax.aliquot",
    field: IcmsPercentageField,
    label: "Alíquota do ICMS",
  },
  {
    name: "icmsTax.value",
    field: IcmsTaxValue,
    label: "Valor do ICMS",
    disabled: true,
  },
];

const icmsExemption: IcmsField<ProductItem>[] = [
  {
    name: "icmsTax.exemptionReason",
    field: IcmsExemptionReason,
    label: "Motivo desoneração",
  },
  {
    name: "icmsTax.exemptedValue",
    field: IcmsCurrencyField,
    label: "ICMS desonerado",
  },
];

const icmsStExemption: IcmsField<ProductItem>[] = [
  {
    name: "icmsTax.stExemptionReason",
    field: IcmsStExemptionReason,
    label: "Motivo desoneração ST",
  },
  {
    name: "icmsTax.stExemptedValue",
    field: IcmsCurrencyField,
    label: "ICMS ST desonerado",
  },
];

const icmsStBC: IcmsField<ProductItem>[] = [
  {
    name: "icmsTax.stCalculationMode",
    field: IcmsStBCMode,
    label: "Modalidade BC ST",
  },
  {
    name: "icmsTax.stAddedValueMargin",
    field: IcmsPercentageField,
    label: "Margem valor adic.",
  },
  {
    name: "icmsTax.stCalculationBaseReduction",
    field: IcmsPercentageField,
    label: "Redução Base ST",
  },
  {
    name: "icmsTax.stCalculationBase",
    field: IcmsCurrencyField,
    label: "Base de cálc. ST",
  },
  {
    name: "icmsTax.stAliquot",
    field: IcmsPercentageField,
    label: "Alíq. ICMS ST",
  },
  {
    name: "icmsTax.stValue",
    field: IcmsTaxValue,
    label: "Valor ICMS ST",
    disabled: true,
  },
];

const icmsMono: IcmsField<ProductItem>[] = [
  {
    name: "icmsTax.monoCalculationBase",
    field: IcmsInputField,
    label: "Quant. tributada",
  },
  {
    name: "icmsTax.aliquot",
    field: IcmsCurrencyField,
    label: "Aliq. ad rem imposto",
  },
  {
    name: "icmsTax.monoValue",
    field: IcmsTaxValue,
    label: "Valor ICMS próprio",
  },
];

const icmsSN: IcmsField<ProductItem>[] = [
  {
    name: "icmsTax.simpleCreditAliquot",
    field: IcmsPercentageField,
    label: "Aliq. cálc. crédito",
  },
  {
    name: "icmsTax.simpleCreditValue",
    field: IcmsTaxValue,
    label: "Valor créd. ICMS",
  },
];

const icmsWithheld: IcmsField<ProductItem>[] = [
  {
    name: "icmsTax.stWithheldCalculationBase",
    field: IcmsInputField,
    label: "BC ST UF origem",
  },
  {
    name: "icmsTax.finalAliquot",
    field: IcmsPercentageField,
    label: "Aliq. Cons. Final",
  },
  {
    name: "icmsTax.substituteValue",
    field: IcmsInputField,
    label: "ICMS próprio substituído",
  },
  {
    name: "icmsTax.stWithheldValue",
    field: IcmsInputField,
    label: "ICMS ST retido ant.",
  },
];

const icmsFieldsComponents: IcmsFieldsComponents<ProductItem> = {
  [NFeItemIcmsSituacaoTributaria.TRIBUTADA_INTEGRALMENTE]: [icmsBC],
  [NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_PROPRIA_SOBRE_COMBUSTIVEIS]:
    [icmsMono],
  [NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_PROPRIA_COM_RETENCAO_SOBRE_COMBUSTIVEIS]:
    [
      icmsMono,
      [
        {
          name: "icmsTax.monoRetentionCalculationBase",
          field: IcmsCurrencyField,
          label: "Qnt. trib. sujeita a retenção",
        },
        {
          name: "icmsTax.aliquotRetentionValue",
          field: IcmsCurrencyField,
          label: "Aliq. ad rem com retenção",
        },
        {
          name: "icmsTax.monoRetentionValue",
          field: IcmsTaxValue,
          label: "Valor ICMS com retenção",
        },
        {
          name: "icmsTax.reductionReason",
          field: IcmsStReductionReason,
          label: "Motivo redução ad rem",
        },
        {
          name: "icmsTax.reductionPercentage",
          field: IcmsPercentageField,
          label: "Perc. redução ad rem ICMS",
        },
      ],
    ],
  [NFeItemIcmsSituacaoTributaria.TRIBUTADA_COM_COBRANCA_DO_ICMS_POR_SUBSTITUICAO_TRIBUTARIA]:
    [icmsBC, icmsStBC],
  [NFeItemIcmsSituacaoTributaria.TRIBUTADA_COM_REDUCAO_DE_BASE_DE_CALCULO]: [
    [
      {
        name: "icmsTax.calculationBaseReduction",
        field: IcmsPercentageField,
        label: "Redução Base de cálculo",
      },
      ...icmsBC,
    ],
    icmsExemption,
  ],
  [NFeItemIcmsSituacaoTributaria.ISENTA_OU_NAO_TRIBUTADA_COM_COBRANCA_DO_ICMS_POR_SUBSTITUICAO_TRIBUTARIA]:
    [icmsStBC, icmsStExemption],
  [NFeItemIcmsSituacaoTributaria.ISENTA]: [icmsExemption],
  [NFeItemIcmsSituacaoTributaria.NAO_TRIBUTADA]: [icmsExemption],
  [NFeItemIcmsSituacaoTributaria.SUSPENSAO]: [icmsExemption],
  [NFeItemIcmsSituacaoTributaria.DIFERIMENTO]: [
    [
      {
        name: "icmsTax.calculationBaseReduction",
        field: IcmsPercentageField,
        label: "Redução Base de cálculo",
      },
      ...icmsBC,
    ],
    [
      {
        name: "icmsTax.taxBenefitCodeBaseReduction",
        field: IcmsInputField,
        label: "Cód. Benef. Fiscal UF RBC",
      },
      {
        name: "icmsTax.operationValue",
        field: IcmsTaxValue,
        label: "ICMS da operação",
        disabled: true,
      },
      {
        name: "icmsTax.deferralPercentage",
        field: IcmsPercentageField,
        label: "Aliq. diferimento",
      },
      {
        name: "icmsTax.deferredValue",
        field: IcmsTaxValue,
        label: "ICMS diferido",
        disabled: true,
      },
    ],
  ],
  [NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_SOBRE_COMBUSTIVEIS_COM_RECOLHIMENTO_DIFERIDO]:
    [
      icmsMono,
      [
        {
          name: "icmsTax.deferralPercentage",
          field: IcmsPercentageField,
          label: "Aliq. diferimento",
        },
        {
          name: "icmsTax.monoOperationValue",
          field: IcmsTaxValue,
          label: "Valor do ICMS monof. op.",
          disabled: true,
        },
        {
          name: "icmsTax.monoDeferredValue",
          field: IcmsTaxValue,
          label: "Valor ICMS monof. diferido",
          disabled: true,
        },
      ],
    ],
  [NFeItemIcmsSituacaoTributaria.COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA]:
    [icmsWithheld],
  [NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_SOBRE_COMBUSTIVEIS_COBRADA_ANTERIORMENTE]:
    [
      [
        {
          name: "icmsTax.monoWithheldCalculationBase",
          field: IcmsInputField,
          label: "Quant. trib. retirada ant.",
        },
        {
          name: "icmsTax.withheldAliquot",
          field: IcmsInputField,
          label: "Aliq. ad rem imposto ret.",
        },
        {
          name: "icmsTax.monoWithheldValue",
          field: IcmsTaxValue,
          label: "Valor ICMS ret. ant.",
          disabled: true,
        },
      ],
    ],
  [NFeItemIcmsSituacaoTributaria.TRIBUTADA_COM_REDUCAO_DE_BASE_E_COM_COBRANCA_DO_ICMS_POR_SUBSTITUICAO_TRIBUTARIA]:
    [
      [
        {
          name: "icmsTax.calculationBaseReduction",
          field: IcmsPercentageField,
          label: "Redução Base de cálculo",
        },
        ...icmsBC,
      ],
      icmsStBC,
      [...icmsExemption, ...icmsStExemption],
    ],
  [NFeItemIcmsSituacaoTributaria.OUTRAS_REGIME_NORMAL]: [
    [
      {
        name: "icmsTax.calculationBaseReduction",
        field: IcmsPercentageField,
        label: "Redução Base de cálculo",
      },
      ...icmsBC,
    ],
    icmsStBC,
    [...icmsExemption, ...icmsStExemption],
  ],
  [NFeItemIcmsSituacaoTributaria.TRIBUTADA_PELO_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO]:
    [icmsSN],
  [NFeItemIcmsSituacaoTributaria.TRIBUTADA_PELO_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO_COM_SUBSTITUICAO_TRIBUTARIA]:
    [icmsStBC, icmsSN],
  [NFeItemIcmsSituacaoTributaria.TRIBUTADA_PELO_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO_COM_SUBSTITUICAO_TRIBUTARIA]:
    [icmsStBC],
  [NFeItemIcmsSituacaoTributaria.ISENCAO_DO_ICMS_NO_SIMPLES_NACIONAL_FAIXA_RECEITA_BRUTA_COM_SUBSTITUICAO_TRIBUTARIA]:
    [icmsStBC],
  [NFeItemIcmsSituacaoTributaria.ICMS_COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA]:
    [icmsWithheld],
  [NFeItemIcmsSituacaoTributaria.OUTRAS_REGIME_SIMPLES_NACIONAL]: [
    [
      {
        name: "icmsTax.calculationBaseReduction",
        field: IcmsPercentageField,
        label: "Redução Base de cálculo",
      },
      ...icmsBC,
    ],
    icmsStBC,
    icmsSN,
  ],
} as const;

function createDefaultValues<T extends z.ZodObject<any>>(schema: T) {
  return Object.fromEntries(
    Object.keys(schema.shape).map((key) => [key, ""])
  ) as z.infer<T>;
}

const icmsDefaultValues = (() => {
  const defaultValues = createDefaultValues(
    productInvoiceItemSchema.innerType().shape.icmsTax
  );

  return {
    ...defaultValues,
    origin: NFeItemIcmsOrigem.NACIONAL,
    taxSituation: NFeItemIcmsSituacaoTributaria.ISENTA,
  };
})();

const cofinsDefaultValues = (() => {
  const defaultValues = createDefaultValues(
    productInvoiceItemSchema.innerType().shape.cofinsTax
  );

  return {
    ...defaultValues,
    taxSituation:
      NFeItemPisCofinsSituacaoTributaria.OPERACAO_ISENTA_CONTRIBUICAO,
    stCalculationType: CalculationType.NONE,
    calculationPercentage: false,
  };
})();

const pisDefaultValues = (() => {
  const defaultValues = createDefaultValues(
    productInvoiceItemSchema.innerType().shape.pisTax
  );

  return {
    ...defaultValues,
    taxSituation:
      NFeItemPisCofinsSituacaoTributaria.OPERACAO_ISENTA_CONTRIBUICAO,
    calculationPercentage: false,
    stCalculationType: CalculationType.NONE,
  };
})();

const ipiDefaultValues = (() => {
  const defaultValues = createDefaultValues(
    productInvoiceItemSchema.innerType().shape.ipiTax
  );

  return {
    ...defaultValues,
    calculationPercentage: false,
  };
})();

export function NewProductInvoiceAddItemModal({
  item,
  index,
  onCreate,
  onUpdate,
}: {
  item?: ProductItem;
  index?: number;
  onCreate?: (item: ProductItem) => void;
  onUpdate?: (index: number, item: ProductItem) => void;
}) {
  const form = useForm<ProductItem>({
    resolver: zodResolver(productInvoiceItemSchema),
    defaultValues: {
      description: item?.description ?? "",
      code: item?.code ?? "",
      cfop: item?.cfop ?? "",
      commercialUnit: item?.commercialUnit ?? "",
      commercialQuantity: item?.commercialQuantity ?? "",
      commercialUnitValue: item?.commercialUnitValue ?? "",
      commercialBarCode: item?.commercialBarCode ?? "",

      differentTaxation: !!item?.taxableUnit,

      taxableUnit: item?.taxableUnit ?? "",
      taxableQuantity: item?.taxableQuantity ?? "",
      taxableUnitValue: item?.taxableUnitValue ?? "",
      taxableBarCode: item?.taxableBarCode ?? "",

      freightValue: item?.freightValue ?? "",
      insuranceValue: item?.insuranceValue ?? "",
      discountValue: item?.discountValue ?? "",
      othersValue: item?.othersValue ?? "",
      taxApproximateValue: item?.taxApproximateValue ?? "",

      ncmCode: item?.ncmCode ?? "",
      tipiExceptionCode: item?.tipiExceptionCode ?? "",
      includeInTotal: !!item?.includeInTotal,
      stateTaxBeneficiaryCode: item?.stateTaxBeneficiaryCode ?? "",
      presumedCreditInfos: item?.presumedCreditInfos ?? [],

      declareIpiTax: !!item?.ipiTax.taxSituation,
      ipiTax: {
        ...ipiDefaultValues,
        ...item?.ipiTax,
      },
      pisTax: {
        ...pisDefaultValues,
        calculationPercentage: !item?.pisTax?.aliquotValue,
        stCalculationType: item?.pisTax?.stAliquotValue
          ? CalculationType.VALUE
          : item?.pisTax?.percentageAliquot && item?.pisTax?.calculationBase
            ? CalculationType.PERCENTAGE
            : CalculationType.NONE,
      },
      cofinsTax: {
        ...cofinsDefaultValues,
        ...(item?.cofinsTax ?? {}),
      },
      icmsTax: {
        ...icmsDefaultValues,
        ...(item?.icmsTax ?? {}),
      },
      observations: item?.observations ?? "",
    },
  });

  const submit = form.handleSubmit((data) => {
    console.log(data);
    item && typeof index !== "undefined"
      ? onUpdate?.(index, data)
      : onCreate?.(data);
    popModal("NewProductInvoiceAddItemModal");
  });

  const [
    isUsingDiffTaxation,

    ipiIsDeclaringTax,
    ipiTaxSituation,
    ipiIsCalculationPercentage,
    ipiAliquotValue,
    ipiPercentageAliquot,
    ipiBaseCalculation,

    pisTaxSituation,
    pisIsCalculationPercentage,
    pisPercentageAliquot,
    pisBaseCalculation,
    pisAliquotValue,
    pisStCalculationType,
    pisStPercentageAliquot,
    pisStBaseCalculation,
    pisStAliquotValue,

    cofinsTaxSituation,
    cofinsIsCalculationPercentage,
    cofinsPercentageAliquot,
    cofinsBaseCalculation,
    cofinsAliquotValue,
    cofinsStCalculationType,
    cofinsStPercentageAliquot,
    cofinsStBaseCalculation,
    cofinsStAliquotValue,

    icmsTaxSituation,
    icmsCalculationBase,
    icmsAliquot,
    icmsMonoCalculationBase,
    icmsStCalculationBase,
    icmsStAliquot,
    icmsDeferralPercentage,
    icmsDeferredValue,
    icmsOperationValue,
    icmsMonoDeferredValue,
    icmsMonoOperationValue,
    icmsMonoWithheldCalculationBase,
    icmsWithheldValue,
  ] = useWatch({
    control: form.control,
    name: [
      "differentTaxation",

      "declareIpiTax",
      "ipiTax.taxSituation",
      "ipiTax.calculationPercentage",
      "ipiTax.aliquotValue",
      "ipiTax.percentageAliquot",
      "ipiTax.calculationBase",

      "pisTax.taxSituation",
      "pisTax.calculationPercentage",
      "pisTax.percentageAliquot",
      "pisTax.calculationBase",
      "pisTax.aliquotValue",
      "pisTax.stCalculationType",
      "pisTax.stPercentageAliquot",
      "pisTax.stCalculationBase",
      "pisTax.stAliquotValue",

      "cofinsTax.taxSituation",
      "cofinsTax.calculationPercentage",
      "cofinsTax.percentageAliquot",
      "cofinsTax.calculationBase",
      "cofinsTax.aliquotValue",
      "cofinsTax.stCalculationType",
      "cofinsTax.stPercentageAliquot",
      "cofinsTax.stCalculationBase",
      "cofinsTax.stAliquotValue",

      "icmsTax.taxSituation",
      "icmsTax.calculationBase",
      "icmsTax.aliquot",
      "icmsTax.monoCalculationBase",
      "icmsTax.stCalculationBase",
      "icmsTax.stAliquot",
      "icmsTax.deferralPercentage",
      "icmsTax.deferredValue",
      "icmsTax.operationValue",
      "icmsTax.monoDeferredValue",
      "icmsTax.monoOperationValue",
      "icmsTax.monoWithheldCalculationBase",
      "icmsTax.withheldAliquot",
    ],
  });

  const icmsFields = useMemo(() => {
    return (
      icmsFieldsComponents[
        icmsTaxSituation as keyof typeof icmsFieldsComponents
      ] || []
    );
  }, [icmsTaxSituation]);

  return (
    <DialogContent className="max-w-6xl max-h-[80vh] overflow-auto">
      <div className="relative">
        <DialogHeader>
          <DialogTitle>
            {item ? `Editar produto: ${item.description}` : "Adicionar produto"}
          </DialogTitle>
          <DialogDescription>
            {item
              ? "Edite as informações do produto selecionado."
              : "Adicione um novo produto a esta nota. Você pode preencher os dados de um novo ou selecionar um produto já cadastrado."}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={submit}>
            <div className="grid grid-cols-6 gap-4">
              {!item && (
                <div className="col-span-6 space-y-2">
                  <Label>Lista dos Produtos Cadastrados</Label>
                  <SelectPopover
                    items={[]}
                    onValueChange={(value) => {
                      console.log(value);
                    }}
                    modal={true}
                  />
                </div>
              )}
              <h1 className="col-span-6 text-xl font-medium">
                1. Dados Gerais do Produto
              </h1>
              <FormFieldSimple
                control={form.control}
                name="code"
                label="Código do Produto"
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                className="col-span-2"
                control={form.control}
                name="description"
                label="Nome do Produto"
                render={({ field }) => <Input {...field} />}
              />

              <FormFieldSimple
                className="col-span-2"
                control={form.control}
                name="cfop"
                label="CFOP"
                render={({ field }) => (
                  <SelectPopover
                    items={CFOP.list.map((item) => ({
                      value: item.value,
                      label: `${item.value} - ${item.label}`,
                      keywords: [item.label],
                    }))}
                    value={field.value}
                    onValueChange={field.onChange}
                    modal={true}
                  />
                )}
              />
              <div />
              <FormFieldSimple
                control={form.control}
                name="commercialUnit"
                label="Unidade Comercial"
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="commercialQuantity"
                label="Quantidade Comercial"
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        icmsTaxSituation ===
                        NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_PROPRIA_SOBRE_COMBUSTIVEIS
                      ) {
                        form.setValue("icmsTax.monoCalculationBase", value);
                      }

                      if (
                        icmsTaxSituation ===
                        NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_SOBRE_COMBUSTIVEIS_COBRADA_ANTERIORMENTE
                      ) {
                        form.setValue(
                          "icmsTax.monoWithheldCalculationBase",
                          value
                        );
                      }

                      field.onChange(value);
                    }}
                  />
                )}
              />
              <FormFieldSimple
                control={form.control}
                name="commercialUnitValue"
                label="Valor Unit. Comercial"
                render={({ field }) => <CurrencyInput {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="commercialBarCode"
                label="Refer. GTIN/EAN"
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                className="col-span-2 justify-end mb-2.5"
                control={form.control}
                name="includeInTotal"
                render={({ field }) => (
                  <div className="flex items-center gap-x-2">
                    <Checkbox
                      id="include-in-total"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <Label htmlFor="include-in-total">
                      Incluir no valor total da nota
                    </Label>
                  </div>
                )}
              />
              <div
                className={cn(
                  "col-span-6 space-y-3 px-1.5",
                  isUsingDiffTaxation &&
                    "border-2 rounded-md border-dashed py-3 "
                )}
              >
                <FormFieldSimple
                  control={form.control}
                  name="differentTaxation"
                  render={({ field }) => (
                    <div className="flex items-center gap-x-2">
                      <Switch
                        id="different-taxation"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <Label htmlFor="different-taxation">
                        Usar valores diferentes para declaração de imposto no
                        preço do produto
                      </Label>
                    </div>
                  )}
                />
                {isUsingDiffTaxation && (
                  <div className="grid grid-cols-6 gap-3">
                    <FormFieldSimple
                      control={form.control}
                      name="taxableUnit"
                      label="Unidade Tributável"
                      render={({ field }) => <Input {...field} />}
                    />
                    <FormFieldSimple
                      control={form.control}
                      name="taxableQuantity"
                      label="Quantidade Tributável"
                      render={({ field }) => <Input {...field} />}
                    />
                    <FormFieldSimple
                      control={form.control}
                      name="taxableUnitValue"
                      label="Valor Uni. Tributável"
                      render={({ field }) => <CurrencyInput {...field} />}
                    />
                    <FormFieldSimple
                      control={form.control}
                      name="taxableBarCode"
                      label="GTIN/EAN Tributável"
                      render={({ field }) => <Input {...field} />}
                    />
                  </div>
                )}
              </div>

              <FormFieldSimple
                control={form.control}
                name="freightValue"
                label="Valor de Frete"
                render={({ field }) => <CurrencyInput {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="insuranceValue"
                label="Valor de Seguro"
                render={({ field }) => <CurrencyInput {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="discountValue"
                label="Valor de Desconto"
                render={({ field }) => <CurrencyInput {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="othersValue"
                label="Valor Outras Desp."
                render={({ field }) => <CurrencyInput {...field} />}
              />
              <h1 className="col-span-6 text-xl font-medium">2. NCM</h1>
              <FormFieldSimple
                control={form.control}
                name="ncmCode"
                label="Código NCM"
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="tipiExceptionCode"
                label="Código de Ex. de TIPI"
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="stateTaxBeneficiaryCode"
                label="Cód. Benef. Fiscal UF"
                render={({ field }) => <Input {...field} />}
              />
              <h1 className="col-span-6 text-xl font-medium">3. Tributos</h1>

              <div className="flex items-center gap-x-2 col-span-6">
                <h2 className="text-lg font-medium">3.1. IPI</h2>
                <FormFieldSimple
                  control={form.control}
                  name="declareIpiTax"
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onCheckedChange={(checked) => {
                        if (!checked) {
                          form.resetField("ipiTax");
                        }

                        field.onChange(checked);
                      }}
                    />
                  )}
                />
              </div>
              {ipiIsDeclaringTax && (
                <>
                  <FormFieldSimple
                    className="col-span-2"
                    control={form.control}
                    name="ipiTax.taxSituation"
                    label="Situação Tributária"
                    render={({ field }) => (
                      <SelectPopover
                        items={NFeItemIpiSituacaoTributariaList.map((item) => ({
                          value: item.value,
                          label: `${item.value} - ${item.label}`,
                          keywords: [item.label],
                        }))}
                        value={field.value}
                        onValueChange={(value) => {
                          if (
                            !ipiCalculateTaxSituation.includes(
                              value as NFeItemIpiSituacaoTributaria
                            )
                          ) {
                            resetFields(ipiCalculationBaseFields, form);
                          }

                          field.onChange(value);
                        }}
                        modal={true}
                      />
                    )}
                  />
                  <FormFieldSimple
                    control={form.control}
                    name="ipiTax.producerBusinessDocument"
                    label="Produtor (CPF ou CNPJ)"
                    render={({ field }) => <Input {...field} />}
                  />
                  <FormFieldSimple
                    control={form.control}
                    name="ipiTax.sealCode"
                    label="Cód. Selo Controle"
                    render={({ field }) => <Input {...field} />}
                  />
                  <FormFieldSimple
                    control={form.control}
                    name="ipiTax.sealQuantity"
                    label="Quant. Selo"
                    render={({ field }) => <Input {...field} />}
                  />
                  <FormFieldSimple
                    control={form.control}
                    name="ipiTax.frameworkCode"
                    label="Cód. Enquadramento"
                    render={({ field }) => <Input {...field} />}
                  />
                  {ipiCalculateTaxSituation.includes(
                    ipiTaxSituation as NFeItemIpiSituacaoTributaria
                  ) && (
                    <>
                      <FormFieldSimple
                        control={form.control}
                        name="ipiTax.calculationPercentage"
                        label="Tipo de Cálculo"
                        render={({ field }) => (
                          <Select
                            value={field.value?.toString()}
                            onValueChange={(value) => {
                              resetFields(ipiCalculationBaseFields, form);
                              field.onChange(value === "true");
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Selecione" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="false">Em valor</SelectItem>
                              <SelectItem value="true">Porcentagem</SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />

                      {ipiIsCalculationPercentage && (
                        <>
                          <FormFieldSimple
                            control={form.control}
                            name="ipiTax.calculationBase"
                            label="Valor Base de Cálculo"
                            render={({ field }) => <CurrencyInput {...field} />}
                          />
                          <FormFieldSimple
                            control={form.control}
                            name="ipiTax.percentageAliquot"
                            label="Percentual do Aliquota"
                            render={({ field }) => (
                              <PercentageInput {...field} />
                            )}
                          />
                        </>
                      )}
                      {!ipiIsCalculationPercentage && (
                        <FormFieldSimple
                          control={form.control}
                          name="ipiTax.aliquotValue"
                          label="Valor do Aliquota"
                          render={({ field }) => <CurrencyInput {...field} />}
                        />
                      )}
                      <FormFieldSimple
                        control={form.control}
                        name="ipiTax.value"
                        label="Valor do IPI"
                        render={({ field }) => (
                          <CurrencyInput
                            {...field}
                            value={calculateTaxValue(
                              ipiIsCalculationPercentage,
                              ipiPercentageAliquot,
                              ipiBaseCalculation,
                              ipiAliquotValue
                            )}
                            disabled
                          />
                        )}
                      />
                    </>
                  )}
                </>
              )}

              <h2 className="text-lg font-medium col-span-6">3.2. ICMS</h2>
              <div className="col-span-6 grid grid-cols-6 gap-3">
                <FormFieldSimple
                  className="col-span-2"
                  control={form.control}
                  name="icmsTax.taxSituation"
                  label="Situação Tributária"
                  render={({ field }) => (
                    <SelectPopover
                      items={NFeItemIcmsSituacaoTributariaList.map((item) => ({
                        value: item.value,
                        label: `${item.value} - ${item.label}`,
                        keywords: [item.label],
                      }))}
                      value={field.value}
                      onValueChange={(value) => {
                        field.onChange(value);
                      }}
                      modal={true}
                    />
                  )}
                />
                <FormFieldSimple
                  className="col-span-2"
                  control={form.control}
                  name="icmsTax.origin"
                  label="Origem"
                  render={({ field }) => (
                    <SelectPopover
                      items={NFeItemIcmsOrigemList.map((item) => ({
                        value: item.value,
                        label: `${item.value} - ${item.label}`,
                        keywords: [item.label],
                      }))}
                      value={field.value}
                      onValueChange={(value) => {
                        field.onChange(value);
                      }}
                      modal={true}
                    />
                  )}
                />
              </div>
              <div className="col-span-6 space-y-3">
                {icmsFields.map((obj) => {
                  return (
                    <div
                      key={`icmsFields_${obj.map((tax) => tax.name).join("_")}`}
                      className="grid grid-cols-6 gap-3"
                    >
                      {obj.map((tax) => {
                        const props = {
                          control: form.control,
                          name: tax.name,
                          label: tax.label,
                          disabled: tax.disabled,
                        };

                        if (tax.name === "icmsTax.value") {
                          if (
                            icmsTaxSituation ===
                            NFeItemIcmsSituacaoTributaria.DIFERIMENTO
                          ) {
                            return (
                              <tax.field
                                {...props}
                                key={`${tax.name}_${tax.label}`}
                                value={(
                                  Number(icmsOperationValue || "0") -
                                  Number(icmsDeferredValue || "0")
                                ).toFixed(2)}
                              />
                            );
                          }

                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={calculateTaxValue(
                                true,
                                icmsAliquot,
                                icmsCalculationBase
                              )}
                            />
                          );
                        }

                        if (tax.name === "icmsTax.monoValue") {
                          if (
                            icmsTaxSituation ===
                            NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_SOBRE_COMBUSTIVEIS_COM_RECOLHIMENTO_DIFERIDO
                          ) {
                            return (
                              <tax.field
                                {...props}
                                key={`${tax.name}_${tax.label}`}
                                value={(
                                  Number(icmsMonoOperationValue || "0") -
                                  Number(icmsMonoDeferredValue || "0")
                                ).toFixed(2)}
                              />
                            );
                          }

                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={multiplyValues(
                                icmsAliquot,
                                icmsMonoCalculationBase
                              )}
                            />
                          );
                        }

                        if (tax.name === "icmsTax.stValue") {
                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={calculateTaxValue(
                                true,
                                icmsStAliquot,
                                icmsStCalculationBase
                              )}
                            />
                          );
                        }

                        if (tax.name === "icmsTax.operationValue") {
                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={calculateTaxValue(
                                true,
                                icmsAliquot,
                                icmsCalculationBase
                              )}
                            />
                          );
                        }

                        if (tax.name === "icmsTax.deferredValue") {
                          if (
                            icmsTaxSituation ===
                            NFeItemIcmsSituacaoTributaria.TRIBUTACAO_MONOFASICA_SOBRE_COMBUSTIVEIS_COM_RECOLHIMENTO_DIFERIDO
                          ) {
                            return (
                              <tax.field
                                {...props}
                                key={`${tax.name}_${tax.label}`}
                                value={multiplyValues(
                                  icmsAliquot,
                                  icmsMonoCalculationBase
                                )}
                              />
                            );
                          }

                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={calculateTaxValue(
                                true,
                                icmsDeferralPercentage,
                                icmsOperationValue
                              )}
                            />
                          );
                        }

                        if (tax.name === "icmsTax.monoOperationValue") {
                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={multiplyValues(
                                icmsAliquot,
                                icmsMonoCalculationBase
                              )}
                            />
                          );
                        }

                        if (tax.name === "icmsTax.monoDeferredValue") {
                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={calculateTaxValue(
                                true,
                                icmsDeferralPercentage,
                                icmsMonoOperationValue
                              )}
                            />
                          );
                        }

                        if (tax.name === "icmsTax.monoWithheldValue") {
                          return (
                            <tax.field
                              {...props}
                              key={`${tax.name}_${tax.label}`}
                              value={multiplyValues(
                                icmsMonoWithheldCalculationBase,
                                icmsWithheldValue
                              )}
                            />
                          );
                        }

                        return (
                          <tax.field
                            key={`${tax.name}_${tax.label}`}
                            {...props}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>

              <h2 className="text-lg font-medium col-span-6">3.3. PIS</h2>
              <div className="col-span-6 grid grid-cols-6 gap-3">
                <FormFieldSimple
                  className="col-span-2"
                  control={form.control}
                  name="pisTax.taxSituation"
                  label="Situação Tributária"
                  render={({ field }) => (
                    <SelectPopover
                      items={NFeItemPisCofinsSituacaoTributariaList.map(
                        (item) => ({
                          value: item.value,
                          label: `${item.value} - ${item.label}`,
                          keywords: [item.label],
                        })
                      )}
                      value={field.value}
                      onValueChange={(value) => {
                        if (
                          !pisCofinsNoCalculateTaxSituation.includes(
                            value as NFeItemPisCofinsSituacaoTributaria
                          )
                        ) {
                          resetFields(pisFields, form);
                        }

                        field.onChange(value);
                      }}
                      modal={true}
                    />
                  )}
                />
                {!pisCofinsNoCalculateTaxSituation.includes(
                  pisTaxSituation as NFeItemPisCofinsSituacaoTributaria
                ) && (
                  <>
                    {!pisCofinsSpecialCalculation.includes(
                      pisTaxSituation as NFeItemPisCofinsSituacaoTributaria
                    ) && (
                      <FormFieldSimple
                        control={form.control}
                        name="pisTax.calculationPercentage"
                        label="Tipo de Cálculo"
                        render={({ field }) => (
                          <Select
                            value={field.value?.toString()}
                            onValueChange={(value) => {
                              resetFields(pisFields, form);
                              field.onChange(value === "true");
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Selecione" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="false">Em valor</SelectItem>
                              <SelectItem value="true">Porcentagem</SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    )}
                    {isPisCofinsSpecialCalculation(
                      pisCofinsOnlyCalculateTaxSituationByValue,
                      pisTaxSituation as NFeItemPisCofinsSituacaoTributaria,
                      !pisIsCalculationPercentage
                    ) && (
                      <FormFieldSimple
                        control={form.control}
                        name="pisTax.aliquotValue"
                        label="Valor unid. trib. PIS"
                        render={({ field }) => <CurrencyInput {...field} />}
                      />
                    )}
                    {isPisCofinsSpecialCalculation(
                      pisCofinsOnlyCalculateTaxSituationByPercentage,
                      pisTaxSituation as NFeItemPisCofinsSituacaoTributaria,
                      pisIsCalculationPercentage
                    ) && (
                      <>
                        <FormFieldSimple
                          control={form.control}
                          name="pisTax.calculationBase"
                          label="Base calc. PIS"
                          render={({ field }) => <CurrencyInput {...field} />}
                        />
                        <FormFieldSimple
                          control={form.control}
                          name="pisTax.percentageAliquot"
                          label="Alíquota PIS"
                          render={({ field }) => <PercentageInput {...field} />}
                        />
                      </>
                    )}
                    <FormFieldSimple
                      control={form.control}
                      name="pisTax.value"
                      label="Valor PIS"
                      render={({ field }) => (
                        <CurrencyInput
                          {...field}
                          value={calculateTaxValue(
                            pisIsCalculationPercentage,
                            pisPercentageAliquot,
                            pisBaseCalculation,
                            pisAliquotValue
                          )}
                          disabled
                        />
                      )}
                    />
                  </>
                )}
              </div>
              <div className="col-span-6 grid grid-cols-6 gap-3">
                <FormFieldSimple
                  className="col-span-2"
                  control={form.control}
                  name="pisTax.stCalculationType"
                  label="Tipo de Cálculo Subst. Trib."
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onValueChange={(value) => {
                        resetFields(pisStFields, form);
                        field.onChange(value);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Selecione" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="none">
                          Não desejo utilizar
                        </SelectItem>
                        <SelectItem value="percentage">Porcentagem</SelectItem>
                        <SelectItem value="value">Em valor</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
                {pisStCalculationType !== CalculationType.NONE && (
                  <>
                    {pisStCalculationType === CalculationType.VALUE && (
                      <FormFieldSimple
                        control={form.control}
                        name="pisTax.stAliquotValue"
                        label="Valor unid. trib. PIS ST"
                        render={({ field }) => <CurrencyInput {...field} />}
                      />
                    )}
                    {pisStCalculationType === CalculationType.PERCENTAGE && (
                      <>
                        <FormFieldSimple
                          control={form.control}
                          name="pisTax.stCalculationBase"
                          label="Base calc. PIS ST"
                          render={({ field }) => <CurrencyInput {...field} />}
                        />
                        <FormFieldSimple
                          control={form.control}
                          name="pisTax.stPercentageAliquot"
                          label="Alíquota PIS ST"
                          render={({ field }) => <PercentageInput {...field} />}
                        />
                      </>
                    )}
                    <FormFieldSimple
                      control={form.control}
                      name="pisTax.stValue"
                      label="Valor PIS ST"
                      render={({ field }) => (
                        <CurrencyInput
                          {...field}
                          value={calculateTaxValue(
                            pisStCalculationType === CalculationType.PERCENTAGE,
                            pisStPercentageAliquot,
                            pisStBaseCalculation,
                            pisStAliquotValue
                          )}
                          disabled
                        />
                      )}
                    />
                  </>
                )}
              </div>

              <h2 className="text-lg font-medium col-span-6">3.4. COFINS</h2>
              <div className="col-span-6 grid grid-cols-6 gap-3">
                <FormFieldSimple
                  className="col-span-2"
                  control={form.control}
                  name="cofinsTax.taxSituation"
                  label="Situação Tributária"
                  render={({ field }) => (
                    <SelectPopover
                      items={NFeItemPisCofinsSituacaoTributariaList.map(
                        (item) => ({
                          value: item.value,
                          label: `${item.value} - ${item.label}`,
                          keywords: [item.label],
                        })
                      )}
                      value={field.value}
                      onValueChange={(value) => {
                        if (
                          !pisCofinsNoCalculateTaxSituation.includes(
                            value as NFeItemPisCofinsSituacaoTributaria
                          )
                        ) {
                          const cofinsTax = form.getValues(
                            "cofinsTax.taxSituation"
                          );

                          form.resetField("cofinsTax");
                          form.setValue("cofinsTax.taxSituation", cofinsTax);
                        }

                        field.onChange(value);
                      }}
                      modal={true}
                    />
                  )}
                />
                {!pisCofinsNoCalculateTaxSituation.includes(
                  cofinsTaxSituation as NFeItemPisCofinsSituacaoTributaria
                ) && (
                  <>
                    {!pisCofinsSpecialCalculation.includes(
                      cofinsTaxSituation as NFeItemPisCofinsSituacaoTributaria
                    ) && (
                      <FormFieldSimple
                        control={form.control}
                        name="cofinsTax.calculationPercentage"
                        label="Tipo de Cálculo"
                        render={({ field }) => (
                          <Select
                            value={field.value?.toString()}
                            onValueChange={(value) => {
                              resetFields(cofinsFields, form);
                              field.onChange(value === "true");
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Selecione" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="false">Em valor</SelectItem>
                              <SelectItem value="true">Porcentagem</SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    )}
                    {isPisCofinsSpecialCalculation(
                      pisCofinsOnlyCalculateTaxSituationByValue,
                      cofinsTaxSituation as NFeItemPisCofinsSituacaoTributaria,
                      !cofinsIsCalculationPercentage
                    ) && (
                      <FormFieldSimple
                        control={form.control}
                        name="cofinsTax.aliquotValue"
                        label="Valor unid. trib. COFINS"
                        render={({ field }) => <CurrencyInput {...field} />}
                      />
                    )}
                    {isPisCofinsSpecialCalculation(
                      pisCofinsOnlyCalculateTaxSituationByPercentage,
                      cofinsTaxSituation as NFeItemPisCofinsSituacaoTributaria,
                      cofinsIsCalculationPercentage
                    ) && (
                      <>
                        <FormFieldSimple
                          control={form.control}
                          name="cofinsTax.calculationBase"
                          label="Base calc. COFINS"
                          render={({ field }) => <CurrencyInput {...field} />}
                        />
                        <FormFieldSimple
                          control={form.control}
                          name="cofinsTax.percentageAliquot"
                          label="Alíquota COFINS"
                          render={({ field }) => <PercentageInput {...field} />}
                        />
                      </>
                    )}
                    <FormFieldSimple
                      control={form.control}
                      name="cofinsTax.value"
                      label="Valor COFINS"
                      render={({ field }) => (
                        <CurrencyInput
                          {...field}
                          value={calculateTaxValue(
                            cofinsIsCalculationPercentage,
                            cofinsPercentageAliquot,
                            cofinsBaseCalculation,
                            cofinsAliquotValue
                          )}
                          disabled
                        />
                      )}
                    />
                  </>
                )}
              </div>
              <div className="col-span-6 grid grid-cols-6 gap-3">
                <FormFieldSimple
                  className="col-span-2"
                  control={form.control}
                  name="cofinsTax.stCalculationType"
                  label="Tipo de Cálculo Subst. Trib."
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onValueChange={(value) => {
                        resetFields(cofinsStFields, form);
                        field.onChange(value);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Selecione" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="none">
                          Não desejo utilizar
                        </SelectItem>
                        <SelectItem value="percentage">Porcentagem</SelectItem>
                        <SelectItem value="value">Em valor</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
                {cofinsStCalculationType !== CalculationType.NONE && (
                  <>
                    {cofinsStCalculationType === CalculationType.VALUE && (
                      <FormFieldSimple
                        control={form.control}
                        name="cofinsTax.stAliquotValue"
                        label="Valor unid. trib. COFINS ST"
                        render={({ field }) => <CurrencyInput {...field} />}
                      />
                    )}
                    {cofinsStCalculationType === CalculationType.PERCENTAGE && (
                      <>
                        <FormFieldSimple
                          control={form.control}
                          name="cofinsTax.stCalculationBase"
                          label="Base calc. COFINS ST"
                          render={({ field }) => <CurrencyInput {...field} />}
                        />
                        <FormFieldSimple
                          control={form.control}
                          name="cofinsTax.stPercentageAliquot"
                          label="Alíquota COFINS ST"
                          render={({ field }) => <PercentageInput {...field} />}
                        />
                      </>
                    )}
                    <FormFieldSimple
                      control={form.control}
                      name="cofinsTax.stValue"
                      label="Valor COFINS ST"
                      render={({ field }) => (
                        <CurrencyInput
                          {...field}
                          value={calculateTaxValue(
                            cofinsStCalculationType ===
                              CalculationType.PERCENTAGE,
                            cofinsStPercentageAliquot,
                            cofinsStBaseCalculation,
                            cofinsStAliquotValue
                          )}
                          disabled
                        />
                      )}
                    />
                  </>
                )}
              </div>

              <h1 className="col-span-6 text-xl font-medium">
                4. Outras Informações
              </h1>
              <FormFieldSimple
                className="col-span-6"
                control={form.control}
                name="observations"
                label="Observações"
                render={({ field }) => <Input {...field} />}
              />
            </div>
            <div className="sticky bottom-0 right-0 w-fit ml-auto bg-white border-sidebar/10 shadow-sm border p-2 rounded-md space-x-3 mt-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => popModal()}
              >
                Cancelar
              </Button>
              <PendingButton isPending={false} type="submit">
                {item ? "Salvar alterações" : "Adicionar"}
              </PendingButton>
            </div>
          </form>
        </Form>
      </div>
    </DialogContent>
  );
}

function IcmsBCMode<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  ...props
}: {
  label?: string;
} & Omit<ControllerProps<TFieldValues, TName>, "render">) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Modalidade BC"}
      render={({ field }) => (
        <Select onValueChange={field.onChange} value={field.value}>
          <SelectTrigger>
            <SelectValue placeholder="Selecione" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">0 - Margem valor adicionado</SelectItem>
            <SelectItem value="1">1 - Pauta (valor)</SelectItem>
            <SelectItem value="2">2 - Preço tabelado máx. (valor)</SelectItem>
            <SelectItem value="3">3 - Valor da operação (valor)</SelectItem>
          </SelectContent>
        </Select>
      )}
    />
  );
}

function IcmsCurrencyField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  disabled,
  ...props
}: { label?: string; disabled?: boolean } & Omit<
  ControllerProps<TFieldValues, TName>,
  "render"
>) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Base de Cálculo"}
      render={({ field }) => <CurrencyInput {...field} disabled={disabled} />}
    />
  );
}

function IcmsInputField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  disabled,
  ...props
}: { label?: string; disabled?: boolean } & Omit<
  ControllerProps<TFieldValues, TName>,
  "render"
>) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Quantidade tributada"}
      render={({ field }) => <Input {...field} disabled={disabled} />}
    />
  );
}

function IcmsPercentageField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  disabled,
  ...props
}: { label?: string; disabled?: boolean } & Omit<
  ControllerProps<TFieldValues, TName>,
  "render"
>) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Alíquota do ICMS"}
      render={({ field }) => <PercentageInput {...field} disabled={disabled} />}
    />
  );
}

function IcmsTaxValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  value,
  disabled,
  ...props
}: { label?: string; value?: string; disabled?: boolean } & Omit<
  ControllerProps<TFieldValues, TName>,
  "render"
>) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Valor do ICMS"}
      render={({ field }) => (
        <CurrencyInput {...field} value={value || ""} disabled={disabled} />
      )}
    />
  );
}

function IcmsStBCMode<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  disabled,
  ...props
}: { label?: string; disabled?: boolean } & Omit<
  ControllerProps<TFieldValues, TName>,
  "render"
>) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Modalidade BC ST"}
      render={({ field }) => (
        <Select
          onValueChange={field.onChange}
          value={field.value}
          disabled={disabled}
        >
          <SelectTrigger>
            <SelectValue placeholder="Selecione" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">
              0 - Preço tabelado ou máximo sugerido
            </SelectItem>
            <SelectItem value="1">1 - Lista negativa (valor)</SelectItem>
            <SelectItem value="2">2 - Lista positiva (valor)</SelectItem>
            <SelectItem value="3">3 - Lista neutra (valor)</SelectItem>
            <SelectItem value="4">4 - Margem de valor agregado (%)</SelectItem>
            <SelectItem value="5">5 - Pauta (valor)</SelectItem>
            <SelectItem value="6">6 - Valor da operação (valor)</SelectItem>
          </SelectContent>
        </Select>
      )}
    />
  );
}

function IcmsStExemptionReason<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  disabled,
  ...props
}: { label?: string; disabled?: boolean } & Omit<
  ControllerProps<TFieldValues, TName>,
  "render"
>) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Motivo desoneração ST"}
      render={({ field }) => (
        <Select
          onValueChange={field.onChange}
          value={field.value}
          disabled={disabled}
        >
          <SelectTrigger>
            <SelectValue placeholder="Selecione" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">Não desejo utilizar</SelectItem>
            <SelectItem value="3">3 - Produtor agropecuário</SelectItem>
            <SelectItem value="9">9 - Outros</SelectItem>
            <SelectItem value="12">
              12 - Orgão de fomento e desenvolv. agropecuário
            </SelectItem>
          </SelectContent>
        </Select>
      )}
    />
  );
}

function IcmsStReductionReason<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  disabled,
  ...props
}: { label?: string; disabled?: boolean } & Omit<
  ControllerProps<TFieldValues, TName>,
  "render"
>) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Motivo redução ad rem"}
      render={({ field }) => (
        <Select
          onValueChange={field.onChange}
          value={field.value}
          disabled={disabled}
        >
          <SelectTrigger>
            <SelectValue placeholder="Selecione" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">Não desejo utilizar</SelectItem>
            <SelectItem value="1">
              1 - Transporte coletivo de passageiros
            </SelectItem>
            <SelectItem value="9">9 - Outros</SelectItem>
          </SelectContent>
        </Select>
      )}
    />
  );
}

function IcmsExemptionReason<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  ...props
}: { label?: string } & Omit<ControllerProps<TFieldValues, TName>, "render">) {
  return (
    <FormFieldSimple
      {...props}
      label={label || "Motivo redução ad rem"}
      render={({ field }) => (
        <Select onValueChange={field.onChange} value={field.value}>
          <SelectTrigger>
            <SelectValue placeholder="Selecione" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">Não desejo utilizar</SelectItem>
            <SelectItem value="1">1 - Táxi</SelectItem>
            <SelectItem value="3">3 - Produtor agropecuário</SelectItem>
            <SelectItem value="4">4 - Frotista/locadora</SelectItem>
            <SelectItem value="5">5 - Diplomático/consular</SelectItem>
            <SelectItem value="6">
              6 - Util. e Motoc. Amazônia Ocidental e Livre Com.
            </SelectItem>
            <SelectItem value="7">7 - SUFRAMA</SelectItem>
            <SelectItem value="9">9 - Outros</SelectItem>
            <SelectItem value="10">10 - Deficiente condutor</SelectItem>
            <SelectItem value="11">11 - Deficiente não condutor</SelectItem>
            <SelectItem value="12">
              12 - Órgão de fomento e desenvolvimento agropecuário
            </SelectItem>
            <SelectItem value="16">16 - Olímpiadas Rio 2016</SelectItem>
            <SelectItem value="90">90 - Solicitado pelo fisco</SelectItem>
          </SelectContent>
        </Select>
      )}
    />
  );
}
