import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Info } from "lucide-react";
import { DataTable } from "@/components/table/data-table.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useFilterSearchParams } from "@/hooks/use-filter-search-params.ts";
import { parseAsString } from "nuqs";
import { usePaginationSearchParams } from "@/hooks/use-pagination-search-params.ts";
import { useOrderBySearchParams } from "@/hooks/use-order-by-search-params.ts";
import { useDebounce } from "@/hooks/use-debounce.tsx";
import { DataTableSearch } from "@/components/table/data-table-search.tsx";
import { DataTableToolbar } from "@/components/table/data-table-toolbar.tsx";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { type ApiResponse, fetchApi, isApiError } from "@/lib/api.ts";
import { getFilesTableColumns } from "@/pages/files/table/files-table-columns.tsx";
import { toast } from "sonner";

type FileRequestedResponse = {
  files: Array<{
    id: string;
    key: string;
    requestedBy: string;
    requestedByName: string;
    tags: string[];
    createdAt: string;
    updatedAt: string | null;
  }>;
  pagination: {
    total: number;
    totalPages: number;
  };
};

export function Files() {
  const queryClient = useQueryClient();

  const { filters, tableFilterState, handleTableFilterChange } =
    useFilterSearchParams({
      requestedBy: parseAsString,
    });

  const { paginationState, handleTablePaginationChange } =
    usePaginationSearchParams();

  const { sortState, tableSortingState, handleTableSortingChange } =
    useOrderBySearchParams({
      orderBy: "createdAt",
      orderDirection: "desc",
    });

  const debouncedFilters = useDebounce(filters, 500);
  const debouncedPaginationState = useDebounce(paginationState, 500);

  const { data } = useQuery({
    queryKey: ["/files", debouncedPaginationState, debouncedFilters, sortState],
    queryFn: () =>
      fetchApi
        .get<ApiResponse<FileRequestedResponse>>("/files", {
          params: {
            limit: debouncedPaginationState.pageSize,
            page: debouncedPaginationState.pageIndex + 1,
            ...debouncedFilters,
            ...sortState,
          },
        })
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
  });

  const { mutate } = useMutation({
    mutationKey: ["download-selected-file"],
    mutationFn: async (data: { id: string }) => {
      return fetchApi
        .post<
          ApiResponse<{
            url: string;
          }>
        >("/files", data)
        .then((res) => res.data.data);
    },
    onSuccess: async (data) => {
      window.open(data.url, "_blank");
      await queryClient.invalidateQueries({
        queryKey: ["/files"],
      });
      toast.success("Operação realizada com sucesso!");
    },
    onError: (error) => {
      if (isApiError(error)) {
        const errorMessage = error.response?.data.error.message;

        if (errorMessage === "FILE_NOT_FOUND") {
          return toast.error("Arquivo não encontrado.");
        }
      }

      toast.error("Ocorreu um erro ao baixar o arquivo.");
    },
  });

  const table = useReactTable({
    columns: getFilesTableColumns({
      onRequestDownload: (id: string) => mutate({ id }),
    }),
    data: data?.files ?? [],
    state: {
      pagination: paginationState,
      columnFilters: tableFilterState,
      sorting: tableSortingState,
    },
    onPaginationChange: handleTablePaginationChange,
    onColumnFiltersChange: handleTableFilterChange,
    onSortingChange: handleTableSortingChange,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    pageCount: data?.pagination?.totalPages ?? -1,
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Arquivos</PageTitle>
        <PageDescription>
          Visualize os arquivos solicitados pelo o seu sistema.
        </PageDescription>
      </PageHeader>
      <PageContent className="flex flex-col gap-4">
        <Card>
          <CardHeader className="flex flex-row items-center gap-4 pb-2">
            <Info className="h-5 w-5 text-primary" />
            <div>
              <CardTitle>Arquivos Requisitados pelo Sistema</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-muted-foreground">
              Esta página exibe todas as requisições de download feitas no
              sistema. Você pode baixar os arquivos clicando no botão de
              download correspondente. As requisições são ordenadas por data,
              com as mais recentes aparecendo primeiro.
            </p>
          </CardContent>
        </Card>
        <div className="flex flex-col gap-4">
          <DataTableToolbar table={table}>
            <DataTableSearch
              placeholder="Requisitado por"
              column={table.getColumn("requestedBy")!}
            />
          </DataTableToolbar>
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
