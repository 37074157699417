import Papa from "papaparse";
import chardet from "chardet";
export async function csvFileParser(file: File, options?: Papa.ParseConfig) {
  const uint8Array = await convertFileToUint8Array(file);

  return new Promise<Papa.ParseResult<Record<string, string>>>(
    (resolve, reject) => {
      const encoding = chardet.detect(uint8Array);

      if (!encoding) {
        return reject("Não foi possível detectar o encoding do arquivo.");
      }

      const decodedResult = new TextDecoder(encoding, {
        fatal: true,
      }).decode(uint8Array);

      Papa.parse<any>(decodedResult, {
        skipEmptyLines: "greedy",
        ...options,
        complete(results) {
          resolve(results);
        },
        error(error: unknown) {
          reject(error);
        },
      });
    }
  );
}

export function convertFileToUint8Array(file: File) {
  return new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      const uint8Array = new Uint8Array(arrayBuffer);

      resolve(uint8Array);
    };

    reader.onerror = () => reject(new Error("Error reading file"));
    reader.readAsArrayBuffer(file);
  });
}
