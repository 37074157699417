import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import { FilePlus } from "@phosphor-icons/react";
import { SentProductInvoices } from "@/pages/invoices/product-sent/product-sent.tsx";

export function ManageProductInvoices() {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Nota Fiscal de Produto (NF-e)</PageTitle>
        <PageDescription>
          Visualize as notas pendentes para a emissão, crie uma nova ou gerencie
          os produtos.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="flex items-center gap-3">
          <CardButton navigateTo={"/invoices/product/sent/new"}>
            <CardButtonContent>
              <CardButtonIcon>
                <FilePlus />
              </CardButtonIcon>
              <CardButtonDescription>Criar nova NF-e</CardButtonDescription>
            </CardButtonContent>
          </CardButton>
          {/*<CardButton>*/}
          {/*  <CardButtonContent>*/}
          {/*    <CardButtonIcon>*/}
          {/*      <Package />*/}
          {/*    </CardButtonIcon>*/}
          {/*    <CardButtonDescription>Gerenciar produtos</CardButtonDescription>*/}
          {/*  </CardButtonContent>*/}
          {/*</CardButton>*/}
        </div>
        <div className="mt-4">
          <SentProductInvoices />
        </div>
      </PageContent>
    </PageContainer>
  );
}
