import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import { useFormContext, useWatch } from "react-hook-form";
import type { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import {
  CurrencyInput,
  PercentageInput,
} from "@/components/validators/currency-input.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import { useExternalCity } from "@/hooks/use-external-city.ts";
import { Autocomplete } from "@/components/autocomplete.tsx";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { Input } from "@/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

export function ServiceForm({
  companies,
}: {
  companies?: CompanyWithAddress[];
}) {
  const form = useFormContext<z.infer<typeof serviceInvoiceSchema>>();

  const companyCityCode = companies?.find(
    (company) => company.document === form.getValues("issuer.document")
  )?.address.cityCode;

  const { data: externalCity } = useExternalCity({
    cityCode: companyCityCode ?? "",
    includeServiceList: true,
  });
  const serviceList = () => {
    if (!externalCity) {
      return [];
    }

    return externalCity.serviceList.map((service) => ({
      label: `(${service.code}) ${service.description}`,
      value: service.code,
    }));
  };

  const [value, deductions, aliquot] = useWatch({
    control: form.control,
    name: ["service.value", "service.deductions", "service.aliquot"],
  });

  const baseValue = (Number(value || "0") - Number(deductions || "0")).toFixed(
    2
  );
  const issValue = (Number(baseValue) * (Number(aliquot || "0") / 100)).toFixed(
    2
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Discriminação dos Serviços</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"flex flex-col lg:grid xl:grid-cols-4 gap-2"}>
          <FormField
            control={form.control}
            name="service.item"
            render={({ field }) => (
              <FormItem className="col-span-full">
                <FormLabel required className="flex items-center gap-x-1">
                  <span>Serviço (item de lei)</span>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger type="button">
                      <InfoCircledIcon className="h-4 w-4 opacity-50" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-72 text-muted-foreground">
                      Caso o serviço desejado não esteja listado ou a lista está
                      vazia, informe o código do serviço no campo abaixo,
                      normalmente de acordo com a Lei Complementar 116/2003.
                    </TooltipContent>
                  </Tooltip>
                </FormLabel>
                <Autocomplete
                  options={serviceList()}
                  onValueChange={(option) => {
                    console.log(option.value);
                    field.onChange(option.value);
                  }}
                  emptyMessage={
                    "Serviço não encontrado. Iremos levar em consideração o que você informar."
                  }
                  setInput={true}
                />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="service.description"
            render={({ field }) => (
              <FormItem className="col-span-full">
                <FormLabel required>Descrição do serviço</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="service.value"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Valor do serviço</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.deductions"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Deduções</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.baseValue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor base de cálculo</FormLabel>
                <FormControl>
                  <CurrencyInput
                    {...field}
                    disabled
                    value={Number(baseValue) < 0 ? "0" : baseValue}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.municipalTaxCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Código tributário do serviço</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="service.issRetained"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Retenção do ISS na Fonte?</FormLabel>
                <Select
                  onValueChange={(value) => field.onChange(value === "yes")}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione a opção" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="yes">Sim</SelectItem>
                    <SelectItem value="no">Não</SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.aliquot"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Aliquota</FormLabel>
                <FormControl>
                  <PercentageInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.issValue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor do ISS</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} value={issValue} disabled />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.cnae"
            render={({ field }) => (
              <FormItem>
                <FormLabel>CNAE</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.conditionalDiscount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor desconto condicional</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.unconditionalDiscount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Valor desconto incondicional</FormLabel>
                <FormControl>
                  <CurrencyInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
