import {
  PageContainer,
  PageContent,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { DashboardStats } from "@/pages/overview/dashboard-stats.tsx";
import { DashboardRecentInvoices } from "@/pages/overview/dashboard-recent-invoices.tsx";

function Dashboard() {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Dashboard</PageTitle>
      </PageHeader>
      <PageContent className="flex flex-col gap-4">
        <DashboardStats />
        <DashboardRecentInvoices />
      </PageContent>
    </PageContainer>
  );
}

export { Dashboard };
