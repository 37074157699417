import { cn } from "@/lib/utils.ts";
import { FloppyDisk, PaperPlaneTilt, Spinner } from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { Separator } from "@/components/ui/separator.tsx";

interface ComponentProps {
  className?: string;
  isNewDraft?: boolean;
  isPending?: boolean;
  canSend?: boolean;
}

export function CreateProductInvoiceFloatingOptions({
  className,
  isNewDraft,
  isPending,
  canSend,
}: ComponentProps) {
  return (
    <div
      className={cn(
        className,
        "border border-sidebar rounded-xl px-3 py-1 bg-white shadow-md shadow-sidebar/40"
      )}
    >
      <div className="flex items-center gap-2 text-white">
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <button
              type="button"
              className="bg-gray-800 p-1 rounded-sm hover:cursor-pointer hover:bg-gray-700 transition-all duration-300 ease-in-out hover:scale-110 disabled:hover:cursor-not-allowed disabled:bg-gray-800/60"
              disabled
            >
              <FloppyDisk className="size-5" />
            </button>
          </TooltipTrigger>
          <TooltipContent className="text-xs" sideOffset={10}>
            {isNewDraft
              ? "A opção de salvar está desabilitada."
              : "A opção de salvar está desabilitada."}
          </TooltipContent>
        </Tooltip>
        <Separator orientation="vertical" className="h-5 bg-black/90" />
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <button
              type="submit"
              className={cn(
                "bg-primary p-1 rounded-sm hover:bg-primary/90 transition-all duration-300 ease-in-out hover:scale-110 disabled:hover:cursor-not-allowed disabled:bg-primary/60",
                isPending && "cursor-not-allowed opacity-50",
                !isPending && "hover:cursor-pointer"
              )}
              disabled={isPending || !canSend}
            >
              {isPending ? (
                <Spinner className="size-5 animate-spin" />
              ) : (
                <PaperPlaneTilt className="size-5" />
              )}
            </button>
          </TooltipTrigger>
          <TooltipContent className="text-xs" sideOffset={10}>
            Transmitir para a Sefaz{" "}
            {canSend ? "" : "(não é possível para o status atual da NFe)"}
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
}
