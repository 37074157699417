import { format } from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { forwardRef } from "react";
import { CalendarIcon } from "lucide-react";
import type { DateRange } from "react-day-picker";

export const DatePicker = forwardRef<
  HTMLDivElement,
  {
    date?: Date;
    setDate: (date?: Date) => void;
  }
>(function DatePickerCmp({ date, setDate }, ref) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "flex w-full justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PP") : <span>Escolha uma data</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" ref={ref}>
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
});

export const DatePickerRange = forwardRef<
  HTMLDivElement,
  {
    value: DateRange;
    setDate: (date?: DateRange) => void;
  }
>(function DatePickerCmp({ value, setDate }, ref) {
  const hasValue = !!(value.from || value.to);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "flex justify-start text-center font-normal w-fit",
            !hasValue && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {!hasValue && <span>Filtrar por data</span>}

          {value?.from && format(value.from, "P")}
          {hasValue && <span className="mx-2 text-muted-foreground">-</span>}
          {value?.to && format(value.to, "P")}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-2" ref={ref}>
        <Calendar
          mode="range"
          selected={value}
          onSelect={setDate}
          initialFocus
        />
        <Button
          variant={"outline"}
          className={cn(
            "flex justify-start text-center font-normal w-fit",
            !value && "text-muted-foreground"
          )}
          onClick={() => {
            setDate(undefined);
          }}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          Limpar
        </Button>
      </PopoverContent>
    </Popover>
  );
});
