import { useQuery } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { AlertCircle, CheckCircle, Clock, FileText, X } from "lucide-react";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.tsx";
import { Badge } from "@/components/ui/badge.tsx";

import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import type { FC } from "react";
import { cn } from "@/lib/utils.ts";
import { useNavigate } from "react-router-dom";
import { pushModal } from "@/modals";

type RecentInvoice = {
  id: string;
  invoiceType: string;
  status: string;
  type: string;
  createdAt: string;
  company: {
    id: string;
    name: string;
  };
  vendor: {
    id: string;
    name: string;
  };
};

export function DashboardRecentInvoices() {
  const { data: recentDocuments } = useQuery<{
    invoices: RecentInvoice[];
  }>({
    queryKey: ["invoices/recent"],
  });

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="flex items-center gap-2">
          <div className="flex h-6 w-6 items-center justify-center rounded-full bg-amber-100">
            <Clock className="h-3.5 w-3.5 text-amber-600" />
          </div>
          <CardTitle className="text-md font-semibold">
            Documentos Recentes
          </CardTitle>
        </div>
        <p className="text-xs text-muted-foreground mt-1">
          Documentos que foram emitidos ou recebidos recentemente na plataforma
        </p>
      </CardHeader>
      <CardContent>
        <ScrollArea>
          <div className="flex gap-2 pb-3">
            {!recentDocuments?.invoices.length && (
              <div className="flex flex-col items-center justify-center w-full mt-3">
                <div className="flex size-10 items-center justify-center rounded-full bg-gray-100 mb-2">
                  <FileText className="size-5 text-gray-400" />
                </div>

                <p className="text-xs text-gray-500 text-center max-w-md">
                  Não encontramos documentos recentes para exibir. Novos
                  documentos aparecerão aqui quando forem emitidos ou recebidos.
                </p>
              </div>
            )}
            {recentDocuments?.invoices.map((invoice) => (
              <InvoiceCard
                key={invoice.id}
                invoice={invoice}
                onClick={(invoice) => {
                  const modalType = (() => {
                    if (invoice.type === "sent") {
                      if (invoice.invoiceType === "service") {
                        return "ViewSentServiceInvoice";
                      }
                    }

                    if (invoice.type === "received") {
                      if (invoice.invoiceType === "product") {
                        return "ViewReceivedProductInvoice";
                      }
                    }
                  })();

                  if (modalType) {
                    pushModal(modalType, {
                      id: invoice.id,
                    });
                  }

                  if (
                    invoice.type === "sent" &&
                    invoice.invoiceType === "product"
                  ) {
                    navigate(
                      `/invoices/product/sent/new?invoiceId=${invoice.id}`
                    );
                  }
                }}
              />
            ))}
          </div>
          <ScrollBar orientation={"horizontal"} />
        </ScrollArea>
      </CardContent>
    </Card>
  );
}

interface InvoiceCardProps {
  invoice: RecentInvoice;
  onClick?: (invoice: RecentInvoice) => void;
}

const getStatusIcon = (status: string) => {
  switch (status.toLowerCase()) {
    case "authorized":
    case "autorizada":
      return <CheckCircle className="h-3.5 w-3.5 text-emerald-500" />;
    case "success":
    case "concluída":
      return <CheckCircle className="h-3.5 w-3.5 text-emerald-500" />;
    case "pending":
    case "pendente":
      return <Clock className="h-3.5 w-3.5 text-amber-500" />;
    case "processing":
    case "processando":
      return <Clock className="h-3.5 w-3.5 text-blue-500" />;
    case "cancelled":
    case "cancelada":
      return <X className="h-3.5 w-3.5 text-gray-500" />;
    case "error":
    case "erro":
      return <AlertCircle className="h-3.5 w-3.5 text-red-500" />;
    default:
      return <FileText className="h-3.5 w-3.5 text-gray-500" />;
  }
};

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "authorized":
    case "autorizada":
      return "bg-emerald-50 text-emerald-700 border-emerald-200";
    case "success":
    case "concluída":
      return "bg-emerald-50 text-emerald-700 border-emerald-200";
    case "pending":
    case "pendente":
      return "bg-amber-50 text-amber-700 border-amber-200";
    case "processing":
    case "processando":
      return "bg-blue-50 text-blue-700 border-blue-200";
    case "cancelled":
    case "cancelada":
      return "bg-gray-50 text-gray-700 border-gray-200";
    case "error":
    case "erro":
      return "bg-red-50 text-red-700 border-red-200";
    default:
      return "bg-gray-50 text-gray-700 border-gray-200";
  }
};

const formatInvoiceType = (type: string) => {
  switch (type.toLowerCase()) {
    case "product":
      return "Produto";
    case "service":
      return "Serviço";
    default:
      return type;
  }
};

const formatStatus = (status: string, type: string) => {
  let translatedStatus = status;
  switch (status.toLowerCase()) {
    case "authorized":
      translatedStatus = "Autorizada";
      break;
    case "success":
      translatedStatus = "Concluída";
      break;
    case "cancelled":
      translatedStatus = "Cancelada";
      break;
    case "error":
      translatedStatus = "Erro";
      break;
    case "pending":
      translatedStatus = "Pendente";
      break;
    case "processing":
      translatedStatus = "Processando";
      break;
  }

  if (["authorized", "success"].includes(status.toLowerCase())) {
    return type.toLowerCase() === "sent" ? "Emitida" : "Recebida";
  }

  return translatedStatus;
};

const InvoiceCard: FC<InvoiceCardProps> = ({ invoice, onClick }) => {
  const formattedDate = formatDistanceToNow(new Date(invoice.createdAt), {
    addSuffix: true,
    locale: ptBR,
  });

  const displayStatus = formatStatus(invoice.status, invoice.type);
  const displayType =
    invoice.type.toLowerCase() === "sent" ? "Enviado" : "Recebido";

  return (
    <Card
      className="relative overflow-hidden border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200 w-80 hover:cursor-pointer"
      onClick={() => onClick?.(invoice)}
    >
      <CardContent className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Badge
              variant="outline"
              className="px-2 py-0.5 text-xs font-medium"
            >
              {formatInvoiceType(invoice.invoiceType)}
            </Badge>
            <Badge
              variant="outline"
              className={cn(
                "px-2 py-0.5 text-xs font-medium flex items-center gap-1",
                getStatusColor(invoice.status)
              )}
            >
              {getStatusIcon(invoice.status)}
              <span>{displayStatus}</span>
            </Badge>
          </div>
        </div>

        <div className="mt-3 space-y-1.5">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-gray-900 truncate">
              {invoice.company.name}
            </h3>
            <span className="text-xs text-gray-500">{formattedDate}</span>
          </div>

          <div className="flex items-center gap-1">
            <span className="text-xs text-gray-500">
              {invoice.type === "sent" ? "Destinatário" : "Remetente"}:
            </span>
            <span className="text-xs font-medium text-gray-700 truncate">
              {invoice.vendor.name}
            </span>
          </div>

          <div className="flex items-center gap-1">
            <span className="text-xs text-gray-500">Tipo:</span>
            <span className="text-xs font-medium text-gray-700">
              {displayType}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
