import { type ReactNode, useCallback, useMemo, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils.ts";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";

interface SelectItem {
  value: string;
  label: string | ReactNode;
  disabled?: boolean;
  keywords?: string[];
}

interface SelectItemGroup {
  heading: string;
  items: SelectItem[];
}

type SelectItems = (SelectItem | SelectItemGroup)[];

interface SelectPopoverProps {
  items: SelectItems;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  modal?: boolean;
  className?: string;
  loading?: boolean;
  loadingText?: string;
  selectPlaceholder?: string;
}

export function SelectPopover({
  items,
  placeholder = "Procurar...",
  value,
  defaultValue,
  onValueChange,
  disabled = false,
  modal = false,
  className,
  loading = false,
  loadingText = "Carregando...",
  selectPlaceholder = "Selecione...",
}: SelectPopoverProps) {
  const [open, setOpen] = useState(false);

  const isGroup = useCallback(
    (item: SelectItem | SelectItemGroup): item is SelectItemGroup =>
      "heading" in item && Array.isArray(item.items),
    []
  );

  const selectedValue = value || defaultValue;
  const selectedLabel = useMemo(() => {
    for (const item of items) {
      if (!isGroup(item) && item.value === selectedValue) {
        return item.label;
      }

      if (isGroup(item)) {
        const foundItem = item.items.find(
          (item) => item.value === selectedValue
        );
        if (foundItem) {
          return foundItem.label;
        }
      }
    }
    return null;
  }, [items, selectedValue, isGroup]);

  const handleSelect = useCallback(
    (selected: string) => {
      onValueChange(selected);
      setOpen(false);
    },
    [onValueChange]
  );

  const nonGroupItems = items.filter((item) => !isGroup(item)) as SelectItem[];

  const InternalCommandItem = ({ item }: { item: SelectItem }) => {
    const isSelected = selectedValue === item.value;
    return (
      <CommandItem
        key={item.value}
        value={[item.value, item.keywords].join("_")}
        disabled={item.disabled}
        onSelect={() => handleSelect(item.value)}
        className={cn(
          "flex items-center justify-between space-x-3",
          isSelected && "font-medium"
        )}
      >
        <Check
          className={cn("size-4", isSelected ? "opacity-100" : "opacity-0")}
        />
        <div className="flex-1">
          {typeof item.label === "string" ? (
            <span>{item.label}</span>
          ) : (
            item.label
          )}
        </div>
      </CommandItem>
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen} modal={modal}>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-label="Selecione uma opção"
          className={cn("w-full h-9 border-input justify-between", className)}
          disabled={disabled || loading}
        >
          <span className="truncate">
            {loading ? loadingText : <>{selectedLabel || selectPlaceholder}</>}
          </span>
          <div className="opacity-50">
            <ChevronsUpDown className="size-4" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="min-w-[200px] max-w-[500px] w-full p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandList>
            <CommandEmpty>Nenhum resultado.</CommandEmpty>
            {items.map((item, index) =>
              isGroup(item) ? (
                <CommandGroup key={index} heading={item.heading}>
                  {item.items.map((subItem) => (
                    <InternalCommandItem key={subItem.value} item={subItem} />
                  ))}
                </CommandGroup>
              ) : null
            )}
            {nonGroupItems.length > 0 && (
              <CommandGroup heading={items.some(isGroup) ? "Outros" : ""}>
                {nonGroupItems.map((item) => (
                  <InternalCommandItem key={item.value} item={item} />
                ))}
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
