import {
  InformativeCard,
  InformativeCardBadge,
  InformativeCardContent,
  InformativeCardIcon,
  InformativeCardTitle,
  InformativeCardValue,
} from "@/components/informative-card.tsx";
import { FileArrowDown, FileArrowUp, FileX } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "@/components/ui/skeleton.tsx";

type Stats = {
  month: number;
  received: number;
  sent: number;
  total: number;
  success: number;
  cancelled: number;
};

function calculateGrowth(current: number, last: number) {
  return ((current - last) / (last || 1)) * 100;
}

function getLastMonth() {
  return new Date().getMonth();
}

function getCurrentMonth() {
  return new Date().getMonth() + 1;
}

function getSums(key: keyof Stats, product: Stats[], service: Stats[]) {
  const month = getCurrentMonth();
  const lastMonth = getLastMonth();

  const current =
    product
      .filter((stat) => stat.month === month)
      .reduce((sum, stat) => sum + stat[key], 0) +
    service
      .filter((stat) => stat.month === month)
      .reduce((sum, stat) => sum + stat[key], 0);

  const lastMonthProduct =
    product.find((stat) => stat.month === lastMonth)?.[key] ?? 0;
  const lastMonthService =
    service.find((stat) => stat.month === lastMonth)?.[key] ?? 0;
  const lastMonthTotal = lastMonthProduct + lastMonthService;

  const growth = calculateGrowth(current, lastMonthTotal);

  return {
    current,
    growth,
  };
}

export function DashboardStats() {
  const { data: stats, isLoading } = useQuery<{
    product: Stats[];
    service: Stats[];
  }>({
    queryKey: ["stats"],
  });

  if (isLoading || !stats) {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
        <Skeleton className="w-72 h-20" />
        <Skeleton className="w-72 h-20" />
        <Skeleton className="w-72 h-20" />
      </div>
    );
  }

  const { current: documentsIssued, growth: issuedGrowth } = getSums(
    "sent",
    stats?.product,
    stats?.service
  );

  const { current: documentsReceived, growth: receivedGrowth } = getSums(
    "received",
    stats?.product,
    stats?.service
  );

  const { current: documentsCancelled, growth: cancelledGrowth } = getSums(
    "cancelled",
    stats?.product,
    stats?.service
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6 mb-6">
      <InformativeCard>
        <InformativeCardIcon>
          <FileArrowDown size={24} />
        </InformativeCardIcon>
        <InformativeCardContent>
          <InformativeCardTitle>Notas Recebidas</InformativeCardTitle>
          <InformativeCardValue loading={isLoading}>
            {documentsReceived}
          </InformativeCardValue>
        </InformativeCardContent>
        <InformativeCardBadge percentage={receivedGrowth} />
      </InformativeCard>
      <InformativeCard>
        <InformativeCardIcon>
          <FileArrowUp size={24} />
        </InformativeCardIcon>
        <InformativeCardContent>
          <InformativeCardTitle>Notas Emitidas</InformativeCardTitle>
          <InformativeCardValue loading={isLoading}>
            {documentsIssued}
          </InformativeCardValue>
        </InformativeCardContent>
        <InformativeCardBadge percentage={issuedGrowth} reverse={true} />
      </InformativeCard>
      <InformativeCard>
        <InformativeCardIcon>
          <FileX size={24} />
        </InformativeCardIcon>
        <InformativeCardContent>
          <InformativeCardTitle>Notas Canceladas</InformativeCardTitle>
          <InformativeCardValue loading={isLoading}>
            {documentsCancelled}
          </InformativeCardValue>
        </InformativeCardContent>
        <InformativeCardBadge percentage={cancelledGrowth} reverse={false} />
      </InformativeCard>
    </div>
  );
}
