import { BatchesList } from "@/pages/invoices/batches/components/batch-list.tsx";
import { Check, Clock, Files, Hourglass } from "lucide-react";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";

export function Batches() {
  return (
    <div className={"flex flex-col gap-4"}>
      <div className="flex gap-3">
        <CardButton
          navigateTo="/invoices/batches/import/service"
          className="w-fit"
        >
          <CardButtonContent>
            <CardButtonIcon>
              <Files />
            </CardButtonIcon>
            <CardButtonDescription>Criar lote (NFS-e)</CardButtonDescription>
          </CardButtonContent>
        </CardButton>
        <CardButton
          navigateTo="/invoices/batches/import/product"
          className="w-fit"
          disabled
        >
          <CardButtonContent>
            <CardButtonIcon>
              <Files />
            </CardButtonIcon>
            <CardButtonDescription>Criar lote (NF-e)</CardButtonDescription>
          </CardButtonContent>
        </CardButton>
      </div>
      <BatchesList
        header={
          <>
            <p className={"text-xl font-medium tracking-tight"}>Na fila</p>
            <Hourglass className={"size-5 text-primary"} />
          </>
        }
        batchType={"queued"}
      />
      <BatchesList
        header={
          <>
            <p className={"text-xl font-medium tracking-tight"}>
              Em processamento
            </p>
            <Clock className={"size-5 text-orange-500"} />
          </>
        }
        batchType={"processing"}
      />
      <BatchesList
        header={
          <>
            <p className={"text-xl font-medium tracking-tight"}>Concluídos</p>
            <Check className={"size-5 text-green-500"} />
          </>
        }
        batchType={"finished"}
      />
    </div>
  );
}
