import type { NewProductInvoice } from "@shared/schemas/invoices/nfe/new_product.ts";
import { useFormContext } from "react-hook-form";
import { SelectPopover } from "@/components/select-popover.tsx";
import { useCompanies } from "@/hooks/use-companies.ts";
import { formatCNPJ } from "@shared/format.ts";
import { FormFieldSimple } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import type { CompanyWithCompleteAddress } from "@shared/schemas/company.ts";
import { RegimeTributary } from "@shared/schemas/company.ts";
import { toast } from "sonner";
import { Card } from "@/components/ui/card.tsx";
import { WarningCircle } from "@phosphor-icons/react";

type Cities = {
  cities: Array<{ name: string; code: string }>;
};

export function NewInvoiceProductIssuer() {
  const form = useFormContext<NewProductInvoice>();

  const { data: dataStates, isLoading: isLoadingStates } = useQuery<{
    states: Array<{ name: string; code: string }>;
  }>({
    queryKey: ["/locations/states"],
    refetchOnMount: false,
  });

  const state = form.watch("issuer.address.state");

  const { data: stateCities, isLoading: isLoadingCities } = useQuery({
    queryKey: ["/external/state/cities", state],
    queryFn: () =>
      fetchApi
        .get(`/locations/states/${state}/cities`)
        .then((res) => res.data.data as Cities),
    enabled: !!state,
    staleTime: Infinity,
  });

  const { data: dataCompanies } = useCompanies();

  const { mutateAsync } = useMutation({
    mutationKey: ["/companies"],
    mutationFn: async (company: string) => {
      const res = await fetchApi.get<{ data: CompanyWithCompleteAddress }>(
        `/companies/${company}`
      );
      return res.data.data;
    },
    onSuccess: () => {
      toast.success("Empresa emitente selecionada com sucesso!", {
        position: "top-center",
      });
    },
  });

  const selectCompany = async (document: string) => {
    const company = await mutateAsync(document);

    form.setValue("issuer.name", company.name);
    form.setValue("issuer.stateRegistration", company.stateRegistration ?? "");
    form.setValue("issuer.address.street", company.address.street);
    form.setValue("issuer.address.number", company.address.number);
    form.setValue("issuer.address.district", company.address.district);
    form.setValue("issuer.address.city", company.address.city);
    form.setValue("issuer.address.state", company.address.stateCode);
    form.setValue("issuer.address.zipCode", company.address.zipCode);
    form.setValue("issuer.address.cityCode", company.address.cityCode);
    form.setValue("issuer.tributaryRegime", company.tributaryRegime);

    form.clearErrors("issuer");
  };

  const isCompanySelected = !!form.watch("issuer.document");

  return (
    <div className="grid grid-cols-6 gap-3">
      <FormFieldSimple
        className="col-span-4"
        control={form.control}
        name="issuer.document"
        label="Empresa"
        required
        render={({ field }) => (
          <SelectPopover
            items={
              dataCompanies?.companies.map((company) => ({
                value: company.document,
                label: `CNPJ: ${formatCNPJ(company.document)} - ${company.name}`,
                keywords: [company.name],
              })) ?? []
            }
            onValueChange={async (value) => {
              if (field.value === value) {
                return;
              }

              field.onChange(value);
              await selectCompany(value);
            }}
            value={field.value}
          />
        )}
      />

      <FormFieldSimple
        control={form.control}
        name="issuer.stateRegistration"
        label="Inscrição Estadual"
        required
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 123456789"
            disabled={isCompanySelected}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="issuer.tributaryRegime"
        label="Regime Tributário"
        required
        render={({ field }) => (
          <Select
            value={field.value.toString()}
            onValueChange={(value) => field.onChange(Number.parseInt(value))}
            disabled={isCompanySelected}
          >
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={RegimeTributary.SIMPLES_NACIONAL.toString()}>
                1 - Simples Nacional
              </SelectItem>
              <SelectItem
                value={RegimeTributary.SIMPLES_NACIONAL_EXCESSO.toString()}
              >
                2 - Simples Nacional Excesso
              </SelectItem>
              <SelectItem value={RegimeTributary.REGIME_NORMAL.toString()}>
                3 - Regime Normal
              </SelectItem>
              <SelectItem
                value={RegimeTributary.SIMPLES_NACIONAL_MEI.toString()}
              >
                4 - Simples Nacional MEI
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <FormFieldSimple
        className="col-span-3"
        control={form.control}
        name="issuer.address.street"
        label="Logradouro"
        required
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: Rua Exemplo"
            disabled={isCompanySelected}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="issuer.address.complement"
        label="Complemento"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: Apto 123"
            disabled={isCompanySelected}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="issuer.address.number"
        label="Número"
        required
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 123"
            disabled={isCompanySelected}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="issuer.address.district"
        label="Bairro"
        required
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: Vila do Sapo"
            disabled={isCompanySelected}
          />
        )}
      />

      <FormFieldSimple
        control={form.control}
        name="issuer.address.state"
        label="UF"
        required
        render={({ field }) => (
          <SelectPopover
            items={
              dataStates?.states.map((state) => ({
                value: state.code,
                label: state.name,
                keywords: [state.name],
              })) ?? []
            }
            value={field.value}
            onValueChange={(value) => {
              form.resetField("issuer.address.city");
              form.resetField("issuer.address.cityCode");
              field.onChange(value);
            }}
            loading={isLoadingStates}
            disabled={isCompanySelected}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="issuer.address.cityCode"
        label="Município"
        required
        render={({ field }) => (
          <SelectPopover
            items={
              stateCities?.cities.map((city) => ({
                value: city.code,
                label: city.name,
                keywords: [city.name],
              })) ?? []
            }
            value={field.value}
            onValueChange={field.onChange}
            loading={isLoadingCities}
            disabled={isCompanySelected}
          />
        )}
      />

      <FormFieldSimple
        control={form.control}
        name="issuer.address.zipCode"
        label="CEP"
        required
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 123456789"
            disabled={isCompanySelected}
          />
        )}
      />
      {isCompanySelected && (
        <div className="col-span-6">
          <Card className="border-yellow-200 bg-yellow-100 text-yellow-700 flex items-center p-2 gap-3">
            <WarningCircle className="size-6" />
            <div className="flex flex-col">
              <span className="font-semibold">Atenção!</span>
              <span>
                Os campos da empresa emissora são imutáveis. Para alterá-los,
                acesse a página de Dados da Empresa no portal.
              </span>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}
