import { DataTable } from "@/components/table/data-table.tsx";
import { serviceSentColumns } from "./service-sent-columns.tsx";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { DataTableToolbar } from "@/components/table/data-table-toolbar.tsx";
import { DataTableSearch } from "@/components/table/data-table-search.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { type ApiResponse, fetchApi, isApiError } from "@/lib/api.ts";
import { useFilterSearchParams } from "@/hooks/use-filter-search-params.ts";
import { parseAsArrayOf, parseAsIsoDate, parseAsString } from "nuqs";
import { useDebounce } from "@/hooks/use-debounce.tsx";
import { usePaginationSearchParams } from "@/hooks/use-pagination-search-params.ts";
import {
  getCoreRowModel,
  type RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import { useOrderBySearchParams } from "@/hooks/use-order-by-search-params.ts";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import { FilePlus } from "@phosphor-icons/react";
import { DataTableFacetedFilter } from "@/components/table/data-table-faceted-filter.tsx";
import { DatePickerRange } from "@/components/ui/date-picker.tsx";
import { useState } from "react";

import { DataTableExportDropdown } from "@/components/data-table/data-table-export-dropdown.tsx";
import { toast } from "sonner";

type SentServiceInvoiceResponse = ApiResponse<{
  invoices: Array<{
    id: string;
    status: string;
    issuer: {
      document: string;
      name: string;
    };
    recipient: {
      document: string;
      name: string;
    };
    invoice: {
      value: string;
      issuedAt: string;
      number: string;
    };
  }>;
  pagination: {
    total: number;
    totalPages: number;
  };
}>;

export function SentServiceInvoices() {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [allInvoices, setAllInvoices] = useState(false);

  const { filters, setFilters, tableFilterState, handleTableFilterChange } =
    useFilterSearchParams({
      status: parseAsArrayOf(parseAsString).withDefault([]),
      issuer: parseAsString,
      recipient: parseAsString,
      dateFrom: parseAsIsoDate,
      dateTo: parseAsIsoDate,
    });

  const { paginationState, handleTablePaginationChange } =
    usePaginationSearchParams();

  const { sortState, tableSortingState, handleTableSortingChange } =
    useOrderBySearchParams({
      orderBy: "issuedAt",
      orderDirection: "desc",
    });

  const debouncedFilters = useDebounce(filters, 500);
  const debouncedPaginationState = useDebounce(paginationState, 500);

  const { data: response } = useQuery({
    queryKey: [
      "/invoices/services/sent",
      debouncedPaginationState,
      debouncedFilters,
      sortState,
    ],
    queryFn: () =>
      fetchApi
        .get<SentServiceInvoiceResponse>("/invoices/services/sent", {
          params: {
            limit: debouncedPaginationState.pageSize,
            page: debouncedPaginationState.pageIndex + 1,
            ...debouncedFilters,
            ...sortState,
          },
        })
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
    refetchInterval: 10000,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ["download-sent-service-invoices"],
    mutationFn: async (data: {
      type: "pdf" | "xml";
      invoices: string[];
      allInvoices: boolean;
    }) => {
      return fetchApi
        .post<
          ApiResponse<{
            url: string;
          }>
        >("/invoices/services/sent/download", data)
        .then((res) => res.data.data);
    },
    onSuccess: (data) => {
      console.log(data);
      toast.success(
        "Arquivo solicitado. Você pode acompanhar e baixar o arquivo no menu Sistema > Arquivos."
      );
    },
    onError: (error) => {
      if (isApiError(error)) {
        const errorMessage = error.response?.data.error.message;

        if (errorMessage === "NO_INVOICES_FOUND") {
          return toast.error(
            "Nenhuma nota foi encontrada para o tipo selecionado."
          );
        }

        if (errorMessage === "INVOICES_NOT_FROM_SAME_TENANT") {
          return toast.error(
            "Alguma coisa deu errado! Algumas notas não pertencem a você. Por favor, entre em contato com o suporte."
          );
        }

        if (errorMessage === "FILE_NOT_FOUND_ON_S3") {
          return toast.error(
            "Algum arquivo selecionado não foi encontrado em nosso banco de dados. Por favor, entre em contato com o suporte."
          );
        }
      }

      toast.error("Ocorreu um erro ao baixar as notas.");
    },
  });

  const table = useReactTable({
    columns: serviceSentColumns,
    data: response?.invoices ?? [],
    state: {
      pagination: paginationState,
      columnFilters: tableFilterState,
      sorting: tableSortingState,
      rowSelection,
    },
    onPaginationChange: handleTablePaginationChange,
    onColumnFiltersChange: handleTableFilterChange,
    onSortingChange: handleTableSortingChange,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    pageCount: response?.pagination?.totalPages ?? -1,
    getRowId: (row) => row.id,
    onRowSelectionChange: (newSelectionUpdater) => {
      setRowSelection((prev) => {
        const newSelection =
          typeof newSelectionUpdater === "function"
            ? newSelectionUpdater(prev)
            : newSelectionUpdater;

        const selectedRowsCount = Object.keys(newSelection).length;
        const totalInvoicesCount = response?.pagination.total || 0;

        if (allInvoices && selectedRowsCount < totalInvoicesCount) {
          setAllInvoices(false);
        }

        return newSelection;
      });
    },
  });

  const dateFrom = filters.dateFrom;
  const dateTo = filters.dateTo;

  const selectedRowsCount = Object.keys(rowSelection).length;
  const totalInvoices = response?.pagination.total || 0;

  const handleInvoicesSelection = (markAll: boolean) => {
    if (markAll) {
      setAllInvoices(true);
      table.toggleAllRowsSelected(true);
    } else {
      setAllInvoices(false);
      table.setRowSelection({});
      table.toggleAllRowsSelected(false);
    }
  };

  const renderSelectionMessage = () => {
    if (selectedRowsCount === 0) return null;

    if (allInvoices) {
      return (
        <div className="flex items-center gap-1 text-sm text-muted-foreground border p-2 rounded-md justify-center">
          <span>Todas as notas foram marcadas.</span>
          <span
            className="text-blue-600 hover:underline hover:cursor-pointer hover:text-blue-500"
            onClick={() => handleInvoicesSelection(false)}
          >
            Clique para desmarcar.
          </span>
        </div>
      );
    }

    if (selectedRowsCount === totalInvoices && !allInvoices) {
      return (
        <div className="flex items-center gap-1 text-sm text-muted-foreground border p-2 rounded-md justify-center">
          <span>Todas as notas foram marcadas.</span>
          <span
            className="text-blue-600 hover:underline hover:cursor-pointer hover:text-blue-500"
            onClick={() => handleInvoicesSelection(false)}
          >
            Clique para desmarcar todas.
          </span>
        </div>
      );
    }

    if (selectedRowsCount > 0) {
      return (
        <div className="flex items-center gap-1 text-sm text-muted-foreground border p-2 rounded-md justify-center">
          <span>
            Notas selecionadas {selectedRowsCount} de {totalInvoices}.
          </span>
          <span
            className="text-blue-600 hover:underline hover:cursor-pointer hover:text-blue-500"
            onClick={() => handleInvoicesSelection(true)}
          >
            Clique para marcar todas.
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Notas de Serviço Emitidas</PageTitle>
        <PageDescription>
          Gerencie todas as notas emitidas até o momento pelo nosso sistema.
        </PageDescription>
      </PageHeader>
      <PageContent className="flex flex-col gap-4">
        <CardButton className="w-fit" navigateTo="/invoices/service/new">
          <CardButtonContent>
            <CardButtonIcon>
              <FilePlus size={20} weight="fill" />
            </CardButtonIcon>
            <CardButtonDescription>Emitir nova NFSe</CardButtonDescription>
          </CardButtonContent>
        </CardButton>
        <div className="space-y-4">
          {renderSelectionMessage()}
          <DataTableToolbar table={table}>
            <DatePickerRange
              value={{
                from: dateFrom ?? undefined,
                to: dateTo ?? undefined,
              }}
              setDate={(newDate) => {
                void setFilters({
                  dateFrom: newDate?.from ?? null,
                  dateTo: newDate?.to ?? null,
                });
              }}
            />
            <DataTableFacetedFilter
              title="Status"
              column={table.getColumn("status")}
              options={[
                {
                  label: "Concluída",
                  value: "success",
                },
                {
                  label: "Processando",
                  value: "processing",
                },
                {
                  label: "Cancelada",
                  value: "cancelled",
                },
                {
                  label: "Falha",
                  value: "error",
                },
              ]}
            />
            <DataTableSearch
              placeholder="Emitente"
              column={table.getColumn("issuer")!}
            />
            <DataTableSearch
              placeholder="Tomador"
              column={table.getColumn("recipient")!}
            />
            {selectedRowsCount > 0 && (
              <DataTableExportDropdown
                isPending={isPending}
                onRequest={(type) =>
                  mutate({
                    type,
                    invoices: Object.keys(rowSelection),
                    allInvoices,
                  })
                }
              />
            )}
          </DataTableToolbar>
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
