import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Button } from "@/components/ui/button.tsx";
import { DownloadIcon, FileCode } from "lucide-react";
import { FilePdf } from "@phosphor-icons/react";

interface DataTableExportDropdownProps {
  isPending?: boolean;
  onRequest: (type: "pdf" | "xml") => void;
  buttonName?: string;
  label?: string;
}
export function DataTableExportDropdown({
  isPending,
  onRequest,
  buttonName = "Exportar",
  label = "Escolha o tipo de arquivo",
}: DataTableExportDropdownProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="h-8 border-dashed">
          <DownloadIcon className="mr-2 size-4" />
          <span>{buttonName}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>{label}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="flex flex-row flex-wrap gap-2">
          <Button variant="outline" size="icon" disabled={isPending}>
            <FilePdf className="size-6" onClick={() => onRequest("pdf")} />
          </Button>
          <Button variant="outline" size="icon" disabled={isPending}>
            <FileCode className="size-6" onClick={() => onRequest("xml")} />
          </Button>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
